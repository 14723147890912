import React from 'react';

import { Checkbox, FormControlLabel } from '@mui/material';

interface CheckboxProps {
  key: number | string;
  checked: boolean;
  ariaLabel: string;
  handleToggleCheckbox: (value: React.ChangeEvent<HTMLInputElement>) => void;
  label: string;
}

const TngCheckbox: React.FC<CheckboxProps> = ({
  key,
  checked,
  ariaLabel,
  handleToggleCheckbox,
  label,
}) => {
  return (
    <FormControlLabel
      sx={{ display: 'block' }}
      key={key}
      control={
        <Checkbox aria-label={ariaLabel} checked={checked} onChange={handleToggleCheckbox} />
      }
      label={label}
    />
  );
};

export default TngCheckbox;
