import React from 'react';

import TngMultiDataGrid from './components/TngMultiDataGrid/TngMultiDataGrid';
import { TngMultiGridConfig } from '../../../common/interfaces/TngMultiGridConfig';
import { TngGrid } from '../../common';

interface TngFindSectionProps {
  gridConfig: TngMultiGridConfig;
}

const TngMultiAgGrid: React.FC<TngFindSectionProps> = ({ gridConfig }) => {
  const { ColumnHeader, moreOptions, columnWidth, sorting, rowHeight, gridData } = gridConfig;

  return (
    <>
      <TngGrid container data-testid="multi-grid">
        <TngMultiDataGrid
          ColumnHeader={ColumnHeader}
          sortingConfig={sorting}
          moreOptions={moreOptions}
          columnWidth={columnWidth}
          rowHeight={rowHeight}
          gridData={gridData}
        />
      </TngGrid>
    </>
  );
};

export default TngMultiAgGrid;
