/**
 * FileTypeEnum
 *
 * Enumeration representing different file types used in the application.
 * - EXCEL: Represents Excel files.
 * - PDF: Represents PDF documents.
 * - DOC: Represents Word documents.
 */

export enum FileTypeEnum {
  EXCEL = 'EXCEL_X',
  PDF = 'PDF',
  WORD = 'WORD_X',
}
