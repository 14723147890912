import Translations from '../../../assets/locale/en/content.json';
import { ColumnDef } from '../../../common/interfaces/ColumnDef';
import {
  CellWithDownloadAction,
  CellWithFileIcon,
} from '../../../components/TngGridSection/common/components/CustomCellRender';

// this function is used to create column definations for partner program price list grid

export const getColumnHeaders = (): ColumnDef[] => {
  return [
    {
      name: 'fileName',
      displayName: Translations.PartnerProgram.fileName,
      colWidth: 500,
    },
    {
      name: 'fileType',
      displayName: Translations.PartnerProgram.fileType,
      colWidth: 200,
      customComponent: [{ name: CellWithFileIcon }],
    },
    {
      name: 'actions',
      displayName: Translations.PartnerProgram.action,
      colWidth: 200,
      customComponent: [{ name: CellWithDownloadAction }],
    },
  ];
};
