import { useQuery } from '@tanstack/react-query';

import { fetchUserProfile } from '../../api/services/user/userService';
import { UserProfileAPIResponse } from '../../common/interfaces/user/UserProfile';

/**
Custom hook to fetch the user profile using React Query.
@returns {object} - An object containing user profile data, error, and loading state.
*/
export const useGetUserProfile = (isAuthenticated: boolean) => {
  const { data, error, isLoading } = useQuery<UserProfileAPIResponse, Error>({
    queryKey: ['profile'],
    queryFn: fetchUserProfile,
    enabled: isAuthenticated,
  });
  return { data, error, isLoading };
};
