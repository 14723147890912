import { Country } from '../interfaces/Country';
import { CountryOptions } from '../interfaces/CountryOptions';

/**

Mapped the country data which we get from api to country dropdown options
@parameter {countries} - List of Country.
@returns CountryOptions[] - array of Country Options
*/

export const countryDropdownMapper = (countries: Country[]): CountryOptions[] => {
  return countries.map((country) => ({
    id: country.id,
    label: country.name,
    value: country.code,
  }));
};
