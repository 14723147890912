import { GetOpportunitiesResponse, Opportunity } from '../../../common/interfaces/quotecreation';
import {
  OpportunityModel,
  OpportunityModelResponse,
} from '../../../common/interfaces/quotes/OppertunityDetailsResponse';
import {
  QuoteModel,
  QuoteModelResponse,
} from '../../../common/interfaces/quotes/QuoteDetailsResponse';
import { ManageQuotesTabEnum } from '../../../pages/ManageQuotes/enums/TabsEnum';
import axiosInstance from '../../axiosInstance';
import { get } from '../../baseApiService';
import { getUrl, URLS } from '../../urls';

/**
 * Fetches the quote by the specified ID from the API.
 * @param {string} id - The ID of the quote to fetch.
 * @returns {Promise<QuoteModel>} - A promise that resolves to the quote data.
 * @throws {Error} - Throws an error if the request fails.
 */
export const getQuoteById = async (quoteName: string, status: string): Promise<QuoteModel> => {
  try {
    const baseURL = getUrl(URLS.QUOTES);

    const query = new URLSearchParams();
    query.append('name', quoteName);
    query.append('isDraft', status === ManageQuotesTabEnum.DRAFT_QUOTE ? 'true' : 'false');

    const url = `${baseURL}?${query.toString()}`;

    const { data } = await get<QuoteModelResponse>(url);

    return data.data[0];
  } catch (err: any) {
    throw new Error('Failed to fetch Quote using ID');
  }
};

/**
Fetches the opportunities from the API.
@returns {Promise<Opportunity[]>} - A promise that resolves to an array of opportunities.
@throws {Error} - Throws an error if the request fails.
*/
export const fetchOpportunities = async (): Promise<Opportunity[]> => {
  try {
    const url = getUrl(URLS.GET_OPPORTUNITIES);
    const { data } = await axiosInstance.get<GetOpportunitiesResponse>(url);
    return data.data;
  } catch (error: any) {
    throw new Error('Failed to fetch existing opportunities');
  }
};

/**
 * Fetches the opportunities by the specified ID from the API.
 * @param {string} id - The ID of the opportunity to fetch.
 * @returns {Promise<Opportunity>} - A promise that resolves to the opportunity data.
 * @throws {Error} - Throws an error if the request fails.
 */
export const getOpportunityById = async (id: string): Promise<OpportunityModel> => {
  try {
    const baseURL = getUrl(URLS.OPPORTUNITIES);

    const query = new URLSearchParams();
    query.append('id', id);

    const url = `${baseURL}?${query.toString()}`;

    const { data } = await get<OpportunityModelResponse>(url);

    return data.data[0];
  } catch (err: any) {
    throw new Error('Failed to fetch opportunity using ID');
  }
};

/**
  Fetches the opportunity list from the API.
  @returns {Promise<OpportunityModel[]>} - A promise that resolves to an array of list of opportunities.
  @throws {Error} - Throws an error if the request fails.
*/

export const fetchOpportunityList = async (
  opportunityName: string,
): Promise<OpportunityModel[]> => {
  try {
    let url = getUrl(URLS.OPPORTUNITIES);
    url = `${url}?name=${opportunityName}`;

    const { data } = await axiosInstance.get<OpportunityModelResponse>(url);
    return data.data;
  } catch (err: any) {
    throw new Error('Failed to fetch opportunity list');
  }
};
