import { useState, useEffect } from 'react';

import { debounce } from '../../utils/debounce';

// hook to check is users device is either Mobile or not
const useIsMobile = (threshold: number = 500) => {
  const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth <= threshold);

  useEffect(() => {
    const handleResize = debounce(() => {
      setIsMobile(window.innerWidth <= threshold);
    }, 250); // Adjust debounce delay as needed (e.g., 250ms)

    // Initial call to set initial state
    handleResize();

    window.addEventListener('resize', handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [threshold]);

  return isMobile;
};

export default useIsMobile;
