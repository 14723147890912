import Translations from '../../../../../assets/locale/en/content.json';
import { AccountInfoDetails } from '../modals/AccountInfoDetails';
import { AddressInfoDetails } from '../modals/AddressInfoDetails';
import { FieldConfig } from '../modals/FieldConfig';
import { PartnerLevelDetails } from '../modals/PartnerLevelDetails';

// create config which used to render the fields using map
export const CreateFieldConfigForAccountInfo = (
  accountInfo: AccountInfoDetails | undefined,
): FieldConfig[] => {
  const fieldConfig: FieldConfig[] = [
    { name: Translations.accountSettings.accountName, value: accountInfo?.accountName },
    { name: Translations.accountSettings.parentName, value: accountInfo?.parentName },
    { name: Translations.accountSettings.website, value: accountInfo?.website },
    { name: Translations.accountSettings.phoneNumber, value: accountInfo?.phoneNumber },
    { name: Translations.accountSettings.partnerNumber, value: accountInfo?.partnerNumber },
    { name: Translations.accountSettings.supportId, value: accountInfo?.supportId },
  ];

  return fieldConfig;
};

export const CreateFieldConfigForPartnerLevel = (
  partnerLevel: PartnerLevelDetails | undefined,
): FieldConfig[] => {
  const fieldConfig: FieldConfig[] = [
    {
      name: Translations.accountSettings.workflowAutomationPartnerLevel,
      value: partnerLevel?.workflowAutomationPartnerLevel,
    },
    {
      name: Translations.accountSettings.invoiceAutomationPartnerLevel,
      value: partnerLevel?.invoiceAutomationPartnerLevel,
    },
    {
      name: Translations.accountSettings.intelligentCapturePartnerLevel,
      value: partnerLevel?.intelligentCapturePartnerLevel,
    },
    {
      name: Translations.accountSettings.powerPDFPartnerLevel,
      value: partnerLevel?.powerPdfPartnerLevel,
    },
    {
      name: Translations.accountSettings.controlSuitePartnerLevel,
      value: partnerLevel?.controlSuitePartnerLevel,
    },
    {
      name: Translations.accountSettings.printixPartnerLevel,
      value: partnerLevel?.printixPartnerLevel,
    },
    {
      name: Translations.accountSettings.totalAgilityCloudPartnerLevel,
      value: partnerLevel?.totalAgilityCloudPartnerLevel,
    },
    {
      name: Translations.accountSettings.invoiceAgilityPartnerLevel,
      value: partnerLevel?.invoiceAgilityPartnerLevel,
    },
  ];

  return fieldConfig;
};

export const CreateFieldConfigForAddressInfo = (
  addressInfo: AddressInfoDetails | undefined,
): FieldConfig[] => {
  const fieldConfig: FieldConfig[] = [
    { name: Translations.accountSettings.billToAddress, address: addressInfo?.billToAddress },
    { name: Translations.accountSettings.soldToaddress, address: addressInfo?.soldToAddress },
  ];

  return fieldConfig;
};
