import { ContextActions } from '../modals/contextAction';
import { ContextState } from '../modals/contextState';

// Action types
export const SET_FINANCIAL_FIELDS = 'SET_FINANCIAL_FIELDS';
export const SET_ORDER_ID = 'SET_ORDER_ID';
export const SET_IS_FINANCIAL_TAB_VISIBLE = 'SET_IS_FINANCIAL_TAB_VISIBLE';

// all reducers for grid context
export const ContextReducers = (state: ContextState, action: ContextActions) => {
  switch (action.type) {
    case SET_FINANCIAL_FIELDS:
      return { ...state, financialDetailFields: action.payload };
    case SET_ORDER_ID:
      return { ...state, orderId: action.payload };
    case SET_IS_FINANCIAL_TAB_VISIBLE:
      return { ...state, isFinancialTabVisible: action.payload };
    default:
      throw new Error(`Unknown action: ${action.type}`);
  }
};
