import Translations from '../../../../assets/locale/en/content.json';
import { ColumnDef } from '../../../../common/interfaces/ColumnDef';

// this function is used to create column definations for order history grid
export const getUsersColumnHeaders = (): ColumnDef[] => {
  return [
    {
      name: 'FirstName',
      displayName: Translations.ManageUsers.firstName,
    },
    {
      name: 'LastName',
      displayName: Translations.ManageUsers.lastName,
    },
    {
      name: 'EmailAddress',
      displayName: Translations.ManageUsers.emailAddress,
      colWidth: 300,
    },
    { name: 'JobTitle', displayName: Translations.ManageUsers.jobTitle, colWidth: 220 },
    { name: 'DateAdded', displayName: Translations.ManageUsers.dateAdded },
    { name: 'UserType', displayName: Translations.ManageUsers.userType, colWidth: 200 },
    { name: 'Country', displayName: Translations.ManageUsers.country, colWidth: 220 },
  ];
};
