import Translations from '../../../assets/locale/en/content.json';
import { ColumnDef } from '../../../common/interfaces/ColumnDef';
import { StatusColors } from '../../../common/interfaces/StatusColor';
import {
  CellWithBorder,
  CellWithlink,
} from '../../../components/TngGridSection/common/components/CustomCellRender';

// this function is used to create column definations for order history grid
export const getColumnHeaders = (statusColors: StatusColors): ColumnDef[] => {
  return [
    {
      name: 'QuoteId',
      displayName: Translations.orderHistory.quoteId,
      customComponent: [
        {
          name: CellWithlink,
          props: { navigateToRoute: 'order-summary', navigateRouteParams: 'orderId' },
        },
      ],
    },
    {
      name: 'OrdStatus',
      displayName: Translations.orderHistory.orderStatus,
      customComponent: [{ name: CellWithBorder, props: { statusColor: statusColors } }],
      colWidth: 190,
    },
    {
      name: 'CustName',
      displayName: Translations.orderHistory.customerName,
      colWidth: 350,
      showTooltip: true,
    },
    {
      name: 'SoldThrough',
      displayName: Translations.orderHistory.soldThrough,
      colWidth: 350,
      showTooltip: true,
    },
    {
      name: 'PONum',
      displayName: Translations.orderHistory.poNumber,
    },
    {
      name: 'PODateFrom',
      displayName: Translations.orderHistory.poDate,
      sort: 'desc',
    },
    { name: 'ConNum', displayName: Translations.orderHistory.contractNumber },
  ];
};
