import React from 'react';

import Translations from '../../assets/locale/en/content.json';
import TngErrorFallbackUI from '../../components/common/TngErrorFallbackUI';

const UnAuthorized: React.FC = () => {
  return (
    <TngErrorFallbackUI
      code={Translations.error.unAuthorizedCode}
      errorHeading={Translations.error.unAuthorizedTitle}
      errorMessage={Translations.error.unAuthorizedMessage}
    />
  );
};

export default UnAuthorized;
