import React from 'react';

import TngInfoCardWrapper from './TngInfoCardWrapper';
import { TngGrid, TngTypography } from '../../../components/common';

export interface FieldConfig {
  name: string;
  value: string;
}

interface FieldDetailProps {
  fieldConfig: FieldConfig[];
  fieldDetailLabel: string;
}

const TngInfoCardDetails: React.FC<FieldDetailProps> = ({ fieldConfig, fieldDetailLabel }) => {
  return (
    <>
      <TngGrid className="tnginfo_detail_header">
        <TngTypography variant="body1">{fieldDetailLabel}</TngTypography>
      </TngGrid>
      <TngGrid className="tnginfo_fields">
        {fieldConfig.map((field) => (
          <TngInfoCardWrapper key={field.name} name={field.name} value={field.value} />
        ))}
      </TngGrid>
    </>
  );
};

export default TngInfoCardDetails;
