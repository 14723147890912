export const updateLiveRegionTextContet = (
  liveRegionRef: React.MutableRefObject<HTMLDivElement | null>,
  text: string,
) => {
  if (liveRegionRef.current) {
    liveRegionRef.current.textContent = `${text} button clicked`;
  }
  // Clear the message after a short delay
  setTimeout(() => {
    if (liveRegionRef.current) {
      liveRegionRef.current.textContent = '';
    }
  }, 1000);
};
