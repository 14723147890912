import { Filter } from '../../../common/interfaces/GridContextState';
import { DefaultSearch } from '../../../common/interfaces/GridSearchConfig';
import { TrainingCertificationGridUrl } from '../../../common/interfaces/urls/TrainingCertificationGridUrl';
import { ListToPipeSeperator } from '../../../utils/listToPipeSeperator';

interface defaultAPIParamsProps {
  pageNumber: number;
  pageSize: number;
  search: DefaultSearch;
  filters: Filter;
}

// used to create default API params for grid
export const defaultAPIParams = ({
  pageNumber,
  pageSize,
  search,
  filters,
}: defaultAPIParamsProps): TrainingCertificationGridUrl => {
  const apiParams: TrainingCertificationGridUrl = {
    pageNumber: pageNumber,
    pageSize: pageSize,
    searchByValue: search.searchValue,
    contactIds: filters?.contactIds && ListToPipeSeperator(filters?.contactIds),
  };
  return apiParams;
};
