import React from 'react';

import Translations from '../../../assets/locale/en/content.json';
import { TngGrid, TngTypography } from '../../../components/common';

type ResetPasswordMessageProps = {
  username: string;
};

const ResetPasswordMessage: React.FC<ResetPasswordMessageProps> = ({ username }) => (
  //className="reset-password-message"
  <TngGrid container>
    <TngGrid item className="reset_password_message_grid">
      <TngTypography>
        {Translations.ResetPassword.emailSentMessage}{' '}
        <span style={{ fontWeight: '700' }}>{username}</span>
        {`.`} {Translations.ResetPassword.checkSpamFolder}
      </TngTypography>
    </TngGrid>
  </TngGrid>
);

export default ResetPasswordMessage;
