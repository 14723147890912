import React, { ReactNode, useEffect, useState } from 'react';

import CustomTabPanel from './CustomTabPanel';
import { TngDivider, TngGrid, TngTab, TngTabs } from '../common';

interface CustomTabProps {
  tabs: { id: number; label: string }[];
  children: ReactNode[];
  orientation?: 'horizontal' | 'vertical';
  onTabSelection?: (tabId: number) => void;
  selectedTab?: number;
  isCapsuleTab?: boolean;
}

export const CustomTabs: React.FC<CustomTabProps> = ({
  tabs,
  children,
  orientation,
  onTabSelection,
  selectedTab,
  isCapsuleTab,
}: CustomTabProps) => {
  const [activeTab, setActiveTab] = useState<number>(selectedTab || tabs[0]?.id); // Initialize with selectedTab or first tab's id

  useEffect(() => {
    if (selectedTab) setActiveTab(selectedTab);
  }, [selectedTab]);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
    onTabSelection && onTabSelection(newValue); // Pass tab id to parent
  };

  return (
    <TngGrid
      container
      className={`${isCapsuleTab ? 'capsule_tab_container' : 'custom_tab_panel_container'}`}
    >
      <TngGrid item className="custom_tab_panel">
        <TngTabs
          orientation={orientation}
          value={activeTab}
          onChange={handleChange}
          className="custom_tabs"
        >
          {tabs.map((tab) => (
            <TngTab
              className="custom_each_tab"
              key={tab.id}
              label={tab.label}
              disableTouchRipple
              disableRipple
              tabIndex={0}
              value={tab.id} // Pass tab.id as the value
            />
          ))}
        </TngTabs>
      </TngGrid>
      <TngDivider className="custom_tab_panel_divider" />
      {children.map((child, index) => (
        <CustomTabPanel key={index} value={activeTab} index={tabs[index]?.id}>
          {child}
        </CustomTabPanel>
      ))}
    </TngGrid>
  );
};
