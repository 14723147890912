import { AxiosRequestConfig, AxiosResponse } from 'axios';

import axiosInstance from './axiosInstance';

export const get = async <T>(
  url: string,
  config?: AxiosRequestConfig,
): Promise<AxiosResponse<T>> => {
  return await axiosInstance.get<T>(url, config);
};

export const post = async <T>(
  url: string,
  data?: any,
  config?: AxiosRequestConfig,
): Promise<AxiosResponse<T>> => {
  return await axiosInstance.post<T>(url, data, config);
};

export const put = async <T>(
  url: string,
  data?: any,
  config?: AxiosRequestConfig,
): Promise<AxiosResponse<T>> => {
  return await axiosInstance.put<T>(url, data, config);
};

export const del = async <T>(
  url: string,
  config?: AxiosRequestConfig,
): Promise<AxiosResponse<T>> => {
  return await axiosInstance.delete<T>(url, config);
};
