import React, { ReactNode } from 'react';

import Drawer, { DrawerProps } from '@mui/material/Drawer';

interface TngDrawerProps extends DrawerProps {
  open: boolean;
  toggleDrawer: () => void;
  children: ReactNode | ReactNode[];
}

export const TngDrawer: React.FC<TngDrawerProps> = ({
  open,
  toggleDrawer,
  children,
  ...drawerProps
}) => {
  return (
    <Drawer open={open} onClose={toggleDrawer} {...drawerProps}>
      {children}
    </Drawer>
  );
};
