import React from 'react';

import Translations from '../../../../../assets/locale/en/content.json';
import { TngDivider, TngGrid, TngTypography } from '../../../../../components/common';
import { FieldConfig } from '../modals/FieldConfig';
import { PartnerLevelDetails } from '../modals/PartnerLevelDetails';
import { CreateFieldConfigForPartnerLevel } from '../utils/FieldConfig';

interface PartnerLevelProps {
  partnerInfo: PartnerLevelDetails | undefined;
}

const PartnerLevel: React.FC<PartnerLevelProps> = ({ partnerInfo }) => {
  const fieldConfig: FieldConfig[] = CreateFieldConfigForPartnerLevel(partnerInfo);
  return (
    <TngGrid container>
      <TngGrid item xs={12} className="account_details_header">
        <TngTypography variant="h6">{Translations.accountSettings.partnerLevel}</TngTypography>
        <TngDivider />
      </TngGrid>
      {fieldConfig.map((acc) => (
        <TngGrid item key={acc.name} xs={12} md={12} lg={6} className="account_details_values">
          <>
            <TngTypography key={acc.name} className="account_details_values_key">
              {acc.name}
            </TngTypography>
            <TngTypography>{acc.value ?? Translations.accountSettings.notAvailable}</TngTypography>
          </>
        </TngGrid>
      ))}
    </TngGrid>
  );
};

export default PartnerLevel;
