import { useQuery } from '@tanstack/react-query';

import { fetchContacts } from '../../../api/services/trainingcertification/TrainingCertificationService';
import { Contact } from '../../../common/interfaces/contacts/Contacts';

export const useContacts = () => {
  return useQuery<Contact[], Error>({
    queryKey: ['contacts'],
    queryFn: fetchContacts,
  });
};
