import React from 'react';

import ProductListGrid from './ProductListGrid';
import { GridContextProvider } from '../../../../common/GridContext/GridContextProvider';

const ProductList = () => {
  return (
    <GridContextProvider>
      <ProductListGrid />
    </GridContextProvider>
  );
};

export default ProductList;
