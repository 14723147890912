import { ExistingOpportunityGridModal } from '../modal/ExistingOpportunityGridModal';
import { ExistingOpportunityListModal } from '../modal/ExistingOpportunityListModal';

// to map the data according to grid columns
export const mapExistingOpportunitiesToExistingOpportunityGridModal = (
  opportunities: ExistingOpportunityListModal[],
): ExistingOpportunityGridModal[] => {
  return opportunities.map((opp) => ({
    name: opp.name,
    opportunityId: opp.opportunityId,
    closeDate: opp.closeDate,
    id: opp.id,
  }));
};
