import { useEffect } from 'react';

import { focusFirstInteractiveElement } from '../../utils/findInteractiveElement';

/**
Custom hook to focus on first interactive element of dom
@param {Id} - Id of root element
*/
const useFocusOnFirstElement = (Id: string) => {
  useEffect(() => {
    const mainDiv = document.getElementById(Id);
    focusFirstInteractiveElement(mainDiv);
  }, [Id]);
};

export default useFocusOnFirstElement;
