import { UrlParams } from '../common/interfaces/UrlParams';
// object contains all the urls used in application
// now only contains test urls
export const URLS: UrlParams = {
  // Auth Routes
  LOGIN: `/auth/login`,
  REGISTER: `/auth/register`,

  // Order Routes
  ORDERS: `order/v1/orders`,
  PRODUCT_LIST: `order/v1/orders/:orderId/order-items`,
  CUSTOMERS: `order/v1/accounts/:accountId/customers`,
  ORDER_STATUS: `order/v1/order-statuses`,
  ORDER_STATUS_COUNTS: `order/v1/orders/status-wise-count`,

  // Ping Routes
  INITIATE_FLOW:
    'as/authorize?response_type=code&client_id=:clientId&scope=openid email address profile phone&code_challenge=:codeChallenge&code_challenge_method=S256&redirect_uri=:redirectUri&response_mode=pi.flow',
  AUTHENTICATE_USER: 'flows/:flowId',
  GET_AUTHORIZATION_CODE: ':resumeUrl',
  EXCHANGE_AUTH_CODE: 'as/token',
  LOGOUT_USER: 'as/signoff?id_token_hint=:token',
  VALIDATE_TOKEN: '/api/validate-token',
  REFRESH_TOKEN: 'as/token', //Refresh Token

  // User Routes
  USERS: `/users`,
  USER_PROFILE: `/users/:userId/test`,

  // Account Routes
  ACCOUNT_DETAILS: `account/v1/accounts/:accountId`,
  TRAINING_CERTIFICATION: `account/v1/accounts/:accountId/training-certificates`,
  CONTACTS: `account/v1/contacts`,
  CONTACT_INFO: `account/v1/contact-info`,
  USER_PROFILE_INFO: `account/v1/user-profile`,
  UPDATE_USER_PROFILE_INFO: `account/v1/user-profile/:contactId`,
  USER_TYPES: `account/v1/user-types`,
  ACCOUNT_CONTACTS: `account/v1/accounts/:accountId/contacts`,
  GLOBAL_PRICE_LIST: `account/v1/accounts/:accountId/global-price-files`,
  PARTNER_PROGRAM_GUIDE_NAMES: `account/v1/accounts/:accountId/program-guides`,
  PARTNER_PROGRAM_GUIDE_LIST: `account/v1/accounts/:accountId/program-guides/:programGuideType/files`,
  UPDATE_CONTACT: `account/v1/contacts/:contactId`,

  // Masterdata Routes
  COUNTRY: `masterData/v1/countries`,
  STATE: `masterData/v1/states`,
  QUOTES_STATUS: `masterdata/v1/lookups/quote-statuses`,

  // Content Routes
  FILE_DOWNLOAD: `content/v1/contents/:contentId/download`,

  // Quote Routes
  OPPORTUNITIES: `quote/v1/opportunities`,
  QUOTES: `quote/v1/quotes`,
  GET_OPPORTUNITIES: `quote/v1/opportunities/search`,

  //USER ADMIN
  USER_PERMISSIONS: `useradmin/v1/permissions`,
};
// return the completed url with params attached to it for given key
export const getUrl = (url: string, params?: UrlParams): string => {
  if (!url) {
    throw new Error(`URL '${url}' not found in URLS`);
  }

  if (params) {
    Object.keys(params).forEach((paramKey) => {
      url = url.replace(`:${paramKey}`, params[paramKey]);
    });
  }

  return url;
};
