import React, { ReactNode } from 'react';

import { OutlinedInputProps } from '@mui/material';
import Select, { SelectChangeEvent, SelectProps } from '@mui/material/Select';

interface TngSelectProps extends Omit<SelectProps<string | number>, 'input'> {
  children: ReactNode | ReactNode[];
  onChange: (event: SelectChangeEvent<string | number>, child: ReactNode) => void;
  input?: React.ReactElement<OutlinedInputProps>;
}

export const TngSelect: React.FC<TngSelectProps> = ({ children, onChange, input, ...props }) => {
  return (
    <Select onChange={onChange} {...props} input={input}>
      {children}
    </Select>
  );
};
