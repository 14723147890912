import { useCallback, useState } from 'react';

import {
  fetchEndUserCustomers,
  fetchSoldThroughCustomers,
} from '../../../api/services/customer/customerService';

export const useCustomers = () => {
  const [error, setError] = useState<string | null>(null);

  // A callback function which we call to fetch customers
  const getCustomers = useCallback(
    async (
      { isSoldThroughCustomer = false }: { isSoldThroughCustomer?: boolean } = {},
      customerName: string,
    ) => {
      try {
        if (isSoldThroughCustomer) {
          const data = await fetchSoldThroughCustomers(customerName);
          return data;
        } else {
          const data = await fetchEndUserCustomers(customerName);
          return data;
        }
      } catch (error: any) {
        setError('Failed to fetch customers');
        throw Error(error.message);
      }
    },
    [],
  );

  return { getCustomers, error };
};
