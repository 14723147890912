import React, { useState } from 'react';

import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import { IconButton, InputAdornment } from '@mui/material';

import { ValidateSearchText } from './helper/helperMethods';
import Translations from '../../../../../assets/locale/en/content.json';
import { GridActionEnum } from '../../../../../common/enums/GridActionEnum';
import { useGridDispatch } from '../../../../../common/GridContext/GridContextProvider';
import { DefaultSearch } from '../../../../../common/interfaces/GridSearchConfig';
import { TngButton, TngGrid, TngTextField } from '../../../../common';

interface searchBarProps {
  isDropdownApplicable: boolean;
  searchBy: string;
  searchValue: string;
  setSearchValue: React.Dispatch<React.SetStateAction<string>>;
  placeholder: string;
}

const TngSearchbar: React.FC<searchBarProps> = ({
  isDropdownApplicable,
  searchBy,
  searchValue,
  setSearchValue,
  placeholder,
}) => {
  const [isError, setIsError] = useState<boolean>(false);
  const [errorHelperText, setErrorHelperText] = useState<string>('');

  const dispatch = useGridDispatch();

  const handleSearchButtonClick = async (): Promise<void> => {
    // Trim whitespace from the beginning and end of the searchText
    const trimmedSearchValue = searchValue.trim();
    setSearchValue(trimmedSearchValue);

    // Validate search text/seachValue
    const isValidated: boolean = ValidateSearchText(
      trimmedSearchValue || '',
      setIsError,
      setErrorHelperText,
    );
    if (isValidated) {
      // update the values on searchBy and searchValue to context for api calling with updated params
      const searchObj: DefaultSearch = {
        searchBy,
        searchValue: trimmedSearchValue,
      };
      dispatch({ type: GridActionEnum.SET_SEARCH, payload: searchObj });
      dispatch({ type: GridActionEnum.SET_LOADER, payload: true });
      dispatch({ type: GridActionEnum.SET_IS_SEARCH_APPLIED, payload: true });
      dispatch({ type: GridActionEnum.SET_PAGE_NUMBER, payload: 1 });
    }
  };

  const handleChangeValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    const searchValue = event.target.value as string;
    (searchValue.length >= 3 || searchValue.length == 0) && setIsError(false);

    // set searchValue to state
    setSearchValue(searchValue);
  };

  const onClear = () => {
    setSearchValue('');
    setIsError(false);
    dispatch({ type: GridActionEnum.SET_SEARCH, payload: { searchBy, searchValue: '' } });
  };

  return (
    <TngGrid container className="searchby_container">
      <TngGrid item>
        <TngTextField
          error={isError}
          className={`${isDropdownApplicable ? '' : 'dropdown_not_applicable'} grid_search_bar_field`}
          value={searchValue || ''}
          placeholder={placeholder}
          onChange={handleChangeValue}
          helperText={isError && errorHelperText}
          InputProps={{
            className: 'search_bar_field_box',
            startAdornment: (
              <InputAdornment className="search_icon" position="start">
                <SearchIcon />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                {searchValue && (
                  <IconButton
                    onClick={onClear}
                    edge="end"
                    className="clear_search_btn"
                    disableRipple
                    disableFocusRipple
                    disableTouchRipple
                    data-testid="clear-button"
                    aria-label={Translations.ariaLabels.clearSearchValue}
                  >
                    <ClearIcon />
                  </IconButton>
                )}
              </InputAdornment>
            ),
          }}
          FormHelperTextProps={{
            className: 'custom-helper-text',
            role: 'alert',
            'aria-live': 'assertive',
          }}
        />
      </TngGrid>
      <TngGrid item>
        <TngButton
          className="grid_search_button"
          variant="outlined"
          onClick={handleSearchButtonClick}
        >
          {Translations.orderHistory.search}
        </TngButton>
      </TngGrid>
    </TngGrid>
  );
};

export default TngSearchbar;
