import React from 'react';

import Translations from '../../../assets/locale/en/content.json';
import { TngGrid, TngTypography } from '../../../components/common';

const LoginFormText = () => {
  return (
    <TngGrid className="form_description">
      <TngGrid className="form_header">
        <TngTypography className="form_greeting" data-testid="greeting">
          {Translations.Login.welcomeBack}
        </TngTypography>
        <TngTypography className="header_title">{Translations.Login.login}</TngTypography>
      </TngGrid>
      <TngGrid className="form_context">
        <TngTypography>{Translations.Login.loginDescriptionText}</TngTypography>
      </TngGrid>
    </TngGrid>
  );
};

export default LoginFormText;
