import { getColumnHeaders } from './columnDetails';
import { TngMultiGridConfig } from '../../../common/interfaces/TngMultiGridConfig';
import { GlobalPriceListGrid } from '../interfaces/GlobalPriceListGrid';

// grid config contains the details about grid which will render for this screen
export const getGridConfig = (data: GlobalPriceListGrid[]): TngMultiGridConfig => ({
  gridData: data,
  ColumnHeader: getColumnHeaders(),
  moreOptions: {
    isApplicable: false,
    moreOptionNavigation: [],
  },
  rowHeight: 75,
  columnWidth: 120,
  sorting: {
    isApplicable: false,
  },
  search: {
    isApplicable: false,
  },
  isPaginationVisible: false,
});
