import React from 'react';

import { Link } from 'react-router-dom';

import { ListItem } from '../../../../../common/interfaces/NavbarListOptions';
import { TngDivider, TngListItem } from '../../../../common';

interface NavbarListProps {
  listItems: ListItem[];
}

const NavbarList: React.FC<NavbarListProps> = ({ listItems }) => {
  return (
    <>
      {listItems.map((item, index) => {
        return (
          <>
            <TngListItem key={item.value} className="popover_list_item" onClick={item.onClick}>
              <Link className="popover_list_link" to={item.href}>
                {item.value} {item.iconRequired && item.icon}
              </Link>
            </TngListItem>
            <TngDivider
              sx={{
                display: index === 0 || index === listItems.length - 2 ? 'inherit' : 'none',
              }}
              aria-hidden="true"
            />
          </>
        );
      })}
    </>
  );
};

export default NavbarList;
