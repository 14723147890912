import { GridAPIResponse } from '../../../common/interfaces/GridAPIResponse';
import { get } from '../../baseApiService';

/**
Fetches grid data from the given URL.
@param {string} baseUrl - The base URL for the API endpoint.
@returns {Promise<GridAPIResponse>} - A promise that resolves to the grid data.
@throws {Error} - Throws an error if the request fails.
*/
export const getGridData = async (baseUrl: string): Promise<GridAPIResponse> => {
  try {
    const response = await get<GridAPIResponse>(baseUrl);
    const responseData = response.data;

    return responseData as GridAPIResponse;
  } catch (err: any) {
    throw new Error('Failed to fetch grid data');
  }
};
