import React from 'react';

interface MoreIconProps {
  height: string;
  width: string;
}

const MoreIcon: React.FC<MoreIconProps> = ({ height, width }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 10.6992C9.10457 10.6992 10 9.80379 10 8.69922C10 7.59465 9.10457 6.69922 8 6.69922C6.89543 6.69922 6 7.59465 6 8.69922C6 9.80379 6.89543 10.6992 8 10.6992Z"
        fill="#231F20"
      />
      <path
        d="M2 10.6992C3.10457 10.6992 4 9.80379 4 8.69922C4 7.59465 3.10457 6.69922 2 6.69922C0.89543 6.69922 0 7.59465 0 8.69922C0 9.80379 0.89543 10.6992 2 10.6992Z"
        fill="#231F20"
      />
      <path
        d="M14 10.6992C15.1046 10.6992 16 9.80379 16 8.69922C16 7.59465 15.1046 6.69922 14 6.69922C12.8954 6.69922 12 7.59465 12 8.69922C12 9.80379 12.8954 10.6992 14 10.6992Z"
        fill="#231F20"
      />
    </svg>
  );
};

export default MoreIcon;
