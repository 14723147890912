import { useEffect } from 'react';

import { defaultAPIParams } from './gridHelper/apiParams';
import { getGridConfig } from './gridHelper/gridConfig';
import { mapProductDetailsToProductGridModal } from './gridHelper/mapperFunctions';
import { GridActionEnum } from '../../../../common/enums/GridActionEnum';
import { useGridDispatch, useGridState } from '../../../../common/GridContext/GridContextProvider';
import { ProductListGridUrl } from '../../../../common/interfaces/urls/ProductListGridUrl';
import { TngGrid } from '../../../../components/common';
import TngAgGrid from '../../../../components/TngGridSection/TngAgGrid/TngAgGrid';
import { useOrderSummaryState } from '../../context/ContextProvider';

const ProductListGrid = () => {
  const { gridData, search, pageNumber, pageSize, sorting, loading } = useGridState();
  // use orderId from order summary page level copntext
  const { orderId } = useOrderSummaryState();
  const dispatch = useGridDispatch();

  // check if gridData is present than called the mapper to map the data according to grid columns
  useEffect(() => {
    if (gridData) {
      const mappedData = mapProductDetailsToProductGridModal(gridData);
      dispatch({ type: GridActionEnum.SET_GRID_MAPPED_DATA, payload: mappedData });
      dispatch({ type: GridActionEnum.SET_LOADER, payload: false });
    }
  }, [dispatch, gridData, loading]);

  const apiParams: ProductListGridUrl = defaultAPIParams({ pageNumber, pageSize, search, sorting });

  return (
    <TngGrid className="order_detail_product_grid_container">
      <TngAgGrid gridConfig={getGridConfig(apiParams, orderId)} />
    </TngGrid>
  );
};

export default ProductListGrid;
