import React, { useState } from 'react';

import OpportunityDropdown from './OpportunityDropdown';
import FilterQuoteStatus from './QuoteStatus';
import { GridActionEnum } from '../../../../common/enums/GridActionEnum';
import { useGridDispatch, useGridState } from '../../../../common/GridContext/GridContextProvider';
import { useQuotesStatus } from '../../../../common/hooks/useQuotesStatus';
import { TngApplyFilterBtn, TngDivider, TngGrid } from '../../../../components/common';
import { ManageQuotesFilter } from '../../modals/ManageQuotesFilter';
import { ManageQuotesFilterContext } from '../../modals/ManageQuotesFilterContext';
import {
  checkIfManageQuotesFiltersChanged,
  initializeFilterState,
  isAnyFilterSelected,
} from '../../utils/filterHelper';

interface FilterProps {
  isDraftFilter: boolean;
}

export const Filter: React.FC<FilterProps> = ({ isDraftFilter }) => {
  // filters from context
  const { filters }: { filters: ManageQuotesFilterContext } = useGridState();
  const dispatch = useGridDispatch();
  const { data: quoteStatuses = [] } = useQuotesStatus({ fetchStatuses: !isDraftFilter });

  // states for filter
  const [filterObj, setFiltersObj] = useState<ManageQuotesFilter>(initializeFilterState(filters));

  const defaultFilterState: ManageQuotesFilter = initializeFilterState(filters);

  // Check if there are any active filters
  const hasFilters = checkIfManageQuotesFiltersChanged(filterObj, defaultFilterState);

  // click handler of apply filter button which updates the context and calls onApply callback
  const applyFilter = () => {
    dispatch({ type: GridActionEnum.SET_FILTERS, payload: { ...filterObj } });
    dispatch({ type: GridActionEnum.SET_IS_FILTER_OPEN, payload: false });
    if (isAnyFilterSelected(filterObj)) {
      dispatch({ type: GridActionEnum.SET_IS_FILTER_APPLIED, payload: true });
    } else {
      dispatch({ type: GridActionEnum.SET_IS_FILTER_APPLIED, payload: false });
    }
  };

  return (
    <TngGrid container className="order_history_filter filter_container">
      {!isDraftFilter && (
        <FilterQuoteStatus
          quoteStatus={quoteStatuses}
          filterObj={filterObj}
          setFiltersObj={setFiltersObj}
        />
      )}

      {!isDraftFilter && <TngDivider orientation="horizontal" variant="middle" flexItem />}

      <OpportunityDropdown filterObj={filterObj} setFiltersObj={setFiltersObj} />

      <TngApplyFilterBtn hasFilters={hasFilters} applyFilter={applyFilter} />
    </TngGrid>
  );
};
