import { PermissionItem } from '../../../common/interfaces/userAdmin/Permissions';
import { UserPermissionsApiRes } from '../../../common/interfaces/userAdmin/UserPermissionsApiRes';
import axiosInstance from '../../axiosInstance';
import { getUrl, URLS } from '../../urls';

export const fetchUserPermissions = async (): Promise<PermissionItem[]> => {
  try {
    const url = getUrl(URLS.USER_PERMISSIONS);
    const { data } = await axiosInstance.get<UserPermissionsApiRes>(url);
    return data.data;
  } catch (err: any) {
    throw new Error('Failed to fetch user permissions');
  }
};
