import { useQuery } from '@tanstack/react-query';

import { fetchQuoteStatus } from '../../api/services/masterData/masterData';
import { StatusWithColor } from '../interfaces/StatusColor';

/**

Custom hook to fetch quotes status data.
@returns {object} - An object containing quotes status data, error, and loading state.
*/
export const useQuotesStatus = ({ fetchStatuses = false }: { fetchStatuses?: boolean } = {}) => {
  const { data, error, isLoading } = useQuery<StatusWithColor[], Error>({
    queryKey: ['quoteStatus'],
    queryFn: fetchQuoteStatus,
    enabled: fetchStatuses,
  });
  return { data, error, isLoading };
};
