import React from 'react';

import logo from '../../../assets/images/tungsten_logo_blue.svg';
import { TngGrid, TngImage } from '../../../components/common';

const ResetPasswordHeader: React.FC = () => {
  return (
    <TngGrid item xs={12} className="reset_password_image">
      <TngImage src={logo} alt="logo Image" />
    </TngGrid>
  );
};

export default ResetPasswordHeader;
