import React, { ReactNode } from 'react';

import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';

import customTheme from './Theme';

interface TngThemeProviderProps {
  children: ReactNode;
}

const TngThemeProvider: React.FC<TngThemeProviderProps> = ({ children }) => {
  return (
    <ThemeProvider theme={customTheme}>
      <CssBaseline enableColorScheme /> {/* Adding CssBaseline here */}
      {children}
    </ThemeProvider>
  );
};

export default TngThemeProvider;
