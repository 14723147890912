import React, { useEffect, useState } from 'react';

import { useLocation, useNavigate } from 'react-router-dom';

import UserInfo from './components/UserInfo';
import { UserDetailsModal } from './modals/UserDetailsModal';
import { dataMapper } from './utils/DataMapper';
import Translations from '../../../assets/locale/en/content.json';
import { TngGrid, TngLoader } from '../../../components/common';
import TngBackNavigation from '../../../components/common/TngBackNavigation';
import { focusFirstInteractiveElement } from '../../../utils/findInteractiveElement';
import EditUserDrawer from '../common/components/AccountContactFrom/EditFormDrawer';
import { useGetAccountContactById } from '../common/hook/useGetAccountContactById';

const UserDetails = () => {
  const [isEditUser, setIsEditUser] = useState<boolean>(false);
  const currentUrl = window.location.href;
  const urlObject = new URL(currentUrl);
  const contactId = urlObject.searchParams.get('Id');

  const [userDetails, setUserDetails] = useState<UserDetailsModal>();
  const [pageLoader, setPageLoader] = useState<boolean>(true);

  const navigate = useNavigate();
  const location = useLocation();

  const { data: contact, isLoading: dataLoad } = useGetAccountContactById(contactId!);
  useEffect(() => {
    if (contact && contactId) {
      if (contact.length > 0) {
        const { userDetailsInfo } = dataMapper(contact[0]);
        setUserDetails(userDetailsInfo);
      }
      setPageLoader(dataLoad);
    }
  }, [contact, contactId, dataLoad]);

  useEffect(() => {
    const mainDiv = document.getElementById('main-content');
    focusFirstInteractiveElement(mainDiv);
  }, []);

  useEffect(() => {
    // Parse the query parameters
    const searchParams = new URLSearchParams(location.search);

    // Check if edit-user is true in the query parameters
    if (searchParams.get('edit') === 'true' && searchParams.get('Id')?.trim()) {
      setIsEditUser(true);
    } else {
      setIsEditUser(false);
    }
  }, [location.search]); // Trigger useEffect whenever the URL's query string changes

  const handleEditClose = () => {
    setIsEditUser(false);
    // Remove 'edit-user' from query params
    const searchParams = new URLSearchParams(location.search);
    searchParams.delete('edit'); // Remove the 'edit' param

    // Update the URL without the 'edit-user' parameter
    navigate(`${location.pathname}?${searchParams.toString()}`, { replace: true });
  };

  return (
    <TngGrid container className="user_detail_parent_container">
      <TngBackNavigation
        label={Translations.common.back}
        path={Translations.navigation.manageUsers}
      />
      <TngGrid container className="user_detail_child_container">
        {pageLoader ? <TngLoader /> : <UserInfo userDetails={userDetails} />}
      </TngGrid>
      {isEditUser && <EditUserDrawer open={isEditUser} onClose={handleEditClose} />}
    </TngGrid>
  );
};

export default UserDetails;
