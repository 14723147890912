import React, { ReactNode, useEffect, useMemo } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { useGetCountries } from '../../common/hooks/useGetCountry';
import { useGetState } from '../../common/hooks/useGetState';
import { useGetUserProfile } from '../../common/hooks/useGetUserProfile';
import { useUserPermissions } from '../../common/hooks/useUserPermissions';
import { RootState } from '../../store';
import { setCountry, setState } from '../../store/master/masterSlice';
import { setLoading, setPermissionsLoader } from '../../store/master/masterSlice';
import { setUserAction, setUserPermissionsAction } from '../../store/user/userSlice';

interface AppServiceProps {
  children: ReactNode;
}

const AppService: React.FC<AppServiceProps> = ({ children }) => {
  const dispatch = useDispatch();
  const isAuthenticated = useSelector((state: RootState) => state.auth.isAuthenticated);
  const isPermissionsLoader = useSelector((state: RootState) => state.master.isPermissionsLoading);
  const isRefreshTokenLoading = useSelector(
    (state: RootState) => state.master.isRefreshTokenLoading,
  );

  const { data: userProfile, isLoading: userLoader } = useGetUserProfile(isAuthenticated);
  const { data: userPermissions, isLoading: permissionLoader } =
    useUserPermissions(isAuthenticated);
  const { data: countries, isLoading: countriesLoader } = useGetCountries(isAuthenticated);
  const { data: states, isLoading: statesLoader } = useGetState(isAuthenticated);

  const setUserProfile = useMemo(
    () => (profile: any) => dispatch(setUserAction(profile)),
    [dispatch],
  );
  const setUserPermissions = useMemo(
    () => (permissions: any) => dispatch(setUserPermissionsAction(permissions)),
    [dispatch],
  );
  const setLoaderState = useMemo(
    () => (state: boolean) => dispatch(setLoading({ isLoading: state })),
    [dispatch],
  );
  const setPermissionsLoaded = useMemo(
    () => (state: boolean) => dispatch(setPermissionsLoader({ isPermissionsLoading: state })),
    [dispatch],
  );

  useEffect(() => {
    if (
      !userLoader &&
      !permissionLoader &&
      !isPermissionsLoader &&
      !isRefreshTokenLoading &&
      !countriesLoader &&
      !statesLoader
    ) {
      setLoaderState(false);
    }
  }, [
    userLoader,
    permissionLoader,
    isPermissionsLoader,
    setLoaderState,
    isRefreshTokenLoading,
    countriesLoader,
    statesLoader,
  ]);

  useEffect(() => {
    if (userProfile) {
      setUserProfile(userProfile);
    }
  }, [userProfile, setUserProfile]);

  useEffect(() => {
    if (userPermissions) {
      setUserPermissions(userPermissions);
      setPermissionsLoaded(false);
    }

    if (countries?.length) dispatch(setCountry({ countries: countries }));
    if (states?.length) dispatch(setState({ states: states }));
  }, [dispatch, userPermissions, setUserPermissions, setPermissionsLoaded, countries, states]);

  return <>{children}</>;
};

export default AppService;
