import React from 'react';

import { Visibility, VisibilityOff } from '@mui/icons-material';
import { IconButton, InputAdornment } from '@mui/material';

import Translations from '../../assets//locale/en/content.json';

interface PasswordIconProps {
  showPassword: boolean;
  setShowPassword: React.Dispatch<React.SetStateAction<boolean>>;
}

export const TngPasswordVisibleIcon: React.FC<PasswordIconProps> = ({
  showPassword,
  setShowPassword,
}) => {
  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const ariaLabel = showPassword
    ? Translations.Login.togglePasswordShowButton
    : Translations.Login.togglePasswordHideButton;

  return (
    <InputAdornment position="end">
      <IconButton
        className="password_icon_button"
        onClick={handleClickShowPassword}
        onMouseDown={handleMouseDownPassword}
        aria-label={ariaLabel}
        disableRipple
      >
        {showPassword ? <Visibility /> : <VisibilityOff />}
      </IconButton>
    </InputAdornment>
  );
};
