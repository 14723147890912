import React from 'react';

import Translations from '../../../assets/locale/en/content.json';
import { TngGrid, TngTypography } from '../../../components/common';

const ForgotPassFormText = () => {
  return (
    <TngGrid className="form_description">
      <TngGrid className="form_header">
        <TngTypography className="header_title" variant="h3">
          {Translations.ForgotPassword.forgotPassword}
        </TngTypography>
      </TngGrid>
      <TngGrid className="form_context">
        <TngTypography>{Translations.ForgotPassword.forgotPasswordDescriptionText}</TngTypography>
      </TngGrid>
    </TngGrid>
  );
};

export default ForgotPassFormText;
