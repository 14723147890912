import { Contact, ContactNameResponse } from '../../../common/interfaces/contacts/Contacts';
import axiosInstance from '../../axiosInstance';
import { getUrl, URLS } from '../../urls';

/**
Fetches the contacts from the API.
@returns {Promise<Contact[]>} - A promise that resolves to an array of contacts.
@throws {Error} - Throws an error if the request fails.
*/
export const fetchContacts = async (): Promise<Contact[]> => {
  try {
    const url = getUrl(URLS.CONTACTS);
    const { data } = await axiosInstance.get<ContactNameResponse>(url);
    return data.data;
  } catch (error: any) {
    throw new Error('Failed to fetch contacts');
  }
};
