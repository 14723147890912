//when any sorting done use this function to add style/classes to header
export const applySortStyles = (colId: string) => {
  // Clear previous sorted column headers
  const allHeaders = document.querySelectorAll('.ag-header-cell.ag-grid-sorted-column-header');
  allHeaders.forEach((header) => header.classList.remove('ag-grid-sorted-column-header'));

  // Apply style to the currently sorted column
  if (colId) {
    const headerElement = document.querySelector(
      `[col-id="${colId}"].ag-header-cell`,
    ) as HTMLElement | null;
    if (headerElement) {
      headerElement.classList.add('ag-grid-sorted-column-header');
    }
  }
};
