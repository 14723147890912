import React from 'react';

import FieldValueWrapper from './FieldValueWrapper';
import { TngGrid } from '../../../components/common';
import { FieldConfig } from '../modals/fieldConfig';
import { FieldDetails } from '../modals/fieldDetails';
import { CreateFieldConfig } from '../utils/fieldConfig';

interface FieldDetailProps {
  fieldDetails: FieldDetails | undefined;
}

const FieldDetail: React.FC<FieldDetailProps> = ({ fieldDetails }) => {
  const fieldConfig: FieldConfig[] = CreateFieldConfig(fieldDetails);
  return (
    <TngGrid className="tnginfo_fields">
      {fieldConfig.map((field) => (
        <FieldValueWrapper key={field.name} name={field.name} value={field.value} />
      ))}
    </TngGrid>
  );
};

export default FieldDetail;
