import { Country, CountryAPIResponse } from '../../../common/interfaces/Country';
import axiosInstance from '../../axiosInstance';
import { getUrl, URLS } from '../../urls';

/**
Fetches all the countries from the API.
@returns {Promise<Country[]>} - A promise that resolves to country.
@throws {Error} - Throws an error if the request fails.
*/
export const fetchCountries = async (): Promise<Country[]> => {
  try {
    const url = getUrl(URLS.COUNTRY);

    const { data } = await axiosInstance.get<CountryAPIResponse>(url);
    return data.data;
  } catch (err: any) {
    throw new Error('Failed to fetch country data');
  }
};
