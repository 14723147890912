import { ProfileFormField } from '../interfaces/ProfileFormField';

/**
Initialize the react hook form with default/empty value
@returns ProfileFormField- object of profile fields
*/

export const initialFormData = () => {
  const defaultValues: ProfileFormField = {
    firstName: '',
    lastName: '',
    jobTitle: '',
    emailAddress: '',
    phoneNumber: '',
    country: '',
    state: '',
    street: '',
    city: '',
    postalCode: '',
  };
  return defaultValues;
};
