import ExistingOpportunity from './ExistingOpportunity/ExistingOpportunity';
import NewOpportunity from './NewOpportunity/NewOpportunity';
import Translations from '../../../../assets/locale/en/content.json';
import { CustomTabs } from '../../../../components/CustomTab/CustomTabs';

interface TabConfig {
  label: string;
  id: number;
}

const OpportunityTabs = () => {
  const tabConfig: TabConfig[] = [
    { label: Translations.quoteCreation.existingOpportunity, id: 1 },
    { label: Translations.quoteCreation.newOpportunity, id: 2 },
  ];
  return (
    <CustomTabs tabs={tabConfig} isCapsuleTab={true}>
      <ExistingOpportunity />
      <NewOpportunity />
    </CustomTabs>
  );
};

export default OpportunityTabs;
