import { ColorStatusResponse } from '../../../common/interfaces/order/ColorStatus';
import { OrdersAPIResponse } from '../../../common/interfaces/order/OrderResponse';
import { Orders } from '../../../common/interfaces/order/Orders';
import {
  OrderStatusCount,
  OrderStatusCountResponse,
} from '../../../common/interfaces/order/OrderStatusCount';
import { StatusWithColor } from '../../../common/interfaces/StatusColor';
import axiosInstance from '../../axiosInstance';
import { get } from '../../baseApiService';
import { getUrl, URLS } from '../../urls';

/**

Fetches the order statuses from the API.
@returns {Promise<OrderStatus[]>} - A promise that resolves to an array of order statuses.
@throws {Error} - Throws an error if the request fails.
*/

export const fetchOrderStatus = async (): Promise<StatusWithColor[]> => {
  try {
    const url = getUrl(URLS.ORDER_STATUS);

    const { data } = await axiosInstance.get<ColorStatusResponse>(url);
    return data.data;
  } catch (err: any) {
    throw new Error('Failed to fetch order status');
  }
};

/**
Fetches orders by the specified order ID from the API.
@param {string} orderId - The ID of the order to fetch.
@returns {Promise<Orders[]>} - A promise that resolves to an array of orders.
@throws {Error} - Throws an error if the request fails.
*/
export const getOrderById = async (orderId: string): Promise<Orders[]> => {
  try {
    const baseURL = getUrl(URLS.ORDERS);

    const query = new URLSearchParams();
    query.append('OrderId', orderId);

    const url = `${baseURL}?${query.toString()}`;

    const { data } = await get<OrdersAPIResponse>(url);

    return data.data;
  } catch (err: any) {
    throw new Error('Failed to fetch order using orderId');
  }
};

/**

Fetches the order statuses count from the API.
@returns {Promise<OrderStatusCount[]>} - A promise that resolves to an array of order statuses count.
@throws {Error} - Throws an error if the request fails.
*/

export const fetchOrderStatusCount = async (): Promise<OrderStatusCount[]> => {
  try {
    const url = getUrl(URLS.ORDER_STATUS_COUNTS);

    const { data } = await axiosInstance.get<OrderStatusCountResponse>(url);
    return data.data;
  } catch (err: any) {
    throw new Error('Failed to fetch order status count');
  }
};
