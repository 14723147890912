import { useEffect, useState } from 'react';

import { defaultAPIParams } from './gridHelper/apiParams';
import { getGridConfig } from './gridHelper/gridConfig';
import { mapExistingOpportunitiesToExistingOpportunityGridModal } from './gridHelper/mapperFunctions';
import { GridActionEnum } from '../../../../../common/enums/GridActionEnum';
import {
  useGridDispatch,
  useGridState,
} from '../../../../../common/GridContext/GridContextProvider';
import { ExistingOpportunityGridUrl } from '../../../../../common/interfaces/urls/ExistingOpportunityGridUrl';
import { TngGrid } from '../../../../../components/common';
import TngAgGrid from '../../../../../components/TngGridSection/TngAgGrid/TngAgGrid';
import { useQuoteCreationDispatch } from '../../../context/ContextProvider';
import { ContextActionEnum } from '../../../enums/ContextActionEnums';
import { ExistingOpportunity } from '../../../modals/ExistingOpportunity';

const ExistingOpportunityGrid = () => {
  const [existingOpportunityValue, setExistingOpportunityValue] = useState<ExistingOpportunity>({
    selected: true,
    selectedOpportunity: '',
  });
  const { gridData, search, pageNumber, pageSize, sorting, loading } = useGridState();
  const dispatch = useGridDispatch();
  const quoteDispatch = useQuoteCreationDispatch();

  // check if gridData is present than called the mapper to map the data according to grid columns
  useEffect(() => {
    if (gridData && gridData.length > 0) {
      const mappedData = mapExistingOpportunitiesToExistingOpportunityGridModal(gridData);
      dispatch({ type: GridActionEnum.SET_GRID_MAPPED_DATA, payload: mappedData });
      setTimeout(() => {
        dispatch({ type: GridActionEnum.SET_LOADER, payload: false });
      }, 1000);
    } else {
      if (!loading) {
        dispatch({ type: GridActionEnum.SET_GRID_MAPPED_DATA, payload: [] });
      }
    }
  }, [dispatch, gridData, loading]);

  useEffect(() => {
    if (existingOpportunityValue.selectedOpportunity) {
      quoteDispatch({
        type: ContextActionEnum.SET_EXISTING_OPPORTUNITY,
        payload: existingOpportunityValue,
      });
    }
  }, [existingOpportunityValue.selectedOpportunity]);

  // create or get default api params
  const apiParams: ExistingOpportunityGridUrl = defaultAPIParams({
    pageNumber,
    pageSize,
    search,
    sorting,
  });

  return (
    <TngGrid
      className="existing_opportunity_grid_container"
      data-testid="existing_opportunity_container"
    >
      <TngAgGrid
        gridConfig={getGridConfig(apiParams, existingOpportunityValue, setExistingOpportunityValue)}
      />
    </TngGrid>
  );
};

export default ExistingOpportunityGrid;
