import dayjs from 'dayjs';

import { isArrayEquals } from '../../../utils/isArrayEquals';
import { OrderHistoryFilter } from '../modals/OrderHistoryFilter';
import { OrderHistoryFilterContext } from '../modals/OrderHistoryFilterContext';

export const initializeFilterState = (filters: OrderHistoryFilterContext) => {
  const initialFilter: OrderHistoryFilter = {
    customerIds: filters.customerIds || [],
    soldThrough: filters.soldThrough || [],
    OrdStatus: filters.OrdStatus || [],
    poDateFrom: filters.poDateFrom ? dayjs(filters.poDateFrom) : null,
    poDateTo: filters.poDateTo ? dayjs(filters.poDateTo) : null,
    customerValue: filters.customerValue || [],
    soldThroughValue: filters.soldThroughValue || [],
  };
  return initialFilter;
};

// Check if filters have been modified or not
export const isAnyFilterSelected = (filterObj: OrderHistoryFilter): boolean => {
  const parsedPoDateFrom = dayjs(filterObj.poDateFrom);
  const parsedPoDateTo = dayjs(filterObj.poDateTo);
  return (
    filterObj.customerIds.length > 0 ||
    filterObj.soldThrough.length > 0 ||
    filterObj.OrdStatus.length > 0 ||
    (filterObj.poDateFrom !== null && parsedPoDateFrom.isValid()) ||
    (filterObj.poDateTo !== null && parsedPoDateTo.isValid())
  );
};

export const checkIfFiltersChanged = (
  filterObj: OrderHistoryFilter,
  defaultFilterObj: OrderHistoryFilter,
): boolean => {
  // Parse and validate the date fields
  const parsedPoDateFrom = dayjs(filterObj.poDateFrom);
  const parsedPoDateTo = dayjs(filterObj.poDateTo);

  const parsedDefaultPoDateFrom = dayjs(defaultFilterObj.poDateFrom);
  const parsedDefaultPoDateTo = dayjs(defaultFilterObj.poDateTo);

  // Check if any filters have changed by comparing with default values
  return (
    !isArrayEquals(filterObj.customerIds, defaultFilterObj.customerIds) ||
    !isArrayEquals(filterObj.soldThrough, defaultFilterObj.soldThrough) ||
    !isArrayEquals(filterObj.OrdStatus, defaultFilterObj.OrdStatus) ||
    (filterObj.poDateFrom !== defaultFilterObj.poDateFrom &&
      parsedPoDateFrom.isValid() &&
      !parsedPoDateFrom.isSame(parsedDefaultPoDateFrom)) ||
    (filterObj.poDateTo !== defaultFilterObj.poDateTo &&
      parsedPoDateTo.isValid() &&
      !parsedPoDateTo.isSame(parsedDefaultPoDateTo))
  );
};
