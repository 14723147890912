import { UserTypeOptions } from '../../../../common/interfaces/user/UserTypes';

// Function to extract userType value by its label
export const getUserTypeValueByLabel = (
  label: string,
  userTypeOptions: UserTypeOptions[],
): string => {
  const option = userTypeOptions.find((option: UserTypeOptions) => option.label === label);
  return option ? option.value : label;
};
