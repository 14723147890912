import React from 'react';

import Translations from '../../../assets/locale/en/content.json';
import { TngGrid, TngToggleSection, TngTypography } from '../../../components/common';

const PartnerProgramPriceList = () => {
  return (
    <TngGrid container className="global_partner_program_price_list_container">
      <TngToggleSection
        title={Translations.PartnerProgram.globalPriceList}
        content={
          <>
            <TngTypography variant="body1">
              {Translations.PartnerProgram.globalPriceListTextContent}
            </TngTypography>
            <TngTypography variant="body1">
              <strong>{Translations.PartnerProgram.generallyAvailablePriceList}</strong> -{' '}
              {Translations.PartnerProgram.generallyAvailablePriceListContent}
            </TngTypography>
            <TngTypography variant="body1">
              <strong>{Translations.PartnerProgram.endOfGenerallyAvailablePriceList}</strong> -{' '}
              {Translations.PartnerProgram.endOfGenerallyAvailablePriceListContent}
            </TngTypography>
            <TngTypography variant="body1">
              <strong>{Translations.PartnerProgram.gplChanges}</strong> -{' '}
              {Translations.PartnerProgram.gplChangesContent}
            </TngTypography>
            <TngTypography variant="body1">
              <strong>{Translations.PartnerProgram.flatFiles}</strong> -{' '}
              {Translations.PartnerProgram.flatFilesContent}
            </TngTypography>
          </>
        }
      />
    </TngGrid>
  );
};

export default PartnerProgramPriceList;
