import Translations from '../../../assets/locale/en/content.json';
import { ColumnDef } from '../../../common/interfaces/ColumnDef';

// this function is used to create column definations for order history grid

export const getColumnHeaders = (): ColumnDef[] => {
  return [
    {
      name: 'certificationName',
      displayName: Translations.trainingCertifications.identifier,
      colWidth: 200,
    },
    {
      name: 'certificationDescription',
      displayName: Translations.trainingCertifications.certificationName,
      showTooltip: true,
      colWidth: 350,
    },
    {
      name: 'contactName',
      displayName: Translations.trainingCertifications.contact,
      colWidth: 200,
    },
    {
      name: 'certificationDate',
      displayName: Translations.trainingCertifications.certificationDate,
    },
    { name: 'grade', displayName: Translations.trainingCertifications.grade, colWidth: 90 },
  ];
};
