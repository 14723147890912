import React, { createContext, useContext, ReactNode, useReducer, Dispatch } from 'react';

import { ContextReducers } from './ContextReducers';
import { ContextActions } from '../modals/contextAction';
import { ContextState } from '../modals/contextState';

export interface QuoteCreationContextType {
  state: ContextState;
  dispatch: Dispatch<ContextActions>; // Adjust 'any' as per your specific action types
}

// Initial state factory function to support multiple configurations
export const initialState = (): ContextState => ({
  existingOpportunity: {
    selected: true,
    selectedOpportunity: '',
  },
  prevButtonProps: {},
  nextButtonProps: {},
});

interface QuoteCreationProviderProps {
  children: ReactNode | ReactNode[];
}

// Creating context with an undefined default value
export const DataContext = createContext<QuoteCreationContextType | undefined>(undefined);

export const QuoteCreationContextProvider: React.FC<QuoteCreationProviderProps> = ({
  children,
}) => {
  // State to manage the grid context
  const [state, dispatch] = useReducer(ContextReducers, initialState());

  return <DataContext.Provider value={{ state, dispatch }}>{children}</DataContext.Provider>;
};

// method to get state
export const useQuoteCreationState = () => {
  const context = useContext(DataContext);
  if (!context) {
    throw new Error('useGridState must be used within a GridProvider');
  }
  return context.state;
};

// method to get dispatch dunction
export const useQuoteCreationDispatch = () => {
  const context = useContext(DataContext);
  if (!context) {
    throw new Error('useGridDispatch must be used within a GridProvider');
  }
  return context.dispatch;
};
