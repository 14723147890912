import { DefaultSearch } from '../../../../../common/interfaces/GridSearchConfig';
import { DefaultSorting } from '../../../../../common/interfaces/GridSortingConfig';
import { ProductListGridUrl } from '../../../../../common/interfaces/urls/ProductListGridUrl';

interface defaultAPIParamsProps {
  pageNumber: number;
  pageSize: number;
  search: DefaultSearch;
  sorting: DefaultSorting;
}

// used to create default API params for grid
export const defaultAPIParams = ({
  pageNumber,
  pageSize,
  search,
  sorting,
}: defaultAPIParamsProps): ProductListGridUrl => {
  const apiParams: ProductListGridUrl = {
    pageNumber: pageNumber,
    pageSize: pageSize,
    productName: search.searchValue,
    sortingField: sorting.colId,
    sortingOrder: sorting.sortOrder,
  };
  return apiParams;
};
