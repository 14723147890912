import React from 'react';

import Popover, { PopoverProps } from '@mui/material/Popover';

interface TngPopoverProps extends PopoverProps {
  children: React.ReactNode;
}

export const TngPopover: React.FC<TngPopoverProps> = ({ children, ...props }) => {
  return <Popover {...props}>{children}</Popover>;
};
