import { configureStore } from '@reduxjs/toolkit';

import authReducer from './auth/authSlice';
import masterReducer from './master/masterSlice';
import userReducer from './user/userSlice';

const store = configureStore({
  reducer: {
    auth: authReducer,
    user: userReducer,
    master: masterReducer,
    // Add reducers for other features here
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(),
});

export default store;
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
