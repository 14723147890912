import React from 'react';

const WordIcon = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.6668 9.00065H1.3335L0.333496 7.66732L1.66683 6.33398H14.3335L15.6668 7.66732L14.6668 9.00065Z"
        fill="#12376B"
      />
      <path
        d="M13.667 15.6673H2.33366C1.96533 15.6673 1.66699 15.369 1.66699 15.0007V1.00065C1.66699 0.632318 1.96533 0.333984 2.33366 0.333984H10.3337L14.3337 4.33398V15.0007C14.3337 15.369 14.0353 15.6673 13.667 15.6673Z"
        fill="#E6E6E6"
      />
      <path
        d="M10.3335 0.333984V3.66732C10.3335 4.03565 10.6318 4.33398 11.0002 4.33398H14.3335L10.3335 0.333984Z"
        fill="#B3B3B3"
      />
      <path
        d="M15.0002 13.668H1.00016C0.631829 13.668 0.333496 13.3696 0.333496 13.0013V7.66797H15.6668V13.0013C15.6668 13.3696 15.3685 13.668 15.0002 13.668Z"
        fill="#27549B"
      />
      <path
        d="M5.18294 10.6361C5.18294 11.0731 5.0626 11.4095 4.82194 11.6458C4.58127 11.8825 4.24327 12.0005 3.80794 12.0005H2.96094V9.38281H3.86694C4.28727 9.38281 4.61127 9.49015 4.83994 9.70515C5.0686 9.92015 5.18294 10.2301 5.18294 10.6361ZM4.4486 10.6611C4.4486 10.4211 4.40127 10.2435 4.30627 10.1275C4.2116 10.0115 4.06727 9.95381 3.87394 9.95381H3.66794V11.4198H3.8256C4.0406 11.4198 4.19794 11.3571 4.29827 11.2325C4.3986 11.1078 4.4486 10.9178 4.4486 10.6611Z"
        fill="white"
      />
      <path
        d="M8.10133 10.6881C8.10133 11.1288 7.99333 11.4635 7.77733 11.6928C7.56133 11.9221 7.245 12.0365 6.82833 12.0365C6.41766 12.0365 6.103 11.9211 5.88366 11.6908C5.665 11.4608 5.55566 11.1251 5.55566 10.6848C5.55566 10.2488 5.66433 9.91613 5.88233 9.68646C6.09966 9.4568 6.41666 9.3418 6.832 9.3418C7.24833 9.3418 7.564 9.45613 7.779 9.6838C7.994 9.91146 8.10133 10.2468 8.10133 10.6881ZM6.30033 10.6881C6.30033 11.1945 6.476 11.4471 6.82833 11.4471C7.00733 11.4471 7.14033 11.3858 7.22666 11.2628C7.313 11.1398 7.35633 10.9485 7.35633 10.6878C7.35633 10.4268 7.31266 10.2335 7.22466 10.1088C7.13666 9.98413 7.006 9.92146 6.83166 9.92146C6.47766 9.92213 6.30033 10.1775 6.30033 10.6881Z"
        fill="white"
      />
      <path
        d="M9.73938 9.9257C9.57105 9.9257 9.43972 9.99437 9.34538 10.1324C9.25138 10.27 9.20372 10.46 9.20372 10.7024C9.20372 11.2064 9.39538 11.4584 9.77872 11.4584C9.89472 11.4584 10.0067 11.442 10.1154 11.4097C10.2241 11.3774 10.3331 11.3387 10.4427 11.2934V11.891C10.2247 11.988 9.97738 12.0364 9.70172 12.0364C9.30638 12.0364 9.00372 11.9217 8.79272 11.6927C8.58205 11.4634 8.47705 11.132 8.47705 10.6994C8.47705 10.4284 8.52772 10.1904 8.63005 9.9847C8.73238 9.77904 8.87872 9.62137 9.07005 9.51104C9.26205 9.4007 9.48738 9.3457 9.74605 9.3457C10.0291 9.3457 10.2991 9.40704 10.5571 9.53004L10.3407 10.0867C10.2441 10.0414 10.1474 10.0034 10.0507 9.97204C9.95405 9.94104 9.85038 9.9257 9.73938 9.9257Z"
        fill="white"
      />
      <path
        d="M13.3114 12.0005H12.4931L11.9827 11.1821L11.4781 12.0005H10.6777L11.5457 10.6611L10.7294 9.38281H11.5141L11.9867 10.1921L12.4411 9.38281H13.2487L12.4197 10.7165L13.3114 12.0005Z"
        fill="white"
      />
    </svg>
  );
};

export default WordIcon;
