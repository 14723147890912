import React from 'react';

const KnowledgeCenterIcon = () => {
  return (
    <svg width="24" height="21" viewBox="0 0 24 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12 3V20" stroke="#00A0FB" strokeMiterlimit="10" />
      <path
        d="M17.5 1C14.5 1 12 2.3 12 4C12 2.3 9.5 1 6.5 1C3.5 1 1 2.3 1 4V21C1 19.3 3.5 18 6.5 18C9.5 18 12 19.3 12 21C12 19.3 14.5 18 17.5 18C20.5 18 23 19.3 23 21V4C23 2.3 20.5 1 17.5 1Z"
        stroke="#00A0FB"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
    </svg>
  );
};

export default KnowledgeCenterIcon;
