import { useCallback, useState } from 'react';

import { fetchOpportunityList } from '../../api/services/quote/quoteService';

export const useOpportunityList = () => {
  const [error, setError] = useState<string | null>(null);

  // A callback function which we call to fetch opportunity list
  const getOpportunityList = useCallback(async (opportunityName: string) => {
    try {
      const data = await fetchOpportunityList(opportunityName);
      return data;
    } catch (error: any) {
      setError('Failed to fetch opportunity list');
      throw Error(error.message);
    }
  }, []);

  return { getOpportunityList, error };
};
