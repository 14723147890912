import React, { ReactNode } from 'react';

import { TngGrid } from './../common';

interface SkipToMainContentProps {
  children: ReactNode;
}

const SkipToMainContent: React.FC<SkipToMainContentProps> = ({ children }) => {
  return (
    <TngGrid id="main-content" role="main">
      {children}
    </TngGrid>
  );
};

export default SkipToMainContent;
