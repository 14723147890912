import React, { useState } from 'react';

import { useForm, SubmitHandler } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { resetPasswordFieldConfig } from './FieldConfig';
import FieldRendering from './FieldRendering';
import PasswordRules from './PasswordRules';
import VerificationCodeSection from './VerificationCodeSection';
import Translations from '../../../assets/locale/en/content.json';
import usePasswordRecovery from '../../../common/hooks/usePasswordRecovery';
import { TngButton, TngGrid, TngTypography } from '../../../components/common';
import { useToast } from '../../../components/Toasts/ToastContext';
import { getCleanErrorMessage } from '../../../utils/getCleanErrorMessage';
import { parseErrorMessage } from '../../../utils/parseErrorMesssage';
import { isValidPassword } from '../../Login/utils/FormValidations';

export interface FormValues {
  password: string;
  confirmPassword: string;
  verificationCode: string;
}

type ResetPasswordFormProps = {
  username: string;
  timer: number;
  setTimer: React.Dispatch<React.SetStateAction<number>>;
  resendEnabled: boolean;
  setResendEnabled: React.Dispatch<React.SetStateAction<boolean>>;
  handleResendCode: () => void;
};

const ResetPasswordForm: React.FC<ResetPasswordFormProps> = ({
  timer,
  resendEnabled,
  handleResendCode,
}) => {
  const navigate = useNavigate();
  const { recoverPassword } = usePasswordRecovery();
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState<boolean>(false);

  // toast hook to dusplay toast messages
  const { showToast } = useToast();

  const {
    handleSubmit,
    register,
    formState: { errors },
    setError,
    watch,
    trigger,
  } = useForm<FormValues>();

  const passwordValue = watch('password');
  const confirmPasswordValue = watch('confirmPassword');

  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    if (!isValidPassword(data.password)) {
      setError('password', {
        type: 'manual',
        message: Translations.ResetPassword.invalidPasswordMessage,
      });
      return;
    }

    if (data.password !== data.confirmPassword) {
      setError('confirmPassword', {
        type: 'manual',
        message: Translations.ResetPassword.passwordMismatchMessage,
      });
      return;
    }

    try {
      const response: any = await recoverPassword(data.verificationCode, data.password);
      const resumeUrl = response?.resumeUrl;
      showToast(Translations.ResetPassword.success_message, {
        type: 'success',
      });
      navigate(`/?resumeUrl=${resumeUrl}`);
    } catch (error: any) {
      const cleanError = getCleanErrorMessage(error);
      const errorRes = parseErrorMessage(cleanError);
      if (errorRes.target === 'recoveryCode' && errorRes.responseCode === 'INVALID_VALUE') {
        setError('verificationCode', {
          type: 'MANUAL',
          message: Translations.ResetPassword.invalidVerificationCodeMessage,
        });
      } else {
        setError('verificationCode', {
          type: errorRes.responseCode,
          message: errorRes.responseMessage,
        });
      }
    }
  };

  return (
    <TngGrid container className="reset_password_form">
      <TngGrid item xs={12} className="reset_password_form_heading">
        <TngTypography variant="h3">{Translations.ResetPassword.reset}</TngTypography>
      </TngGrid>
      <form onSubmit={handleSubmit(onSubmit)}>
        {resetPasswordFieldConfig.map((field) => (
          <>
            <TngGrid item xs={12} key={field.htmlFor} className="reset_password_input_fields">
              <FieldRendering
                field={field}
                showPassword={showPassword}
                showConfirmPassword={showConfirmPassword}
                errors={errors}
                register={register}
                trigger={trigger}
                watch={watch}
                setShowPassword={setShowPassword}
                setShowConfirmPassword={setShowConfirmPassword}
              />
            </TngGrid>
            <TngGrid item xs={12} className="reset_password_password_rules">
              {field.htmlFor === 'password' && <PasswordRules passwordValue={passwordValue} />}
            </TngGrid>
            <TngGrid item xs={12} className="reset_password_password_rules">
              {field.htmlFor === 'confirmPassword' && (
                <PasswordRules
                  passwordValue={passwordValue}
                  confirmPasswordValue={confirmPasswordValue}
                  isPasswordRulesRender={false}
                />
              )}
            </TngGrid>
          </>
        ))}
        <VerificationCodeSection
          timer={timer}
          resendEnabled={resendEnabled}
          handleResendCode={handleResendCode}
        />
        <TngButton type="submit" className="reset_password_form_submit_button">
          {Translations.ResetPassword.reset}
        </TngButton>
      </form>
    </TngGrid>
  );
};

export default ResetPasswordForm;
