import { DefaultSearch } from '../../../../../../common/interfaces/GridSearchConfig';
import { DefaultSorting } from '../../../../../../common/interfaces/GridSortingConfig';
import { ExistingOpportunityGridUrl } from '../../../../../../common/interfaces/urls/ExistingOpportunityGridUrl';

interface defaultAPIParamsProps {
  pageNumber: number;
  pageSize: number;
  search: DefaultSearch;
  sorting: DefaultSorting;
}

// used to create default API params for grid
export const defaultAPIParams = ({
  pageNumber,
  pageSize,
  search,
  sorting,
}: defaultAPIParamsProps): ExistingOpportunityGridUrl => {
  const apiParams: ExistingOpportunityGridUrl = {
    pageNumber: pageNumber,
    pageSize: pageSize,
    searchValue: search.searchValue,
    sortingField: sorting?.colId,
    sortingOrder: sorting?.sortOrder,
  };
  return apiParams;
};
