import React from 'react';

import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import { useNavigate } from 'react-router-dom';

import Translations from '../../../assets/locale/en/content.json';
import { TngButton, TngGrid } from '../../../components/common';

const BackNavigation: React.FC = () => {
  const navigate = useNavigate();

  return (
    <TngGrid className="forgot_password_navigation">
      <TngButton
        className="navigation_btn"
        startIcon={<KeyboardArrowLeftIcon />}
        onClick={() => navigate('/')}
      >
        {Translations.ForgotPassword.backToLoginPage}
      </TngButton>
    </TngGrid>
  );
};

export default BackNavigation;
