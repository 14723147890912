import React from 'react';

import { Checkbox, FormControlLabel, Typography } from '@mui/material';

import Translations from '../../../../assets/locale/en/content.json';
import { StatusWithColor } from '../../../../common/interfaces/StatusColor';
import { TngGrid } from '../../../../components/common';
import { OrderHistoryFilter } from '../../modals/OrderHistoryFilter';

interface OrderStatusProps {
  orderStatuses: StatusWithColor[];
  filterObj: OrderHistoryFilter;
  setFiltersObj: React.Dispatch<React.SetStateAction<OrderHistoryFilter>>;
}

const FilterOrderStatus: React.FC<OrderStatusProps> = ({
  orderStatuses,
  filterObj,
  setFiltersObj,
}) => {
  // update state after user select/deselect order status in filter
  const handleOrderStatusToggle = (Id: number) => () => {
    const existingOrderStatus = filterObj?.OrdStatus || [];
    const newFilters = existingOrderStatus.includes(Id)
      ? existingOrderStatus.filter((value: any) => value !== Id)
      : [...existingOrderStatus, Id];

    setFiltersObj({ ...filterObj, OrdStatus: newFilters });
  };

  return (
    <TngGrid container marginTop="20px">
      <TngGrid item xs={12} className="order_status_text">
        <Typography variant="h5">{Translations.filterSection.orderStatus}</Typography>
      </TngGrid>
      <TngGrid item xs={12} className="order_status_checkbox">
        {orderStatuses.map((status: StatusWithColor) => (
          <FormControlLabel
            sx={{ display: 'block' }}
            key={status.id}
            control={
              <Checkbox
                aria-label={Translations.filterSection.orderStatus}
                checked={(filterObj.OrdStatus || []).includes(status.id)}
                onChange={handleOrderStatusToggle(status.id)}
              />
            }
            label={status.name}
          />
        ))}
      </TngGrid>
    </TngGrid>
  );
};

export default FilterOrderStatus;
