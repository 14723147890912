import { useState, useCallback } from 'react';

import { fetchOTPService } from '../../api/services/auth/authService';
import { DEFAULT_ERROR_MESSAGE, RECOVERY_CODE_REQUIRED } from '../constant';

interface FetchOTPResponse {
  status: string;
  response: any;
  flowId: string;
}
// fetch OTP from PingIdentity
const useFetchOTP = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const fetchOTP = useCallback(async (username: string): Promise<FetchOTPResponse> => {
    setLoading(true);
    setError(null);

    try {
      const flowId = '';
      const response: any = await fetchOTPService(flowId, username);

      // Return the appropriate status and response
      const status =
        response.status === RECOVERY_CODE_REQUIRED ? RECOVERY_CODE_REQUIRED : response.status;
      return { status, response, flowId };
    } catch (err: any) {
      const errorMessage = err.message || DEFAULT_ERROR_MESSAGE;
      setError(errorMessage);
      throw new Error(errorMessage);
    } finally {
      setLoading(false);
    }
  }, []);

  return { fetchOTP, loading, error };
};

export default useFetchOTP;
