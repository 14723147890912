import React from 'react';

import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';

import Translations from '../../../../assets/locale/en/content.json';
import { TngGrid, TngTypography } from '../../../../components/common';
import { OrderHistoryFilter } from '../../modals/OrderHistoryFilter';

interface PODatesProps {
  filterObj: OrderHistoryFilter;
  setFiltersObj: React.Dispatch<React.SetStateAction<OrderHistoryFilter>>;
  poDateError: string | null;
}

const PODates: React.FC<PODatesProps> = ({ filterObj, setFiltersObj, poDateError }) => {
  const today = dayjs().startOf('day'); // Get today's date at midnight for maxDate

  // Helper function to get the minimum date between `poDateTo` and `today`
  const getMaxDate = () => {
    if (filterObj.poDateTo && filterObj.poDateTo.isBefore(today)) {
      return filterObj.poDateTo;
    }
    return today;
  };

  const handlePoDateChange = (key: string) => (event: Dayjs | null) => {
    setFiltersObj({ ...filterObj, [key]: event });
  };

  return (
    <TngGrid container marginTop="15px" className="po_date_range_container">
      <TngGrid item xs={12} sm={12} md={12} lg={6} className="po_date_range">
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <TngTypography variant="h5" id="po-date-from-range-label">
            {Translations.filterSection.fromPoDate}
          </TngTypography>
          <DatePicker
            value={filterObj.poDateFrom}
            onChange={handlePoDateChange('poDateFrom')}
            format="YYYY-MM-DD"
            maxDate={getMaxDate()} // Call helper function to get the maximum date
            slotProps={{
              textField: {
                inputProps: {
                  'aria-label': `${Translations.filterSection.poDateRange} from`,
                  readOnly: true,
                  tabIndex: -1,
                },
              },
              field: {
                readOnly: true,
              },
              openPickerButton: {
                'aria-describedby': 'po-date-from-range-label',
              },
            }}
          />
        </LocalizationProvider>
      </TngGrid>
      <TngGrid item xs={12} sm={12} md={12} lg={6} className="po_date_range" textAlign="right">
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <TngTypography variant="h5" id="po-date-to-range-label">
            {Translations.filterSection.toPoDate}
          </TngTypography>
          <DatePicker
            value={filterObj.poDateTo}
            onChange={handlePoDateChange('poDateTo')}
            format="YYYY-MM-DD"
            minDate={filterObj.poDateFrom || undefined} // Min date is the "From" date
            maxDate={today} // Max date cannot be beyond today
            slotProps={{
              textField: {
                inputProps: {
                  'aria-label': `${Translations.filterSection.poDateRange} to`,
                  readOnly: true,
                  tabIndex: -1,
                },
              },
              field: {
                readOnly: true,
              },
              openPickerButton: {
                'aria-describedby': 'po-date-to-range-label',
              },
            }}
          />
        </LocalizationProvider>
      </TngGrid>
      {poDateError && (
        <TngGrid item xs={12} className="po_date_error">
          <TngTypography variant="body2" color="error" aria-live="assertive" role="alert">
            {poDateError}
          </TngTypography>
        </TngGrid>
      )}
    </TngGrid>
  );
};

export default PODates;
