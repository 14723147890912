import { useCallback } from 'react';

import { useLocation } from 'react-router-dom';

import { recoverPasswordService } from '../../api/services/auth/authService';
import Translations from '../../assets/locale/en/content.json';

const usePasswordRecovery = () => {
  // Extract flowId from the URL
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const flowId = searchParams.get('flowId');

  // Define the function to recover the password
  const recoverPassword = useCallback(
    async (recoveryCode: string, newPassword: string) => {
      if (!flowId) {
        console.error(Translations.ResetPassword.missingFlowId); // Use translation for missing flowId
        return;
      }

      try {
        const response = await recoverPasswordService(flowId, {
          recoveryCode,
          newPassword,
        });

        return response;
      } catch (error: any) {
        console.error(Translations.ResetPassword.recoveryError, error);
        throw error;
      }
    },
    [flowId], // Memoize the function based on flowId
  );

  return { recoverPassword };
};

export default usePasswordRecovery;
