import React from 'react';

const ManageQuotesIcon = () => {
  return (
    <svg width="20" height="24" viewBox="0 0 20 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M13 23V17H19" strokeMiterlimit="10" />
      <path d="M13 23H1V1H19V17L13 23Z" strokeMiterlimit="10" strokeLinecap="square" />
      <path d="M5 6H15" strokeMiterlimit="10" strokeLinecap="square" />
      <path d="M5 11H15" strokeMiterlimit="10" strokeLinecap="square" />
      <path d="M5 16H9" strokeMiterlimit="10" strokeLinecap="square" />
    </svg>
  );
};

export default ManageQuotesIcon;
