import React from 'react';

import { ColorStatus } from '../../../common/interfaces/order/ColorStatus';
import { SearchDropdown } from '../../../common/interfaces/SearchDropdown';
import { TngBox, TngButton, TngGrid } from '../../../components/common';
import { CreateStatusColorObj } from '../../../utils/createStatusColorObj';
import { TngMenuButton } from '../../common/TngMenuButton';

export interface HeaderDetails {
  fieldName: string;
  fieldValue: string;
  fieldStatus: string;
}

interface FieldHeaderProps {
  headerDetails: HeaderDetails | undefined;
  statusColors: ColorStatus[];
  dropdownOptions: SearchDropdown[];
}

const TngInfoCardHeader: React.FC<FieldHeaderProps> = ({
  headerDetails,
  statusColors,
  dropdownOptions,
}) => {
  const colors = CreateStatusColorObj(statusColors);

  const borderColor = (status: string) => {
    const color = colors[status as keyof typeof colors]
      ? `#${colors[status as keyof typeof colors]}`
      : '#634E12';
    return color;
  };

  return (
    <TngBox className="fields_header">
      <TngGrid className="field_wrapper">
        <TngGrid className="field_name">{headerDetails?.fieldName}</TngGrid>
        <TngGrid className="info_field_value">
          <TngGrid className="field_value">{headerDetails?.fieldValue}</TngGrid>
          <TngGrid
            className="field_status"
            sx={{ border: `1px solid ${borderColor(`${headerDetails?.fieldStatus}`)}` }}
          >
            {headerDetails?.fieldStatus}
          </TngGrid>
        </TngGrid>
      </TngGrid>

      {dropdownOptions.length === 1 ? (
        <TngButton className="header_btn">{dropdownOptions[0].value}</TngButton>
      ) : (
        <>
          <TngMenuButton menuItems={dropdownOptions} />
        </>
      )}
    </TngBox>
  );
};

export default TngInfoCardHeader;
