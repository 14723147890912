import React from 'react';

import TngPaginationButtons from './TngPaginationButtons';
import TngPaginationDetails from './TngPaginationDetails';
import TngPaginationDropdown from './TngPaginationDropdown';
import { TngGrid } from '../../../../common';

const TngDataGridPagination = () => {
  return (
    <TngGrid container className="ag_grid_pagination">
      <TngGrid item className="pagination_start_container"></TngGrid>
      <TngGrid item>
        <TngPaginationDropdown />
      </TngGrid>
      <TngGrid item>
        <TngPaginationDetails />
      </TngGrid>
      <TngGrid item>
        <TngPaginationButtons />
      </TngGrid>
    </TngGrid>
  );
};

export default TngDataGridPagination;
