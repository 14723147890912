import { Filter } from '../../../common/interfaces/GridContextState';
import { DefaultSearch } from '../../../common/interfaces/GridSearchConfig';
import { DefaultSorting } from '../../../common/interfaces/GridSortingConfig';
import { ManageQuotesGridUrl } from '../../../common/interfaces/urls/ManageQuotesGridUrl';
import { ListToPipeSeperator } from '../../../utils/listToPipeSeperator';

interface defaultAPIParamsProps {
  pageNumber: number;
  pageSize: number;
  search: DefaultSearch;
  sorting: DefaultSorting;
  filters: Filter;
}

// used to create default API params for grid
export const defaultManageQuotesAPIParams = ({
  pageNumber,
  pageSize,
  search,
  sorting,
  filters,
}: defaultAPIParamsProps): ManageQuotesGridUrl => {
  const apiParams: ManageQuotesGridUrl = {
    pageNumber: pageNumber,
    pageSize: pageSize,
    searchParam: search.searchBy,
    searchValue: search.searchValue,
    sortingField: sorting?.colId,
    sortingOrder: sorting?.sortOrder,
    quoteStatus: filters?.quoteStatus && ListToPipeSeperator(filters?.quoteStatus),
    opportunityName: filters?.opportunities && ListToPipeSeperator(filters?.opportunities),
  };
  return apiParams;
};
