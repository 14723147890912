import React, { ReactNode, forwardRef } from 'react';

import ListItemButton, { ListItemButtonProps } from '@mui/material/ListItemButton';

interface TngListItemButtonProp extends ListItemButtonProps {
  children: ReactNode | ReactNode[];
}

export const TngListItemButton = forwardRef<HTMLButtonElement, TngListItemButtonProp>(
  ({ children, ...props }, ref) => {
    return (
      <ListItemButton component="button" {...props} ref={ref}>
        {children}
      </ListItemButton>
    );
  },
);

TngListItemButton.displayName = 'TngListItemButton';
