import { TransformDate } from '../../../utils/dateTimeConvertor';
import { OrderGridModal } from '../modals/OrderGridModal';
import { OrderHistoryModal } from '../modals/OrderHistoryModal';

// to map the data according to grid columns
export const mapOrdersToOrderGridModal = (orders: OrderHistoryModal[]): OrderGridModal[] => {
  return orders.map((order) => ({
    orderId: order.id,
    PONum: order.purchaseOrderNumber,
    OrdStatus: order.orderStatus,
    CustName: order.customerName,
    SoldThrough: order.soldThrough,
    QuoteId: order.quoteId,
    PODateFrom: TransformDate(order.purchaseOrderDate),
    ConNum: order.contractNumber,
  }));
};
