import React from 'react';

interface TngButtonLiveRegionProps {
  liveRegionRef: React.MutableRefObject<HTMLDivElement | null>;
}

const TngButtonLiveRegion: React.FC<TngButtonLiveRegionProps> = ({ liveRegionRef }) => {
  // using div because this didn't work with TngGrid
  return (
    <div
      ref={liveRegionRef}
      role="status"
      aria-live="polite"
      style={{ position: 'absolute', left: '-9999px' }}
    ></div>
  );
};

export default TngButtonLiveRegion;
