import { OrderStatusEnum } from '../../../common/enums/OrderStatusEnum';

export const getClassNameForValue = (status: string) => {
  let className;
  switch (status) {
    case OrderStatusEnum.PendingFulfillment:
      className = 'pending_fulfillment_card';
      break;
    case OrderStatusEnum.Fulfilled:
      className = 'fulfillment_card';
      break;
    case OrderStatusEnum.CreditHold:
      className = 'credit_hold_card';
      break;
    case OrderStatusEnum.Billed:
      className = 'billed_card';
      break;
    case OrderStatusEnum.TotalOrders:
      className = 'total_orders_card';
      break;
    default:
      className = '';
      break;
  }
  return className;
};
