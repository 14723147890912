import React, { useEffect } from 'react';

import BackNavigation from './components/BackNavigation';
import ForgotPassForm from './components/ForgotPassForm';
import ForgotPassFormText from './components/ForgotPassFormText';
import { TngGrid } from '../../components/common';
import { focusFirstInteractiveElement } from '../../utils/findInteractiveElement';

const ForgotPassword = () => {
  useEffect(() => {
    focusFirstInteractiveElement();
  }, []);
  return (
    <TngGrid className="forgot_password_container">
      <TngGrid className="forgot_password_form_wrapper">
        <BackNavigation />
        <ForgotPassFormText />
        <ForgotPassForm />
      </TngGrid>
    </TngGrid>
  );
};

export default ForgotPassword;
