import React from 'react';

import { Typography } from '@mui/material';

import Translations from '../../../../../assets/locale/en/content.json';
import { Country } from '../../../../../common/interfaces/Country';
import { TngGrid } from '../../../../../components/common';
import TngMultiSelectDropdown from '../../../../../components/common/TngMultiSelectDropdown';
import { ManageUserFilter } from '../../modals/ManageUserFilter';

interface CountryDropdownProps {
  filterObj: ManageUserFilter;
  setFiltersObj: React.Dispatch<React.SetStateAction<ManageUserFilter>>;
  country: Country[];
}

const CountryDropdown: React.FC<CountryDropdownProps> = ({ filterObj, setFiltersObj, country }) => {
  // filtered out data/options according to selected user type
  const filterOptions = () => {
    return country.map((cus: Country) => {
      return { name: cus.name, id: cus.code };
    });
  };
  return (
    <TngGrid container marginTop="20px">
      <TngGrid item xs={6} className="order_status_text">
        <Typography variant="h5">{Translations.filterSection.countryMin3Char}</Typography>
      </TngGrid>
      <TngGrid item xs={6}>
        <Typography
          style={{ textAlign: 'right' }}
          variant="body1"
        >{`${filterObj.country.length} Selected`}</Typography>
      </TngGrid>
      <TngGrid item xs={12} className="user_dropdown">
        <TngMultiSelectDropdown
          options={filterOptions()}
          setOption={(newSelectedCountries) => {
            setFiltersObj({
              ...filterObj,
              country: country
                .filter((cus) => newSelectedCountries.includes(cus.code))
                .map((cus) => cus.code),
            });
          }}
          selectedOptions={filterObj.country}
          placeholderText={Translations.filterSection.typeCountryNamePlaceholder}
        />
      </TngGrid>
    </TngGrid>
  );
};

export default CountryDropdown;
