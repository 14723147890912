import { useEffect } from 'react';

import TrainingCertificationFilter from './Filter';
import { GridActionEnum } from '../../../common/enums/GridActionEnum';
import { useGridDispatch, useGridState } from '../../../common/GridContext/GridContextProvider';
import { TrainingCertificationGridUrl } from '../../../common/interfaces/urls/TrainingCertificationGridUrl';
import { TngGrid } from '../../../components/common';
import TngAgGrid from '../../../components/TngGridSection/TngAgGrid/TngAgGrid';
import { defaultAPIParams } from '../gridHelper/apiParams';
import { getGridConfig } from '../gridHelper/gridConfig';
import { mapTrainingCertificatesToTrainingCertificateGridModal } from '../gridHelper/mapperFunctions';

const TrainingCertificationGrid = () => {
  const { gridData, search, pageNumber, pageSize, filters, loading } = useGridState();
  const dispatch = useGridDispatch();

  // check if gridData is present than called the mapper to map the data according to grid columns
  useEffect(() => {
    if (gridData && gridData.length > 0) {
      const mappedData = mapTrainingCertificatesToTrainingCertificateGridModal(gridData);
      dispatch({ type: GridActionEnum.SET_GRID_MAPPED_DATA, payload: mappedData });
      setTimeout(() => {
        dispatch({ type: GridActionEnum.SET_LOADER, payload: false });
      }, 1000);
    } else {
      if (!loading) {
        dispatch({ type: GridActionEnum.SET_GRID_MAPPED_DATA, payload: [] });
      }
    }
  }, [dispatch, gridData, loading]);

  const filterCount = (): number => {
    if (filters?.contactIds && filters.contactIds.length >= 0) {
      return filters.contactIds.length;
    }
    return 0;
  };

  // create or get default api params
  const apiParams: TrainingCertificationGridUrl = defaultAPIParams({
    pageNumber,
    pageSize,
    search,
    filters,
  });

  return (
    <TngGrid className="training_certification_grid_container">
      <TngAgGrid
        gridConfig={getGridConfig(apiParams)}
        filterComponent={<TrainingCertificationFilter />}
        filterCount={filterCount()}
      />
    </TngGrid>
  );
};

export default TrainingCertificationGrid;
