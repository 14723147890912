import React, { ReactNode } from 'react';

import Button, { ButtonProps } from '@mui/material/Button';

interface TngButtonProps extends ButtonProps {
  children: ReactNode | string;
}

export const TngButton: React.FC<TngButtonProps> = ({ children, ...props }) => {
  return <Button {...props}>{children}</Button>;
};
