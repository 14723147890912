import React from 'react';

const HighSpotIcon = () => {
  return (
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M21 1H1V21H21V1Z" stroke="#00A0FB" strokeMiterlimit="10" strokeLinecap="square" />
      <path d="M4 12H9.5" stroke="#00A0FB" strokeMiterlimit="10" strokeLinecap="square" />
      <path d="M12.5 12H18" stroke="#00A0FB" strokeMiterlimit="10" strokeLinecap="square" />
      <path d="M4 18H9.5" stroke="#00A0FB" strokeMiterlimit="10" strokeLinecap="square" />
      <path d="M12.5 18H18" stroke="#00A0FB" strokeMiterlimit="10" strokeLinecap="square" />
      <path d="M4 15H9.5" stroke="#00A0FB" strokeMiterlimit="10" strokeLinecap="square" />
      <path d="M12.5 15H18" stroke="#00A0FB" strokeMiterlimit="10" strokeLinecap="square" />
      <path d="M5 5H9" stroke="#00A0FB" strokeMiterlimit="10" strokeLinecap="square" />
      <path d="M13 5H17" stroke="#00A0FB" strokeMiterlimit="10" strokeLinecap="square" />
      <path d="M1 9H21" stroke="#00A0FB" strokeMiterlimit="10" strokeLinecap="square" />
    </svg>
  );
};

export default HighSpotIcon;
