import React from 'react';

const ManageUsersIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.5 6.5C3.3955 6.5 2.5 5.6045 2.5 4.5C2.5 3.3955 3.3955 2.5 4.5 2.5C5.6045 2.5 6.5 3.3955 6.5 4.5C6.5 5.6045 5.6045 6.5 4.5 6.5Z"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <path
        d="M6 21.5H3L2.5 16L1 15.5V11C1 9.8955 1.8955 9 3 9H6C6.727 9 7.3635 9.3885 7.7135 9.9685"
        strokeMiterlimit="10"
      />
      <path
        d="M19.5 6.5C20.6045 6.5 21.5 5.6045 21.5 4.5C21.5 3.3955 20.6045 2.5 19.5 2.5C18.3955 2.5 17.5 3.3955 17.5 4.5C17.5 5.6045 18.3955 6.5 19.5 6.5Z"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <path
        d="M18.0006 21.5H21.0006L21.5006 16L23.0006 15.5V11C23.0006 9.8955 22.1051 9 21.0006 9H18.0006C17.2736 9 16.6371 9.3885 16.2871 9.9685"
        strokeMiterlimit="10"
      />
      <path
        d="M12 7C10.343 7 9 5.657 9 4C9 2.343 10.343 1 12 1C13.657 1 15 2.343 15 4C15 5.657 13.657 7 12 7Z"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <path
        d="M14.5 23H9.5L9 17L7 16.5V11.5C7 10.3955 7.8955 9.5 9 9.5H15C16.1045 9.5 17 10.3955 17 11.5V16.5L15 17L14.5 23Z"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
    </svg>
  );
};

export default ManageUsersIcon;
