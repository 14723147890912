import {
  MappedPermissions,
  PermissionFeature,
  PermissionItem,
  PermissionType,
} from '../interfaces/userAdmin/Permissions';

export const mapPermissions = (permissions: PermissionItem[]): MappedPermissions => {
  return permissions.reduce<MappedPermissions>((acc, curr) => {
    const { feature } = curr;

    // Find last hyphen in feature to split into parentKey and childKey
    const lastIndex = feature.lastIndexOf('-');
    const parentKey = (
      lastIndex !== -1 ? feature.slice(0, lastIndex) : feature
    ) as PermissionFeature; // Everything before last '-'
    const childKey = (lastIndex !== -1 ? feature.slice(lastIndex + 1) : feature) as PermissionType; // Everything after last '-'

    // Initialize parent key if it doesn't exist
    if (!acc[parentKey]) {
      acc[parentKey] = {};
    }
    // Add permission key as true under the child key
    (acc[parentKey] as Record<PermissionType, boolean>)[childKey] = true;
    return acc;
  }, {});
};
