import { UserProfile, UserProfileAPIResponse } from '../../common/interfaces/user/UserProfile';

/**
Mapped the profile data fetched using api to actual data rendered on UI
@parameter {data} - user profile data fetched using api
@returns CountryOptions[] - array of Country Options
*/

export const userProfileMapper = (data: UserProfileAPIResponse): UserProfile => {
  const {
    accountId,
    contactId,
    firstName,
    lastName,
    jobTitle,
    phoneNumber,
    emailAddress,
    address: { countryCode, stateCode, street, city, postalCode },
  } = data;

  const defaultData: UserProfile = {
    accountId: accountId,
    contactId: contactId,
    firstName: firstName,
    lastName: lastName,
    jobTitle: jobTitle,
    emailAddress: emailAddress,
    phoneNumber: phoneNumber,
    country: countryCode,
    state: stateCode,
    street: street,
    city: city,
    postalCode: postalCode,
  };

  return defaultData;
};
