import { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { logoutUserService } from '../../api/services/auth/authService';
import { RootState } from '../../store';
import { logout } from '../../store/auth/authSlice';

const useTokenListener = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { idToken, token, refreshToken } = useSelector((state: RootState) => state.auth);

  useEffect(() => {
    const handleStorageChange = async () => {
      // Retrieve and parse the tokens object from localStorage
      const storedTokens = JSON.parse(localStorage.getItem('tokens') || '{}');

      const storedIdToken = storedTokens?.idToken;
      const storedAccessToken = storedTokens?.token;
      const storedRefreshToken = storedTokens?.refreshToken;

      // Logout if tokens are changed or removed from localStorage
      if (
        storedIdToken !== idToken ||
        storedAccessToken !== token ||
        storedRefreshToken !== refreshToken
      ) {
        idToken && (await logoutUserService(idToken));
        dispatch(logout());
        navigate('/'); // Redirect to login
      }
    };

    // Listen for storage changes across all tabs/windows
    window.addEventListener('storage', handleStorageChange);

    // Clean up event listener on component unmount
    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, [dispatch, navigate, idToken, token]);
};

export default useTokenListener;
