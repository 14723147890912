import React from 'react';

import TngInfoCardDetails from './components/TngInfoCardDetails';
import TngInfoCardHeader, { HeaderDetails } from './components/TngInfoCardHeader';
import { ColorStatus } from '../../common/interfaces/order/ColorStatus';
import { SearchDropdown } from '../../common/interfaces/SearchDropdown';
import { TngGrid, TngDivider } from '../common';

export interface TngInfoCardProps {
  fieldConfig: any;
  headerDetails?: HeaderDetails;
  statusColors?: ColorStatus[];
  isHeaderDetailsVisible?: boolean;
  dropdownOptions?: SearchDropdown[];
  fieldDetailLabel: string;
}

const TngInfoCard: React.FC<TngInfoCardProps> = ({
  fieldConfig,
  headerDetails,
  statusColors,
  isHeaderDetailsVisible = true,
  dropdownOptions,
  fieldDetailLabel,
}) => {
  return (
    <TngGrid className="tnginfo_fields_container">
      {isHeaderDetailsVisible && headerDetails && statusColors && (
        <TngInfoCardHeader
          headerDetails={headerDetails}
          statusColors={statusColors}
          dropdownOptions={dropdownOptions || []}
        />
      )}
      <TngDivider className="fields_container_divider" />
      <TngInfoCardDetails fieldConfig={fieldConfig} fieldDetailLabel={fieldDetailLabel} />
    </TngGrid>
  );
};

export default TngInfoCard;
