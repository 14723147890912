import React, { useEffect } from 'react';

import OrderHistoryGrid from './components/OrderHistoryGrid';
import Translations from '../../assets/locale/en/content.json';
import { GridContextProvider } from '../../common/GridContext/GridContextProvider';
import { TngGrid, TngTypography } from '../../components/common';
import { focusFirstInteractiveElement } from '../../utils/findInteractiveElement';

const OrderHistory = () => {
  useEffect(() => {
    const mainDiv = document.getElementById('main-content');
    focusFirstInteractiveElement(mainDiv);
  }, []);

  return (
    <GridContextProvider>
      <TngGrid className="order_history">
        <TngGrid className="order_history_header">
          <TngTypography variant="h2" tabIndex={0}>
            {Translations.orderHistory.orderHistoryHeading}
          </TngTypography>
        </TngGrid>
        <OrderHistoryGrid />
      </TngGrid>
    </GridContextProvider>
  );
};

export default OrderHistory;
