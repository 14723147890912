// These are pagination helper methods to which perform task on each button clicks
import { PaginationBtnAttributes } from '../modal/PaginationBtnAttributes';

// increament buttons value by 1
export const incrementButtonValue = (
  buttonValue: PaginationBtnAttributes[],
  setButtonValue: React.Dispatch<React.SetStateAction<PaginationBtnAttributes[]>>,
) => {
  const updatedButtonValue = buttonValue.map((button) => ({
    ...button,
    value: button.value + 1,
  }));
  setButtonValue(updatedButtonValue);
};

// decrement buttons value by 1
export const decrementButtonValue = (
  buttonValue: PaginationBtnAttributes[],
  setButtonValue: React.Dispatch<React.SetStateAction<PaginationBtnAttributes[]>>,
) => {
  const updatedButtonValue = buttonValue.map((button) => ({
    ...button,
    value: button.value - 1,
  }));
  setButtonValue(updatedButtonValue);
};

// handle when use click on next btn
export const handleNextClick = (
  pageNumber: number,
  buttonValue: PaginationBtnAttributes[],
  setButtonValue: React.Dispatch<React.SetStateAction<PaginationBtnAttributes[]>>,
  goToPage: (page: number) => void,
) => {
  const newPage = pageNumber + 1;
  goToPage(newPage);

  if (pageNumber === buttonValue[buttonValue.length - 1].value) {
    incrementButtonValue(buttonValue, setButtonValue);
  }
};

// handle when use click on prev btn
export const handlePrevClick = (
  pageNumber: number,
  buttonValue: PaginationBtnAttributes[],
  setButtonValue: React.Dispatch<React.SetStateAction<PaginationBtnAttributes[]>>,
  goToPage: (page: number) => void,
) => {
  const newPage = pageNumber - 1;
  goToPage(newPage);

  if (pageNumber === buttonValue[0].value) {
    decrementButtonValue(buttonValue, setButtonValue);
  }
};

// handle when use click on last btn
export const handleLastClick = (
  totalPages: number,
  buttonValue: PaginationBtnAttributes[],
  setButtonValue: React.Dispatch<React.SetStateAction<PaginationBtnAttributes[]>>,
  goToPage: (page: number) => void,
) => {
  goToPage(totalPages);
  if (totalPages > buttonValue.length) {
    const updatedButtonValue = buttonValue.map((button, index) => ({
      ...button,
      value: totalPages - buttonValue.length + index + 1,
    }));
    setButtonValue(updatedButtonValue);
  }
};

// handle when use click on first btn
export const handleFirstClick = (
  buttonValue: PaginationBtnAttributes[],
  setButtonValue: React.Dispatch<React.SetStateAction<PaginationBtnAttributes[]>>,
  goToPage: (page: number) => void,
) => {
  goToPage(1);

  const updatedButtonValue = buttonValue.map((button, index) => ({
    ...button,
    value: index + 1,
  }));

  setButtonValue(updatedButtonValue);
};
