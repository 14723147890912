import React from 'react';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { SelectChangeEvent } from '@mui/material';

import Translations from '../../../../../assets/locale/en/content.json';
import { SearchDropdown } from '../../../../../common/interfaces/SearchDropdown';
import { TngMenuItem, TngSelect } from '../../../../common';

interface TngTngSearchDropdownProps {
  dropdownOptions: SearchDropdown[];
  searchBy: string;
  setSearchBy: React.Dispatch<React.SetStateAction<string>>;
}

const TngSearchDropdown: React.FC<TngTngSearchDropdownProps> = ({
  dropdownOptions,
  searchBy,
  setSearchBy,
}) => {
  const handleChange = (event: SelectChangeEvent<string | number>) => {
    const searchBy = event.target.value as string;
    // set value of searchBy to state
    setSearchBy(searchBy);
  };

  return (
    <>
      <TngSelect
        value={searchBy}
        onChange={handleChange}
        IconComponent={KeyboardArrowDownIcon}
        className="grid_search_dropdown"
        inputProps={{
          className: 'search_dropdown_box',
          'data-testid': 'grid_search_dropdown',
          'aria-label': `${Translations.TngGrid.searchDropdownAriaLabel}`,
        }}
        MenuProps={{
          classes: {
            paper: 'search_dropdown_menu_paper',
            list: 'search_dropdown_menu',
          },
          MenuListProps: {
            role: 'listbox',
            'aria-label': `${Translations.TngGrid.searchDropdownAriaLabelExpanded}`,
          },
        }}
      >
        {dropdownOptions.map((option: SearchDropdown) => (
          <TngMenuItem className="search_dropdown_items" key={option.label} value={option.label}>
            {option.value}
          </TngMenuItem>
        ))}
      </TngSelect>
    </>
  );
};

export default TngSearchDropdown;
