import React, { ReactNode } from 'react';

import { DrawerProps } from '@mui/material/Drawer';
import { styled, Theme, CSSObject } from '@mui/material/styles';

import { openedMixin, closedMixin } from './MixinFunctions';
import { TngDrawer } from '../common/TngDrawer';

interface ReactDrawerProps extends DrawerProps {
  top: string;
  width: string;
  children: ReactNode | ReactNode[];
  open: boolean;
  toggleDrawer: () => void;
  responsiveWidthMixin: (theme: Theme) => CSSObject;
}

const Drawer: React.FC<ReactDrawerProps> = ({
  top,
  width,
  open,
  toggleDrawer,
  responsiveWidthMixin,
  children,
  ...props
}) => {
  const StyledDrawer = styled(TngDrawer, {
    shouldForwardProp: (prop) => prop !== 'open',
  })(({ theme }) => ({
    width: width,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme, width, top),
      ...responsiveWidthMixin(theme),
      '& .MuiDrawer-paper': { ...openedMixin(theme, width, top) },
    }),
    ...(!open && {
      ...closedMixin(theme, top),
      '& .MuiDrawer-paper': closedMixin(theme, top),
    }),
  }));

  return (
    <StyledDrawer open={open} toggleDrawer={toggleDrawer} {...props}>
      {children}
    </StyledDrawer>
  );
};

export default Drawer;
