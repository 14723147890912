import React from 'react';

import ExistingOpportunityGrid from './ExistingOpportunityGrid';
import Translations from '../../../../../assets/locale/en/content.json';
import { GridContextProvider } from '../../../../../common/GridContext/GridContextProvider';
import { TngGrid, TngTypography } from '../../../../../components/common';

const ExistingOpportunity = () => {
  return (
    <TngGrid className="existing_opportunity_container">
      <TngTypography
        className="existing_opportunity_search_text"
        data-testid="existing_opportunity_search_text"
      >
        {Translations.quoteCreation.searchOpportunityText}
      </TngTypography>
      <GridContextProvider>
        <ExistingOpportunityGrid />
      </GridContextProvider>
    </TngGrid>
  );
};

export default ExistingOpportunity;
