import React from 'react';

interface SadIconProps {
  height: string;
  width: string;
}

const SadIcon: React.FC<SadIconProps> = ({ height, width }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 45 45"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-testid="sad_icon"
    >
      <path
        d="M12.5 22.1992C13.6046 22.1992 14.5 21.3038 14.5 20.1992C14.5 19.0946 13.6046 18.1992 12.5 18.1992C11.3954 18.1992 10.5 19.0946 10.5 20.1992C10.5 21.3038 11.3954 22.1992 12.5 22.1992Z"
        stroke="#002854"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <path
        d="M32.5 22.1992C33.6046 22.1992 34.5 21.3038 34.5 20.1992C34.5 19.0946 33.6046 18.1992 32.5 18.1992C31.3954 18.1992 30.5 19.0946 30.5 20.1992C30.5 21.3038 31.3954 22.1992 32.5 22.1992Z"
        stroke="#002854"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <path
        d="M22.5 43.1992C34.098 43.1992 43.5 33.7972 43.5 22.1992C43.5 10.6012 34.098 1.19922 22.5 1.19922C10.902 1.19922 1.5 10.6012 1.5 22.1992C1.5 33.7972 10.902 43.1992 22.5 43.1992Z"
        stroke="#002854"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <path
        d="M15.5703 34.1994C16.2722 32.9824 17.2822 31.9717 18.4988 31.269C19.7153 30.5663 21.0954 30.1963 22.5003 30.1963C23.9052 30.1963 25.2853 30.5663 26.5019 31.269C27.7184 31.9717 28.7284 32.9824 29.4303 34.1994"
        stroke="#002854"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <path
        d="M12.5 22.1992C13.6046 22.1992 14.5 21.3038 14.5 20.1992C14.5 19.0946 13.6046 18.1992 12.5 18.1992C11.3954 18.1992 10.5 19.0946 10.5 20.1992C10.5 21.3038 11.3954 22.1992 12.5 22.1992Z"
        fill="#002854"
      />
      <path
        d="M32.5 22.1992C33.6046 22.1992 34.5 21.3038 34.5 20.1992C34.5 19.0946 33.6046 18.1992 32.5 18.1992C31.3954 18.1992 30.5 19.0946 30.5 20.1992C30.5 21.3038 31.3954 22.1992 32.5 22.1992Z"
        fill="#002854"
      />
    </svg>
  );
};

export default SadIcon;
