import React, { ReactNode } from 'react';

import { GridProps } from '@mui/material/Grid/Grid';

import { TngGrid } from '.';

interface TngContainerPropsWithGrid extends GridProps {
  leftWidth: number;
  rightWidth: number;
  children: ReactNode[];
}

export const TngContainer: React.FC<TngContainerPropsWithGrid> = ({
  leftWidth,
  rightWidth,
  children,
  ...gridProps
}) => {
  return (
    <TngGrid container {...gridProps}>
      <TngGrid item xs={leftWidth} className="container_first_child">
        {children[0]} {/* Render the first child component */}
      </TngGrid>
      <TngGrid item xs={rightWidth} className="container_second_child">
        {children[1]} {/* Render the second child component */}
      </TngGrid>
    </TngGrid>
  );
};
