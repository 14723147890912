import React from 'react';

interface TngImageProps {
  src: string;
  alt: string;
  [key: string]: any; // Allow for additional props
}

export const TngImage: React.FC<TngImageProps> = ({ src, alt, ...props }) => {
  if (!src || !alt) {
    return null;
  }
  return <img src={src} alt={alt} {...props} />;
};
