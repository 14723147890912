import dayjs from 'dayjs';

export const TransformDate = (dateString: string | undefined): string => {
  let formattedDate = '';
  const placeholderDataTime: string = '0001-01-01T00:00:00';
  if (dateString && dateString !== placeholderDataTime) {
    const date = dayjs(dateString);
    if (date.isValid()) {
      // Check if the date is valid
      formattedDate = date.format('YYYY-MM-DD');
    }
  }
  return formattedDate;
};
