import React, { createContext, useContext, ReactNode, useReducer, Dispatch } from 'react';

import { ContextReducers } from './ContextReducers';
import { ContextActions } from '../modals/contextAction';
import { ContextState } from '../modals/contextState';

export interface OrderSummaryContextType {
  state: ContextState;
  dispatch: Dispatch<ContextActions>; // Adjust 'any' as per your specific action types
}

// Initial state factory function to support multiple configurations
export const initialState = (): ContextState => ({
  financialDetailFields: null,
  orderId: '',
  isFinancialTabVisible: false,
});

interface OrderSummaryProviderProps {
  children: ReactNode | ReactNode[];
}

// Creating context with an undefined default value
export const DataContext = createContext<OrderSummaryContextType | undefined>(undefined);

export const OrderSummaryContextProvider: React.FC<OrderSummaryProviderProps> = ({ children }) => {
  // State to manage the grid context
  const [state, dispatch] = useReducer(ContextReducers, initialState());

  return <DataContext.Provider value={{ state, dispatch }}>{children}</DataContext.Provider>;
};

// method to get state
export const useOrderSummaryState = () => {
  const context = useContext(DataContext);
  if (!context) {
    throw new Error('useGridState must be used within a GridProvider');
  }
  return context.state;
};

// method to get dispatch dunction
export const useOrderSummaryDispatch = () => {
  const context = useContext(DataContext);
  if (!context) {
    throw new Error('useGridDispatch must be used within a GridProvider');
  }
  return context.dispatch;
};
