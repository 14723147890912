import React, { forwardRef } from 'react';

import Link, { LinkProps } from '@mui/material/Link';

// Use forwardRef to allow refs to be passed down to the Link component
export const TngLink = forwardRef<HTMLAnchorElement, LinkProps>((props, ref) => {
  return <Link {...props} ref={ref} />;
});

// Add a displayName for better debugging
TngLink.displayName = 'TngLink';
