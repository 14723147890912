import React, { useState } from 'react';

import Translations from '../../../assets/locale/en/content.json';
import { GridActionEnum } from '../../../common/enums/GridActionEnum';
import { useGridDispatch, useGridState } from '../../../common/GridContext/GridContextProvider';
import { Contact } from '../../../common/interfaces/contacts/Contacts';
import { TngApplyFilterBtn, TngGrid, TngTypography } from '../../../components/common';
import TngMultiSelectDropdown from '../../../components/common/TngMultiSelectDropdown';
import { useContacts } from '../hooks/useTrainingCertification';
import { TrainingCertificateFilter } from '../modal/TrainingCertificateFilter';
import { TrainingCertificationFilterContext } from '../modal/TrainingCertificateFilterContext';
import {
  checkIfFiltersChanged,
  initializeFilterState,
  isAnyFilterSelected,
} from '../utils/filterHelper';

const TrainingCertificationFilter = () => {
  const dispatch = useGridDispatch();
  const { filters }: { filters: TrainingCertificationFilterContext } = useGridState();

  const [filterObj, setFiltersObj] = useState<TrainingCertificateFilter>(
    initializeFilterState(filters),
  );

  const defaultFilterState: TrainingCertificateFilter = initializeFilterState(filters);

  const { data: contacts = [] } = useContacts();

  const hasFilters = checkIfFiltersChanged(filterObj, defaultFilterState);

  const filterOptions = () => {
    return contacts.map((cus: Contact) => {
      return { name: cus.name, id: cus.id };
    });
  };

  const applyFilter = () => {
    dispatch({ type: GridActionEnum.SET_FILTERS, payload: { ...filterObj } });
    dispatch({ type: GridActionEnum.SET_IS_FILTER_OPEN, payload: false });
    if (isAnyFilterSelected(filterObj)) {
      dispatch({ type: GridActionEnum.SET_IS_FILTER_APPLIED, payload: true });
    } else {
      dispatch({ type: GridActionEnum.SET_IS_FILTER_APPLIED, payload: false });
    }
  };

  return (
    <TngGrid className="training_certi_filter filter_container" marginTop="20px">
      <TngGrid item xs={12} className="user_dropdown">
        <TngGrid className="order_status_text">
          <TngTypography variant="h5">{Translations.filterSection.contactMin3Char}</TngTypography>
          <TngTypography
            style={{ textAlign: 'right', fontWeight: '500', color: '#002854' }}
            variant="body1"
          >{`${filterObj.contactIds.length} selected`}</TngTypography>
        </TngGrid>
        <TngMultiSelectDropdown
          options={filterOptions()}
          setOption={(newSelectedContacts) => {
            setFiltersObj({
              ...filterObj,
              contactIds: contacts
                .filter((cus) => newSelectedContacts.includes(cus.id))
                .map((cus) => cus.id),
            });
          }}
          selectedOptions={filterObj.contactIds}
          placeholderText={Translations.filterSection.contactNamePlaceholder}
        />
      </TngGrid>
      <TngApplyFilterBtn hasFilters={hasFilters} applyFilter={applyFilter} />
    </TngGrid>
  );
};

export default TrainingCertificationFilter;
