import { StatusColors, StatusWithColor } from '../common/interfaces/StatusColor';

export const CreateStatusColorObj = (statuses: StatusWithColor[]): StatusColors => {
  const colors: StatusColors = {} as StatusColors;
  statuses.forEach((status) => {
    // Ensure the item.Name matches a valid enum value
    if (status.name) {
      colors[status.name] = status.colorCode;
    }
  });

  return colors;
};
