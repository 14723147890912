import React from 'react';

import FinancialDetailFields from './FinancialDetailFields';
import { GridContextProvider } from '../../../../common/GridContext/GridContextProvider';

const FinancialDetails = () => {
  return (
    <GridContextProvider>
      <FinancialDetailFields />
    </GridContextProvider>
  );
};

export default FinancialDetails;
