import React from 'react';

import { TngGrid, TngTypography } from '../common';

interface CustomToastProps {
  message: string;
}

const CustomToast: React.FC<CustomToastProps> = ({ message }) => {
  return (
    <TngGrid className="custom-toast">
      <TngTypography className="toast_message">{message}</TngTypography>
    </TngGrid>
  );
};

export default CustomToast;
