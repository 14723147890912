import React, { useEffect, useState } from 'react';

import Translations from '../../../assets/locale/en/content.json';
import { TngGrid } from '../../../components/common';
import TngBottomPanel from '../../../components/common/TngBottomPanel';
import CreateQuoteStepOne from '../components/CreateQuoteStepOne';
import { useQuoteCreationDispatch, useQuoteCreationState } from '../context/ContextProvider';
import { ContextActionEnum } from '../enums/ContextActionEnums';

const Elements: any = {
  0: <CreateQuoteStepOne />,
  1: <div>Element 2: This is the second element!</div>,
};

const CreateQuoteWorkflow = () => {
  const [configStep, setConfigStep] = useState<number>(0);
  const quoteDispatch = useQuoteCreationDispatch();
  const quoteState = useQuoteCreationState();

  useEffect(() => {
    switch (configStep) {
      case 0:
        quoteDispatch({
          type: ContextActionEnum.SET_PREV_BUTTON_PROPS,
          payload: { value: Translations.quoteCreation.closeButtonText },
        });
        quoteDispatch({
          type: ContextActionEnum.SET_NEXT_BUTTON_PROPS,
          payload: {
            value: Translations.quoteCreation.quoteCreationFormText,
            disabled: quoteState.existingOpportunity.selectedOpportunity ? false : true,
            className: quoteState.existingOpportunity.selectedOpportunity ? '' : 'btn_disabled',
          },
        });
        break;
      case 1:
        quoteDispatch({
          type: ContextActionEnum.SET_PREV_BUTTON_PROPS,
          payload: { className: 'btn_hidden' },
        });
        quoteDispatch({
          type: ContextActionEnum.SET_NEXT_BUTTON_PROPS,
          payload: {
            value: 'Next',
            disabled: true,
            className: quoteState.existingOpportunity.selectedOpportunity ? '' : 'btn_disabled',
          },
        });
        break;

      default:
        quoteDispatch({
          type: ContextActionEnum.SET_PREV_BUTTON_PROPS,
          payload: { value: '' },
        });
        break;
    }
  }, [configStep, quoteState.existingOpportunity]); // Trigger whenever configStep changes
  return (
    <TngGrid className="quote_creation_container">
      <TngGrid className="top_panel_container">{Elements[configStep]}</TngGrid>
      <TngGrid className="bottom_panel_container">
        <TngBottomPanel
          prevButtonProps={quoteState.prevButtonProps}
          nextButtonProps={quoteState.nextButtonProps}
          setConfigStep={setConfigStep} // Pass setConfigStep to the Bottom Panel
          currentStep={configStep} // Also pass current step if needed
          navigateToRoute={Translations.navigation.manageQuotes}
        />
      </TngGrid>
    </TngGrid>
  );
};

export default CreateQuoteWorkflow;
