import React, { forwardRef } from 'react';

import Typography, { TypographyProps } from '@mui/material/Typography';

interface TngTypographyProps extends TypographyProps {}

export const TngTypography = forwardRef<HTMLSpanElement, TngTypographyProps>(
  ({ children, ...typographyProps }, ref) => {
    return (
      <Typography ref={ref} {...typographyProps}>
        {children}
      </Typography>
    );
  },
);

// Set the display name for better debugging and to satisfy ESLint
TngTypography.displayName = 'TngTypography';
