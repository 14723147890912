import { State } from '../interfaces/State';
import { StateOptions } from '../interfaces/StateOptions';
/**

Mapped the state data which we get from api to state dropdown options
@parameter {states} - List of State.
@returns StateOptions[] - array of State Options
*/

export const stateDropdownMapper = (
  states: State[],
  selectedCountry: number | null,
): StateOptions[] => {
  // Filter states based on selectedCountry if it is not null
  const filteredStates =
    selectedCountry !== null
      ? states.filter((state) => state.countryId === selectedCountry)
      : states;

  // Map the filtered or whole states to the StateOptions format
  return filteredStates.map((state) => ({
    id: state.id,
    label: state.name,
    value: state.code,
    countryId: state.countryId,
  }));
};
