import { Card, CardContent } from '@mui/material';

import { TngGrid, TngTypography } from '../../../components/common';
import { getClassNameForValue } from '../utils/GetClassNameForValues';

const DashboardCard = ({ status, count }: { status: string; count: number }) => {
  const cardClass = getClassNameForValue(status);
  return (
    <Card className={cardClass}>
      <CardContent>
        <TngGrid item className="dashboard_cardcontentlabel_grid">
          <TngTypography>{status}</TngTypography>
        </TngGrid>
        <TngGrid item className="dashboard_cardcontentvalue_grid">
          <TngTypography>{count}</TngTypography>
        </TngGrid>
      </CardContent>
    </Card>
  );
};

export default DashboardCard;
