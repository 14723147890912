import React from 'react';

import Translations from '../../../../assets/locale/en/content.json';
import { TngGrid, TngToggleSection, TngTypography } from '../../../../components/common';

const GlobalPartnerProgram = () => {
  return (
    <TngGrid container className="global_partner_program_guide_container">
      <TngToggleSection
        title={Translations.PartnerProgram.globalPartnerProgramGuide}
        content={
          <>
            <TngTypography variant="body1">
              <strong>{Translations.PartnerProgram.globalPartnerProgramGuide}</strong> -{' '}
              {Translations.PartnerProgram.globalPartnerProgramGuideContent}
            </TngTypography>
            <TngTypography variant="body1">
              <strong>{Translations.PartnerProgram.globalPartnerProgramGuideChangeLog}</strong> -{' '}
              {Translations.PartnerProgram.globalPartnerProgramGuideChangeLogContent}
            </TngTypography>
          </>
        }
      />
    </TngGrid>
  );
};

export default GlobalPartnerProgram;
