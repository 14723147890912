import React from 'react';

import Translations from '../../../assets/locale/en/content.json';
import { TngButton, TngGrid, TngTypography } from '../../../components/common';

type VerificationCodeSectionProps = {
  timer: number;
  resendEnabled: boolean;
  handleResendCode: () => void;
};

const VerificationCodeSection: React.FC<VerificationCodeSectionProps> = ({
  timer,
  resendEnabled,
  handleResendCode,
}) => (
  <TngGrid container margin="40px 0px">
    <TngGrid item className="reset_password_form_resend_code_button">
      {!resendEnabled && (
        <TngTypography variant="body1" marginRight="10px">
          {Translations.ResetPassword.resendCodeIn} {timer} {Translations.ResetPassword.seconds}
        </TngTypography>
      )}
      <TngButton
        variant="text"
        onClick={handleResendCode}
        disabled={!resendEnabled}
        className={`${!resendEnabled ? 'btn_disabled' : 'btn_enabled'}`}
      >
        {Translations.ResetPassword.resendCode}
      </TngButton>
    </TngGrid>
  </TngGrid>
);

export default VerificationCodeSection;
