import React, { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import DashboardCard from './components/DashboardCard';
import { useOrderStatusCount } from './hooks/useOrderStatusCount';
import { mapOrderStatus } from './utils/CustomMapping';
import DashboardImage from '../../assets/images/dashboard_img.svg';
import Translations from '../../assets/locale/en/content.json';
import { SSO_SALES_SUPPORT_URL } from '../../common/constant';
import { OrderStatusCount } from '../../common/interfaces/order/OrderStatusCount';
import { TngGrid, TngImage, TngLoader, TngTypography } from '../../components/common';
import { RootState } from '../../store';

const Dashboard = () => {
  const [orderStatusCount, setOrderStatusCount] = useState<(OrderStatusCount | undefined)[]>();
  const [totalCount, setTotalCount] = useState<number>(0);
  const userState = useSelector((state: RootState) => state.user.user);
  const userName = userState?.firstName;
  const { data: statusCount } = useOrderStatusCount();

  useEffect(() => {
    if (statusCount) {
      const mappedStatusData = mapOrderStatus(statusCount);
      setOrderStatusCount(mappedStatusData);
      setTotalCount(
        statusCount.reduce((total: number, curr: OrderStatusCount) => total + curr.recordCount, 0),
      );
    }
  }, [statusCount]);

  return (
    <TngGrid container className="dashboard_parent_container">
      <TngGrid container className="dashboard_child_container">
        <TngGrid item xs={12} className="dashboard_main_heading">
          <TngTypography>{`${Translations.dashboard.welcomeGreeting}, ${userName || ''}`}</TngTypography>
        </TngGrid>
        {orderStatusCount && orderStatusCount.length ? (
          <TngGrid container className="dashboard_card_main_grid">
            <TngGrid item xs={12} sm={12} md={9} className="dashboard_card_left_grid">
              <TngGrid container className="left_grid_container">
                {orderStatusCount.map(
                  (statusCount) =>
                    statusCount && (
                      <TngGrid
                        item
                        key={statusCount.id}
                        xs={12}
                        sm={12}
                        md={6}
                        className="left_card"
                      >
                        <DashboardCard
                          status={statusCount.orderStatus}
                          count={statusCount.recordCount}
                        />
                      </TngGrid>
                    ),
                )}
              </TngGrid>
            </TngGrid>
            <TngGrid item xs={12} sm={12} md={3} className="dashboard_right_card">
              <DashboardCard status="Total Orders" count={totalCount} />
            </TngGrid>
          </TngGrid>
        ) : (
          <TngLoader />
        )}
        <TngGrid container className="dashboard_feature_grid">
          <TngGrid item xs={12} sm={12} md={3} className="feature_img">
            <TngImage src={DashboardImage} alt="Dashboard Image" />
          </TngGrid>
          <TngGrid item xs={12} sm={12} md={9} className="feature_details">
            <TngTypography className="details_header">
              {Translations.dashboard.excitingFeaturesHeader}
            </TngTypography>
            <TngTypography>
              {Translations.dashboard.feedbackContent}{' '}
              {
                <Link
                  className="feature_link"
                  aria-label={Translations.ariaLabels.salesSupportLink}
                  target="_blank"
                  to={process.env.REACT_APP_SSO_URL! + SSO_SALES_SUPPORT_URL}
                >
                  {Translations.dashboard.feedbackLink}
                </Link>
              }
              {Translations.dashboard.feedbackContentNextLine}
            </TngTypography>
            <TngTypography>
              {Translations.dashboard.feedbackHelpContent}{' '}
              <span className="feedback_email">{Translations.dashboard.feedbackHelpEmail}</span>
            </TngTypography>
          </TngGrid>
        </TngGrid>
      </TngGrid>
    </TngGrid>
  );
};

export default Dashboard;
