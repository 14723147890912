import axiosInstance from '../../axiosInstance';
import { getUrl, URLS } from '../../urls';

/**
used to download the file.
@param {string} Id - Id of the file
@returns {Promise<Blob>} - A promise that resolves to file download.
@throws {Error} - Throws an error if the request fails.
*/
export const DownloadFile = async (Id: string): Promise<any> => {
  try {
    const url = getUrl(URLS.FILE_DOWNLOAD, { contentId: Id });

    const response = await axiosInstance.get<Blob>(url, {
      responseType: 'blob', //to get the response as a Blob
      timeout: 0,
    });

    // Access the 'Content-Disposition' header
    const contentDisposition = response.headers['content-disposition'];
    let fileName: string = '';
    if (contentDisposition) {
      // Extract filename from Content-Disposition header
      const fileNameMatch = contentDisposition.match(/filename="?([^"]+)"?/);
      if (fileNameMatch && fileNameMatch?.length > 1) {
        const encodedFileName = fileNameMatch[1];
        fileName = decodeURIComponent(encodedFileName); // Decodes URL-encoded special characters
      }
    }

    // create a response with fileName and data
    const responseData = {
      data: response.data,
      fileName,
    };
    return responseData;
  } catch (err: any) {
    throw new Error('Failed to download the file');
  }
};
