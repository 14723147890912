// this function is used to find 1st interactive element on page

export const focusFirstInteractiveElement = (element: Document | HTMLElement | null = document) => {
  const interactiveSelectors = [
    'a[href]', // Links with href attribute
    'button', // Button elements
    'input', // Input elements
    'select', // Select elements
    'textarea', // Textarea elements
    'details', // Details elements
    'summary', // Summary elements
    '[tabindex]', // Elements with tabindex attribute
    'a[role="button"]', // Links with button role
    '[role="button"]', // Elements with button role
  ];

  // Combine all selectors into a single query
  const query = interactiveSelectors.join(', ');

  // Find all interactive elements
  const elements = element?.querySelectorAll(query);

  if (elements && elements.length > 0) {
    // Focus on the first interactive element
    (elements[0] as HTMLElement).focus();
  }
};
