import { useEffect } from 'react';

import { useSearchParams } from 'react-router-dom';

import { Filter } from './Filter/Filter';
import { GridActionEnum } from '../../../common/enums/GridActionEnum';
import { useGridDispatch, useGridState } from '../../../common/GridContext/GridContextProvider';
import { StatusColors } from '../../../common/interfaces/StatusColor';
import { ManageQuotesGridUrl } from '../../../common/interfaces/urls/ManageQuotesGridUrl';
import { TngGrid } from '../../../components/common';
import TngAgGrid from '../../../components/TngGridSection/TngAgGrid/TngAgGrid';
import { ManageQuotesTabEnum } from '../enums/TabsEnum';
import { defaultManageQuotesAPIParams } from '../gridHelper/apiParam';
import { getManageUserGridConfig } from '../gridHelper/gridConfig';
import { mapQuotesToGridModal } from '../gridHelper/mapperFunction';

interface ManageQuotesGridProps {
  statusColors?: StatusColors;
}

// eslint-disable-next-line react/prop-types
const ManageQuotesGrid: React.FC<ManageQuotesGridProps> = ({ statusColors }) => {
  const { gridData, search, pageNumber, pageSize, sorting, filters } = useGridState();
  const dispatch = useGridDispatch();
  const [searchParams] = useSearchParams(); // Get the current query parameters

  // Check if `tab` parameter exists, default to 1 if not present
  const activeTab = searchParams.get('tab');

  useEffect(() => {
    if (gridData) {
      const mappedData = mapQuotesToGridModal(gridData);
      dispatch({ type: GridActionEnum.SET_GRID_MAPPED_DATA, payload: mappedData });
      dispatch({ type: GridActionEnum.SET_LOADER, payload: false });
    }
  }, [dispatch, gridData]);

  const filterCount = (): number => {
    let count = 0;
    if (filters?.opportunities && filters.opportunities.length >= 0) {
      count += filters.opportunities.length;
    }
    if (filters?.quoteStatus && filters.quoteStatus.length >= 0) {
      count += filters.quoteStatus.length;
    }
    return count;
  };

  // create or get default api params
  const apiParams: ManageQuotesGridUrl = defaultManageQuotesAPIParams({
    pageNumber,
    pageSize,
    search,
    sorting,
    filters,
  });

  return (
    <TngGrid className="manage_quote_grid">
      <TngAgGrid
        gridConfig={getManageUserGridConfig(
          apiParams,
          activeTab === ManageQuotesTabEnum.DRAFT_QUOTE,
          statusColors,
        )}
        filterComponent={<Filter isDraftFilter={activeTab === ManageQuotesTabEnum.DRAFT_QUOTE} />}
        filterCount={filterCount()}
      />
    </TngGrid>
  );
};

export default ManageQuotesGrid;
