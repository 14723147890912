import { State, StateAPIResponse } from '../../../common/interfaces/State';
import axiosInstance from '../../axiosInstance';
import { getUrl, URLS } from '../../urls';

/**
Fetches all the state from the API.
@returns {Promise<State[]>} - A promise that resolves to State.
@throws {Error} - Throws an error if the request fails.
*/
export const fetchStates = async (): Promise<State[]> => {
  try {
    const url = getUrl(URLS.STATE);

    const { data } = await axiosInstance.get<StateAPIResponse>(url);
    return data.data;
  } catch (err: any) {
    throw new Error('Failed to fetch state data');
  }
};
