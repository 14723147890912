import Translations from '../../../assets/locale/en/content.json';
import { ColumnDef } from '../../../common/interfaces/ColumnDef';
import { StatusColors } from '../../../common/interfaces/StatusColor';
import { CellWithBorder } from '../../../components/TngGridSection/common/components/CustomCellRender';

// this function is used to create column definations for manage quotes grid
export const getManageQuotesColumnHeaders = (
  isDraftQuote: boolean,
  statusColors?: StatusColors,
): ColumnDef[] => {
  const columns: ColumnDef[] = [
    {
      name: 'name',
      displayName: Translations.ManageQuotes.quoteId,
      colWidth: 180,
    },
    {
      name: 'customerName',
      displayName: Translations.ManageQuotes.customerName,
      colWidth: 220,
    },
    {
      name: 'quoteCreationDate',
      displayName: Translations.ManageQuotes.quoteCreationDate,
      colWidth: 200,
    },
    {
      name: 'createdBy',
      displayName: Translations.ManageQuotes.createdBy,
      colWidth: 180,
    },
    {
      name: 'opportunityName',
      displayName: Translations.ManageQuotes.opportunityName,
      colWidth: 300,
    },
    {
      name: 'primary',
      displayName: Translations.ManageQuotes.primary,
      colWidth: 120,
    },
  ];

  // Conditionally add the 'Status' column if it's not a draft quote
  if (!isDraftQuote) {
    columns.splice(1, 0, {
      name: 'status',
      displayName: Translations.ManageQuotes.status,
      customComponent: [{ name: CellWithBorder, props: { statusColor: statusColors } }],
      colWidth: 280,
    });
  }

  return columns;
};
