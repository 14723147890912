import * as React from 'react';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import { TngButton } from './TngButton';
import { TngGrid } from './TngGrid';
import { SearchDropdown } from '../../common/interfaces/SearchDropdown';

interface MenuButtonProps {
  menuItems: SearchDropdown[];
}

export const TngMenuButton: React.FC<MenuButtonProps> = ({ menuItems }) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [selectedItem, setSelectedItem] = React.useState(menuItems[0]?.value);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (item: string) => {
    setSelectedItem(item);
    handleClose();
  };

  return (
    <TngGrid className="menu_button_dropdown">
      <TngButton
        id="demo-customized-button"
        aria-controls={open ? 'demo-customized-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        disableElevation
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon />}
      >
        {selectedItem || 'Options'}
      </TngButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {menuItems.map((item, index) => (
          <MenuItem onClick={() => handleMenuItemClick(item.value)} key={index}>
            {item.value}
          </MenuItem>
        ))}
      </Menu>
    </TngGrid>
  );
};
