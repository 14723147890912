/**
 * useAriaLiveMessage Hook
 *
 * Manages ARIA live messages to provide accessibility feedback based on the count of items
 * in the grid. It updates the message when the grid data changes and clears it after 1 second,
 * enhancing the user experience for assistive technology users.
 */

import { useEffect } from 'react';

import Translations from '../../assets/locale/en/content.json';

type UseAriaLiveMessageProps = {
  gridData: any[] | null;
  loading?: boolean;
  setAriaLiveMessage: (value: React.SetStateAction<string | null>) => void;
};

const useAriaLiveMessage = ({
  gridData,
  loading = false,
  setAriaLiveMessage,
}: UseAriaLiveMessageProps) => {
  useEffect(() => {
    if (loading || !gridData?.length) return;

    const handle = requestAnimationFrame(() => {
      setAriaLiveMessage(`${gridData.length} ${Translations.ariaLabels.resultFound}`);
      setTimeout(() => setAriaLiveMessage(null), 1000);
    });

    return () => cancelAnimationFrame(handle);
  }, [gridData, loading, setAriaLiveMessage]);
};

export default useAriaLiveMessage;
