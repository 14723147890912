import Translations from '../../../../assets/locale/en/content.json';
import { CountryOptions } from '../../../../common/interfaces/CountryOptions';
import { StateOptions } from '../../../../common/interfaces/StateOptions';
import { UserTypeOptions } from '../../../../common/interfaces/user/UserTypes';
import { FieldConfig } from '../interfaces/ManageUsers';

/**
Create a field config for form to render fields on UI
@parameter {countryOptions} - List of options for country dropdown.
@parameter {stateOptions} - List of options for state dropdown.
@parameter {UserTypeOptions} - List of options for user types
@returns FieldConfig[] - return field config
*/

export const GetInputFieldConfig = (
  countryOptions: CountryOptions[],
  stateOptions: StateOptions[],
  userTypeOptions: UserTypeOptions[],
  selectedCountry: number | null,
): FieldConfig[] => {
  const country = countryOptions.filter(
    (country) => country.value === Translations.common.usCountry,
  );
  const fieldConfig: FieldConfig[] = [
    [
      {
        label: Translations.ManageUsers.UserFormField.firstName,
        htmlFor: 'firstName',
        fieldType: 'text',
        placeholder: Translations.ManageUsers.UserFormField.firstName,
        validationRules: { required: true, maxLength: 40 },
      },
      {
        label: Translations.ManageUsers.UserFormField.lastName,
        htmlFor: 'lastName',
        fieldType: 'text',
        placeholder: Translations.ManageUsers.UserFormField.lastName,
        validationRules: { required: true, maxLength: 80 },
      },
    ],
    [
      {
        label: Translations.ManageUsers.UserFormField.emailAddress,
        htmlFor: 'emailAddress',
        fieldType: 'text',
        placeholder: Translations.ManageUsers.UserFormField.emailAddress,
        validationRules: { required: true, readonly: true },
      },
    ],
    [
      {
        label: Translations.ManageUsers.UserFormField.jobTitle,
        htmlFor: 'jobTitle',
        fieldType: 'text',
        placeholder: Translations.ManageUsers.UserFormField.jobTitle,
        validationRules: { required: false, maxLength: 40 },
      },
    ],
    [
      {
        label: Translations.ManageUsers.UserFormField.userType,
        htmlFor: 'userType',
        fieldType: 'dropdown',
        placeholder: 'Select',
        validationRules: { required: true },
        options: userTypeOptions,
      },
    ],
    [
      {
        label: Translations.ManageUsers.UserFormField.country,
        htmlFor: 'country',
        fieldType: 'dropdown',
        placeholder: 'Select',
        validationRules: { required: true },
        options: countryOptions,
      },
      {
        label: Translations.ManageUsers.UserFormField['state/province'],
        htmlFor: 'state',
        fieldType: 'dropdown',
        placeholder: 'Select',
        validationRules: {
          required: country[0]?.id === selectedCountry,
          disabled: selectedCountry ? false : true,
        },
        options: stateOptions,
      },
    ],
    [
      {
        label: Translations.ManageUsers.UserFormField.street,
        htmlFor: 'street',
        fieldType: 'text',
        placeholder: Translations.ManageUsers.UserFormField.street,
        validationRules: { required: true, maxLength: 255 },
      },
    ],
    [
      {
        label: Translations.ManageUsers.UserFormField.city,
        htmlFor: 'city',
        fieldType: 'text',
        placeholder: Translations.ManageUsers.UserFormField.city,
        validationRules: { required: true, maxLength: 40 },
      },
      {
        label: Translations.ManageUsers.UserFormField['postal/zipcode'],
        htmlFor: 'postalCode',
        fieldType: 'text',
        placeholder: Translations.ManageUsers.UserFormField['postal/zipcode'],
        validationRules: { required: false, maxLength: 20 },
      },
    ],
    [
      {
        label: Translations.ManageUsers.UserFormField.phoneNumber,
        htmlFor: 'phoneNumber',
        fieldType: 'text',
        placeholder: Translations.ManageUsers.UserFormField.phoneNumber,
        validationRules: { required: true, maxLength: 40 },
      },
    ],
  ];
  return fieldConfig;
};
