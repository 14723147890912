import { ColDef } from 'ag-grid-community';

import { ColumnDef } from '../../../../common/interfaces/ColumnDef';
import { GridMoreOptionsConfig } from '../../../../common/interfaces/GridMoreOptionsConfig';
import { CustomTooltip, TextCellRenderer } from '../components/CustomCellRender';
import MoreOptionHeader from '../components/MoreOptionHeader';
import MoreOption from '../components/MoreOptions';

interface TransformColumnHeaderProps {
  ColumnHeader: ColumnDef[];
  moreOptions: GridMoreOptionsConfig;
  isSortingApplicable: boolean;
}

// transform the column header according to the ag-grid
export const TransformColumnHeader = ({
  ColumnHeader,
  moreOptions,
  isSortingApplicable,
}: TransformColumnHeaderProps) => {
  const transformedColumnHeader = ColumnHeader?.map((column) => {
    const colDef: ColDef = {
      headerName: column.displayName,
      field: column.name,
      cellRenderer: TextCellRenderer,
      sortable: column.sortable !== undefined ? column.sortable : isSortingApplicable,
      sortingOrder: ['desc', 'asc'],
      sort: column.sort,
      comparator: column.comparator,
    };
    //check for any custom Component to be rendered for a field
    column.customComponent?.map((cus) => {
      colDef.cellRenderer = cus.name;
      colDef.cellRendererParams = cus?.props;
    });

    // check if column specific width is given
    if (column?.colWidth) {
      colDef.minWidth = column.colWidth;
    }

    // check whether to show tooltip or not.
    if (column?.showTooltip) {
      colDef.tooltipField = column.name;
      colDef.tooltipComponent = CustomTooltip;
    }
    return colDef;
  });

  // Add the moreOptions column
  if (moreOptions.isApplicable) {
    const moreOptionsColumn: ColDef = {
      headerName: '',
      cellRenderer: MoreOption,
      cellRendererParams: { moreOptionNavigation: moreOptions.moreOptionNavigation },
      headerComponent: MoreOptionHeader,
      maxWidth: 64,
      sortable: false,
      resizable: false,
      cellAriaRole: 'more option',
    };

    return [...transformedColumnHeader, moreOptionsColumn];
  }
  return transformedColumnHeader;
};
