import React from 'react';

const CaseManagementIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_7641_6089)">
        <path d="M10.176 7.34791L5.76953 2.94141" stroke="#00A0FB" strokeMiterlimit="10" />
        <path d="M2.94141 5.76953L7.34841 10.176" stroke="#00A0FB" strokeMiterlimit="10" />
        <path d="M16.6523 10.176L21.0588 5.76953" stroke="#00A0FB" strokeMiterlimit="10" />
        <path d="M18.2288 2.94141L13.8223 7.34841" stroke="#00A0FB" strokeMiterlimit="10" />
        <path d="M13.8223 16.6523L18.2288 21.0588" stroke="#00A0FB" strokeMiterlimit="10" />
        <path d="M21.0574 18.2307L16.6504 13.8242" stroke="#00A0FB" strokeMiterlimit="10" />
        <path d="M7.34791 13.8242L2.94141 18.2307" stroke="#00A0FB" strokeMiterlimit="10" />
        <path d="M5.76953 21.0593L10.176 16.6523" stroke="#00A0FB" strokeMiterlimit="10" />
        <path
          d="M19.7777 19.7781C24.0734 15.4824 24.0734 8.51763 19.7777 4.2219C15.4819 -0.0738257 8.51719 -0.0738257 4.22146 4.2219C-0.0742669 8.51763 -0.0742669 15.4824 4.22146 19.7781C8.51719 24.0738 15.4819 24.0738 19.7777 19.7781Z"
          stroke="#00A0FB"
          strokeMiterlimit="10"
          strokeLinecap="square"
        />
        <path
          d="M15.5362 15.5355C17.4888 13.5829 17.4888 10.4171 15.5362 8.4645C13.5836 6.5119 10.4178 6.5119 8.46519 8.4645C6.51258 10.4171 6.51258 13.5829 8.46519 15.5355C10.4178 17.4881 13.5836 17.4881 15.5362 15.5355Z"
          stroke="#00A0FB"
          strokeMiterlimit="10"
          strokeLinecap="square"
        />
      </g>
      <defs>
        <clipPath id="clip0_7641_6089">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default CaseManagementIcon;
