import { useCallback } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { logoutUserService } from '../../api/services/auth/authService';
import { RootState } from '../../store';
import { logout } from '../../store/auth/authSlice';

export const useUserLogout = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const idToken = useSelector((state: RootState) => state.auth.idToken);

  const logoutUser = useCallback(async () => {
    if (!idToken) {
      return;
    }

    try {
      await logoutUserService(idToken);

      // Dispatch the logout action to clear the Redux state
      dispatch(logout());

      // Navigate to the login page or any other appropriate page
      navigate('/');
    } catch (error) {
      console.error('User logout failed:', error);
      // Optionally handle logout error (e.g., display error message)
    }
  }, [dispatch, navigate, idToken]);

  return { logoutUser };
};
