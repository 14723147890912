export const fontSize: { [key: string]: string } = {
  f11: '11px',
  f12: '12px',
  f13: '13px',
  f14: '14px',
  f16: '16px',
  f17: '17px',
  f18: '18px',
  f19: '19px',
  f20: '20px',
  f22: '22px',
  f24: '24px',
  f28: '28px',
  f32: '32px',
  f36: '36px',
  f40: '40px',
};

export const lineHeight: { [key: string]: string } = {
  l14: '14px',
  l15: '15px',
  l16: '16px',
  l19: '19px',
  l20: '20px',
  l21: '21px',
  l22: '22px',
  l24: '24px',
  l27: '27px',
};

export const fontWeight: { [key: string]: number } = {
  w100: 100,
  w200: 200,
  light: 300,
  normal: 400,
  medium: 500,
  w600: 600,
  bold: 700,
  w800: 800,
  w900: 900,
};
