import React from 'react';

import Translations from '../../../../../assets/locale/en/content.json';
import { TngDivider, TngGrid, TngTypography } from '../../../../../components/common';
import { AddressInfoDetails } from '../modals/AddressInfoDetails';
import { FieldConfig } from '../modals/FieldConfig';
import { CreateFieldConfigForAddressInfo } from '../utils/FieldConfig';

interface AddressInfoProps {
  addressInfo: AddressInfoDetails | undefined;
}

const AddressInfo: React.FC<AddressInfoProps> = ({ addressInfo }) => {
  const fieldConfig: FieldConfig[] = CreateFieldConfigForAddressInfo(addressInfo);
  return (
    <TngGrid container>
      <TngGrid item xs={12} className="account_details_header">
        <TngTypography variant="h6">{Translations.accountSettings.addressInfo}</TngTypography>
        <TngDivider />
      </TngGrid>
      {fieldConfig.map((acc) => (
        <TngGrid key={acc.name} item xs={12} md={12} lg={6} className="account_details_values">
          <>
            <TngTypography className="account_details_values_key">{acc.name}</TngTypography>
            <TngTypography>
              {acc &&
              acc.address &&
              (acc.address.street ||
                acc.address.city ||
                acc.address.state ||
                acc.address.country ||
                acc.address.postalCode)
                ? `${acc.address.street ? acc.address.street + ',' : ''} ${acc.address.city ? acc.address.city + ',' : ''} ${acc.address.state ? acc.address.state + ',' : ''} ${acc.address.country ? acc.address.country + ',' : ''} ${acc.address.postalCode ? acc.address.postalCode : ''}`
                : Translations.accountSettings.notAvailable}
            </TngTypography>
          </>
        </TngGrid>
      ))}
    </TngGrid>
  );
};

export default AddressInfo;
