import React from 'react';

import ErrorPage from '../../pages/ErrorPage';

export interface FallbackUIProps {}

const FallbackUI: React.FC<FallbackUIProps> = () => {
  return <ErrorPage />;
};

export default FallbackUI;
