import React, { useMemo } from 'react';

import GlobalPartnerProgram from './GlobalPartnerProgram';
import PublicSectorProgram from './PublicSectorProgram';
import { ProgramGuideSectionNames } from '../../../common/interfaces/partnerProgramGuide';
import { CustomTabs } from '../../../components/CustomTab/CustomTabs';
import { usePartnerProgramNames } from '../hooks/usePartnerProgramNames';

const GridTabs: React.FC<{ onTabSelection: (arg: number) => void }> = ({ onTabSelection }) => {
  const { data, isLoading } = usePartnerProgramNames();

  // Memoize tabConfig to avoid recomputation on every render
  const tabConfig = useMemo(
    () =>
      data?.map((program: ProgramGuideSectionNames) => ({
        label: program.programGuideName,
        id: program.id,
      })) || [],
    [data],
  );

  if (isLoading) return <></>;

  return (
    <CustomTabs tabs={tabConfig} onTabSelection={onTabSelection}>
      <GlobalPartnerProgram />
      <PublicSectorProgram />
    </CustomTabs>
  );
};

export default GridTabs;
