import React, { ReactNode } from 'react';

import ListItem, { ListItemProps } from '@mui/material/ListItem';

interface TngListItemProps extends ListItemProps {
  children: ReactNode;
}

export const TngListItem: React.FC<TngListItemProps> = ({ children, ...listItemProps }) => {
  return <ListItem {...listItemProps}>{children}</ListItem>;
};
