import React from 'react';

import Translations from '../../../../../../assets/locale/en/content.json';

// validate the input text
export const ValidateSearchText = (
  searchText: string,
  setIsError: React.Dispatch<React.SetStateAction<boolean>>,
  setErrorHelperText: React.Dispatch<React.SetStateAction<string>>,
): boolean => {
  // check if length > 3
  if (searchText.length < 3) {
    setErrorHelperText(Translations.TngGrid.searchbarError);
    setIsError(true);
    return false;
  }
  setIsError(false);
  return true;
};
