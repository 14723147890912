import { TransformDate } from '../../../utils/dateTimeConvertor';
import { TrainingCertificateGridModal } from '../modal/TrainingCertificateGridModal';
import { TrainingCertificateListModal } from '../modal/TrainingCertificateListModal';

// to map the data according to grid columns
export const mapTrainingCertificatesToTrainingCertificateGridModal = (
  certificates: TrainingCertificateListModal[],
): TrainingCertificateGridModal[] => {
  return certificates.map((certificate) => ({
    certificationName: certificate.certificationName,
    certificationDescription: certificate.certificationDescription,
    contactName: certificate.contactName,
    certificationDate: TransformDate(certificate.certificationDate),
    grade: certificate.grade,
  }));
};
