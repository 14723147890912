import React from 'react';

import AccountDetails from './CompanyAccountDetails/AccountDetails';
import Profile from './MyProfile/Profile';
import Translations from '../../../assets/locale/en/content.json';
import { CustomTabs } from '../../../components/CustomTab/CustomTabs';

interface TabConfig {
  label: string;
  id: number;
}

const AccountSettingsTabs = () => {
  const tabConfig: TabConfig[] = [
    { label: Translations.accountSettings.profile, id: 1 },
    { label: Translations.accountSettings.accountDetails, id: 2 },
  ];
  return (
    <CustomTabs tabs={tabConfig} orientation="vertical">
      <Profile />
      <AccountDetails />
    </CustomTabs>
  );
};

export default AccountSettingsTabs;
