import { PaginationBtnAttributes } from '../modal/PaginationBtnAttributes';
import { PaginationDropdown } from '../modal/PaginationDropdown';

// contain pagination dropdown values with label
export const dropdownValues: PaginationDropdown[] = [
  {
    label: 10,
    value: 10,
  },
  {
    label: 20,
    value: 20,
  },
  {
    label: 50,
    value: 50,
  },
  {
    label: 100,
    value: 100,
  },
];

// used to initialize the default values for pagination buttons
export const initializePaginationBtnValue = (): PaginationBtnAttributes[] => {
  return [
    { value: 1, disabled: false, id: 'btnFirst' },
    { value: 2, disabled: false, id: 'btnSecond' },
    { value: 3, disabled: false, id: 'btnThird' },
    { value: 4, disabled: false, id: 'btnFourth' },
    { value: 5, disabled: false, id: 'btnFifth' },
  ];
};
