/**
 * Maps a list of global price lists into a categorized structure.
 * Groups price list items by their category, converting each to a GlobalPriceListGrid format.
 *
 * @param {GlobalPriceList[]} globalPriceList - The list of global price list items to map.
 * @returns {Object} - An object where keys are categories, and values are arrays of GlobalPriceListGrid items.
 */

import { GlobalPriceList } from '../../../common/interfaces/globalPriceList/GlobalPriceList';
import { GlobalPriceListGrid } from '../interfaces/GlobalPriceListGrid';

export const mappedDataAccToCategory = (
  globalPriceList: GlobalPriceList[],
): { [key: string]: GlobalPriceListGrid[] } => {
  const mappedData = globalPriceList.reduce<{ [key: string]: GlobalPriceListGrid[] }>(
    (acc, pricelist) => {
      // Convert the pricelist to GlobalPriceListGrid format
      const gridItem: GlobalPriceListGrid = {
        id: pricelist.id,
        fileName: pricelist.name,
        fileType: pricelist.fileType, // Updated from 'extension' to 'fileType'
      };

      if (!acc[pricelist.category]) {
        acc[pricelist.category] = [];
      }
      acc[pricelist.category].push(gridItem);

      return acc;
    },
    {},
  );

  return mappedData;
};
