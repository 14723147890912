import React from 'react';

import Translations from '../../../../assets/locale/en/content.json';
import { TngTypography } from '../../../../components/common';

const PublicSectorProgram = () => {
  return (
    <TngTypography variant="h5" className="public_sector_program">
      {Translations.PartnerProgram.publicSectorProgramGuide}
    </TngTypography>
  );
};

export default PublicSectorProgram;
