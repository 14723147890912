import React, { useRef } from 'react';

import { TngGrid, TngLink } from '../common';

const SkipLink = () => {
  const skipToMainRef = useRef<HTMLAnchorElement>(null);
  const skipToNavigationRef = useRef<HTMLAnchorElement>(null);

  const handleSkipToMainClick = () => {
    const mainContent = document.getElementById('main-content');
    mainContent?.focus();
  };

  const handleSkipToNavigationClick = () => {
    const navContent = document.getElementById('navigation_landmark');
    navContent?.focus();
  };
  return (
    <TngGrid className="skip_link_container">
      <TngLink
        href="#main-content"
        ref={skipToMainRef}
        className="skip_link"
        onClick={handleSkipToMainClick}
      >
        Skip to main content
      </TngLink>
      <TngLink
        href="#navigation_landmark"
        ref={skipToNavigationRef}
        className="skip_link"
        onClick={handleSkipToNavigationClick}
      >
        Skip to navigation
      </TngLink>
    </TngGrid>
  );
};

export default SkipLink;
