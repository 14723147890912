import React, { useState, useEffect } from 'react';

import { Checkbox, ListItemText } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';

import Translations from '../../assets/locale/en/content.json';
import { TngTextField } from '../common';

type Option = {
  name: string;
  id: string;
  userType?: string; // Optional field to associate with a user type like "End User" or "Reseller"
};

interface CustomDropdownProps {
  options: Option[];
  setOption: (option: string[]) => void;
  selectedOptions: string[];
  placeholderText: string;
}

const TngMultiSelectDropdown: React.FC<CustomDropdownProps> = ({
  options,
  setOption,
  selectedOptions,
  placeholderText,
}) => {
  const [inputValue, setInputValue] = useState('');
  const [selectedOptionsState, setSelectedOptionsState] = useState<string[]>(selectedOptions);
  const [isOpen, setIsOpen] = useState(false);
  const [isDropdownClicked, setIsDropdownClicked] = useState(false);

  useEffect(() => {
    setSelectedOptionsState(selectedOptions);
  }, [selectedOptions]);

  const handleSelectChange = (event: React.ChangeEvent<unknown>, value: Option[]) => {
    const selectedIds = value.map((option) => option.id);
    setSelectedOptionsState(selectedIds);
    setOption(selectedIds);
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const input = event.target.value;
    setInputValue(input);

    if (input.length >= 3 && isDropdownClicked) {
      setIsOpen(true);
    } else {
      setIsOpen(false);
    }
  };

  const handleBlur = () => {
    setIsOpen(false);
    setIsDropdownClicked(false);
  };

  const handleDropdownClick = () => {
    setIsDropdownClicked(true);
  };

  // Function to get filtered options and ensure only selected options are shown on dropdown click
  const getFilteredOptions = () => {
    const lowercasedInputValue = inputValue.toLowerCase();

    // If dropdown is clicked, show only selected options
    if (isDropdownClicked && !inputValue) {
      return options.filter((option) => selectedOptionsState.includes(option.id));
    }

    // Filter options based on input value
    const filteredOptions = options.filter((option: Option) =>
      option.name?.toLowerCase().includes(lowercasedInputValue),
    );

    return [...filteredOptions];
  };

  return (
    <Autocomplete
      multiple
      open={isOpen}
      onOpen={handleDropdownClick}
      onClose={() => setIsOpen(false)}
      value={options.filter((option) => selectedOptionsState.includes(option.id))}
      options={getFilteredOptions()}
      inputValue={inputValue}
      onChange={handleSelectChange}
      getOptionLabel={(option) => option.name}
      getOptionKey={(option) => option.id}
      componentsProps={{
        popupIndicator: {
          onClick: () => {
            setIsOpen(!isOpen);
            setIsDropdownClicked(true);
          },
        },
      }}
      renderInput={(params) => (
        <TngTextField
          {...params}
          placeholder={placeholderText}
          onChange={handleInputChange}
          value={inputValue}
          onBlur={handleBlur}
        />
      )}
      renderOption={(props, option, { selected }) => (
        <li key={option.id} {...props}>
          <Checkbox checked={selected} />
          <ListItemText primary={option.name} />
        </li>
      )}
      disableCloseOnSelect
      noOptionsText={Translations.common.noOptionsText}
    />
  );
};

export default TngMultiSelectDropdown;
