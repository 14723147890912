import React, { ReactNode } from 'react';

import ListItemIcon, { ListItemIconProps } from '@mui/material/ListItemIcon';

interface TngListItemIconProp extends ListItemIconProps {
  children: ReactNode;
}

export const TngListItemIcon: React.FC<TngListItemIconProp> = ({
  children,
  ...listItemIconProps
}) => {
  return <ListItemIcon {...listItemIconProps}>{children}</ListItemIcon>;
};
