import React, { useMemo } from 'react';

import PartenerProgramPriceGrid from './PartnerProgramPriceGrid';
import { TngGrid, TngLoader } from '../../../components/common';
import { mappedDataAccToCategory } from '../gridHelper/dataMapper';
import { useGetGlobalPriceList } from '../hooks/useGetGlobalPriceList';
import { GlobalPriceListGrid } from '../interfaces/GlobalPriceListGrid';

const PartnerProfileGridContainer = () => {
  // #temporary
  // currently hardcoding the accountId will change it once we authenticate user
  const accountId: string = '001dy000000HRbhAAG';
  const { data: globalPriceList, isLoading } = useGetGlobalPriceList(accountId);

  const mappedCategorizedPriceLists = useMemo<{ [key: string]: GlobalPriceListGrid[] }>(() => {
    if (globalPriceList && globalPriceList.length > 0) {
      return mappedDataAccToCategory(globalPriceList);
    }
    return {};
  }, [globalPriceList]);

  return (
    <>
      {isLoading ? (
        <TngLoader />
      ) : (
        <TngGrid className="partner_profile_grid_container">
          {Object.keys(mappedCategorizedPriceLists).map((category) => (
            <PartenerProgramPriceGrid
              key={category}
              category={category}
              items={mappedCategorizedPriceLists[category]}
            />
          ))}
        </TngGrid>
      )}
    </>
  );
};

export default PartnerProfileGridContainer;
