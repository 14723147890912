import { TransformDate } from '../../../../utils/dateTimeConvertor';
import { AccountActiveContact } from '../../common/interfaces/ManageUsers';
import { ManageUserGridModal } from '../interface/ManageUsersGridModal';

// to map the data according to grid columns
export const mapContactsToGridModal = (users: AccountActiveContact[]): ManageUserGridModal[] => {
  return users.map((user) => ({
    Id: user.id,
    FirstName: user.firstName,
    LastName: user.lastName,
    EmailAddress: user.emailAddress,
    JobTitle: user.jobTitle,
    DateAdded: TransformDate(user.createdDate),
    UserType: user.userTypeId,
    Country: user.address.country,
  }));
};
