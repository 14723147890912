import React from 'react';

const ManageRenewalsIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_7641_6202)">
        <path
          d="M22.7445 0.855469L22.818 8.85797L15.1875 6.30497"
          stroke="#00A0FB"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M0.785156 11.1136C0.992522 8.48992 2.11322 6.02194 3.95225 4.13915C5.79128 2.25636 8.23222 1.07794 10.8503 0.808935C13.4685 0.539934 16.098 1.19738 18.2815 2.66688C20.465 4.13638 22.0641 6.3249 22.8007 8.85164"
          stroke="#00A0FB"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M1.25709 23.147L1.18359 15.1445L8.81409 17.6975"
          stroke="#00A0FB"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M23.2167 12.8906C23.0093 15.5143 21.8886 17.9823 20.0496 19.8651C18.2105 21.7479 15.7696 22.9263 13.1515 23.1953C10.5334 23.4643 7.90379 22.8069 5.72032 21.3374C3.53686 19.8679 1.93773 17.6794 1.20117 15.1526"
          stroke="#00A0FB"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_7641_6202">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ManageRenewalsIcon;
