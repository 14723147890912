import React, { ReactNode } from 'react';

import { Navigate } from 'react-router-dom';

interface PermissionRouteProps {
  isView: boolean | undefined;
  routeName: string;
  children: ReactNode | ReactNode[];
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const PermissionRoute: React.FC<PermissionRouteProps> = ({ isView, routeName, children }) => {
  // If the user has the required permission, render the children (the protected component)
  if (isView) {
    return <>{children}</>;
  }
  // If access is granted, render the child routes, otherwise redirect
  return <Navigate to={'/unAuthorized'} />;
};

export default PermissionRoute;
