import { useQuery } from '@tanstack/react-query';

import { getOpportunityById } from '../../../api/services/quote/quoteService';
import { OpportunityModel } from '../../../common/interfaces/quotes/OppertunityDetailsResponse';

// this hook called the api and get data to be displayed on Quote detail page
export const useOpportunityDetails = (opportunityId: string) => {
  const queryKey = `Opportunity details${opportunityId}`;
  const { data, error, isLoading } = useQuery<OpportunityModel, Error>({
    queryKey: [queryKey, opportunityId],
    queryFn: () => getOpportunityById(opportunityId),
  });
  return { data, error, isLoading };
};
