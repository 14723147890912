import React, { useEffect, useState } from 'react';

import { initializePaginationBtnValue } from './helper/dataHelper';
import { PaginationBtnAttributes } from './modal/PaginationBtnAttributes';
import {
  FirstButton,
  LastButton,
  NextButton,
  PaginationNumberButtons,
  PrevButton,
} from './TngButtonsDescription';
import { GridActionEnum } from '../../../../../common/enums/GridActionEnum';
import {
  useGridDispatch,
  useGridState,
} from '../../../../../common/GridContext/GridContextProvider';
import { TngGrid } from '../../../../common';

const TngPaginationButtons = () => {
  const { totalPages, isSortingApplied } = useGridState();
  const dispatch = useGridDispatch();
  const [buttonValue, setButtonValue] = useState<PaginationBtnAttributes[]>(
    initializePaginationBtnValue(),
  );

  // if total pages changes then re-calculate the buttons display logic
  useEffect(() => {
    const updatedButtonValue = buttonValue.map((button, index) => ({
      ...button,
      value: index + 1,
    }));
    setButtonValue(updatedButtonValue);
    goToPage(1);

    isSortingApplied && dispatch({ type: GridActionEnum.SET_IS_SORTING_APPLIED, payload: false });
  }, [totalPages, isSortingApplied]);

  const goToPage = (page: number) => {
    dispatch({ type: GridActionEnum.SET_PAGE_NUMBER, payload: page });
  };

  return (
    <TngGrid container>
      <TngGrid item className="grid_pagination_buttons">
        <FirstButton
          buttonValue={buttonValue}
          setButtonValue={setButtonValue}
          goToPage={goToPage}
        />
        <PrevButton buttonValue={buttonValue} setButtonValue={setButtonValue} goToPage={goToPage} />
        <PaginationNumberButtons buttonValue={buttonValue} goToPage={goToPage} />
        <NextButton buttonValue={buttonValue} setButtonValue={setButtonValue} goToPage={goToPage} />
        <LastButton buttonValue={buttonValue} setButtonValue={setButtonValue} goToPage={goToPage} />
      </TngGrid>
    </TngGrid>
  );
};

export default TngPaginationButtons;
