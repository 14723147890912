import Translations from '../../../../../assets/locale/en/content.json';
import { CountryOptions } from '../../../../../common/interfaces/CountryOptions';
import { StateOptions } from '../../../../../common/interfaces/StateOptions';
import { FieldConfig } from '../interfaces/ProfileFormField';

/**
Create a field config for form to render fields on UI
@parameter {countryOptions} - List of options for country dropdown.
@parameter {stateOptions} - List of options for state dropdown.
@returns FieldConfig[] - return field config
*/

export const GetInputFieldConfig = (
  countryOptions: CountryOptions[],
  stateOptions: StateOptions[],
  selectedCountry: number | null,
): FieldConfig[] => {
  const country = countryOptions.filter(
    (country) => country.value === Translations.common.usCountry,
  );

  const fieldConfig: FieldConfig[] = [
    [
      {
        label: Translations.AccountSettingsProfile.firstName,
        htmlFor: 'firstName',
        fieldType: 'text',
        placeholder: Translations.AccountSettingsProfile.firstName,
        validationRules: { required: true, maxLength: 40 },
      },
      {
        label: Translations.AccountSettingsProfile.lastName,
        htmlFor: 'lastName',
        fieldType: 'text',
        placeholder: Translations.AccountSettingsProfile.lastName,
        validationRules: { required: true, maxLength: 80 },
      },
    ],
    [
      {
        label: Translations.AccountSettingsProfile.jobTitle,
        htmlFor: 'jobTitle',
        fieldType: 'text',
        placeholder: Translations.AccountSettingsProfile.jobTitle,
        validationRules: { required: false, maxLength: 40 },
      },
    ],
    [
      {
        label: Translations.AccountSettingsProfile.emailAddress,
        htmlFor: 'emailAddress',
        fieldType: 'text',
        placeholder: Translations.AccountSettingsProfile.emailAddress,
        validationRules: { required: true, readonly: true },
      },
      {
        label: Translations.AccountSettingsProfile.phoneNumber,
        htmlFor: 'phoneNumber',
        fieldType: 'text',
        placeholder: Translations.AccountSettingsProfile.phoneNumber,
        validationRules: { required: true, maxLength: 40 },
      },
    ],
    [
      {
        label: Translations.AccountSettingsProfile.country,
        htmlFor: 'country',
        fieldType: 'dropdown',
        placeholder: 'Select',
        validationRules: { required: true },
        options: countryOptions,
      },
      {
        label: Translations.AccountSettingsProfile['province/state'],
        htmlFor: 'state',
        fieldType: 'dropdown',
        placeholder: 'Select',
        validationRules: {
          required: country[0]?.id === selectedCountry,
          disabled: selectedCountry ? false : true,
        },
        options: stateOptions,
      },
    ],
    [
      {
        label: Translations.AccountSettingsProfile.street,
        htmlFor: 'street',
        fieldType: 'text',
        placeholder: Translations.AccountSettingsProfile.street,
        validationRules: { required: true, maxLength: 255 },
      },
    ],
    [
      {
        label: Translations.AccountSettingsProfile.city,
        htmlFor: 'city',
        fieldType: 'text',
        placeholder: Translations.AccountSettingsProfile.city,
        validationRules: { required: true, maxLength: 40 },
      },
      {
        label: Translations.AccountSettingsProfile['postal/zipcode'],
        htmlFor: 'postalCode',
        fieldType: 'text',
        placeholder: Translations.AccountSettingsProfile['postal/zipcode'],
        validationRules: { required: false, maxLength: 20 },
      },
    ],
  ];
  return fieldConfig;
};
