import { AccountDetailsResponse } from '../../../common/interfaces/accountsettings/accountdetails/accountDetailsResponse';
import axiosInstance from '../../axiosInstance';
import { getUrl, URLS } from '../../urls';

/**
Fetches the account details for given account Id from the API.
@returns {Promise<OrderStatus[]>} - A promise that resolves to account details.
@throws {Error} - Throws an error if the request fails.
*/
export const fetchAccountDetails = async (accountId: string): Promise<AccountDetailsResponse> => {
  try {
    const url = getUrl(URLS.ACCOUNT_DETAILS, { accountId: accountId });

    const { data } = await axiosInstance.get<AccountDetailsResponse>(url);
    return data;
  } catch (error) {
    throw new Error('Failed to fetch account details');
  }
};
