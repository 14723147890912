import React, { useRef } from 'react';

import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

import {
  handleFirstClick,
  handleLastClick,
  handleNextClick,
  handlePrevClick,
} from './helper/helperMethods';
import { updateLiveRegionTextContet } from './helper/updateLiveRegionTextContext';
import { PaginationBtnAttributes } from './modal/PaginationBtnAttributes';
import TngButtonLiveRegion from './TngButtonLiveRegion';
import Translations from '../../../../../assets/locale/en/content.json';
import { useGridState } from '../../../../../common/GridContext/GridContextProvider';
import { TngButton } from '../../../../common';

interface ButtonProps {
  buttonValue: PaginationBtnAttributes[];
  setButtonValue: React.Dispatch<React.SetStateAction<PaginationBtnAttributes[]>>;
  goToPage: (page: number) => void;
}

interface PaginationNumberButtonsProps {
  buttonValue: PaginationBtnAttributes[];
  goToPage: (page: number) => void;
}

// interfaces for button clickHandlers
interface FirstButtonClick extends ButtonProps {}
interface PrevButtonClick extends ButtonProps {
  pageNumber: number;
}
interface NextButtonClick extends PrevButtonClick {}
interface LastButtonClick extends ButtonProps {
  totalPages: number;
}

export const FirstButton: React.FC<ButtonProps> = ({ buttonValue, setButtonValue, goToPage }) => {
  const { pageNumber } = useGridState();

  const liveRegionRef = useRef<HTMLDivElement | null>(null);

  const handleClick = ({ buttonValue, setButtonValue, goToPage }: FirstButtonClick) => {
    // Update live region content
    updateLiveRegionTextContet(liveRegionRef, Translations.orderHistory.gotoFirstPage);
    handleFirstClick(buttonValue, setButtonValue, goToPage);
  };
  return (
    <>
      <TngButton
        className="pagination_btn first_btn"
        aria-label={Translations.orderHistory.gotoFirstPage}
        onClick={() => handleClick({ buttonValue, setButtonValue, goToPage })}
        disabled={pageNumber === 1}
      >
        {Translations.orderHistory.first}
      </TngButton>

      {/* Hidden live region to announce button click */}
      <TngButtonLiveRegion liveRegionRef={liveRegionRef} />
    </>
  );
};

export const PrevButton: React.FC<ButtonProps> = ({ buttonValue, setButtonValue, goToPage }) => {
  const { pageNumber } = useGridState();
  const liveRegionRef = useRef<HTMLDivElement | null>(null);

  const handleClick = ({ pageNumber, buttonValue, setButtonValue, goToPage }: PrevButtonClick) => {
    handlePrevClick(pageNumber, buttonValue, setButtonValue, goToPage);
    // Update live region content
    updateLiveRegionTextContet(liveRegionRef, Translations.orderHistory.gotoPrevPage);
  };

  return (
    <>
      <TngButton
        className="pagination_btn prev_btn"
        aria-label={Translations.orderHistory.gotoPrevPage}
        onClick={() => handleClick({ pageNumber, buttonValue, setButtonValue, goToPage })}
        disabled={pageNumber === 1}
        startIcon={<KeyboardArrowLeftIcon />}
      >
        {Translations.orderHistory.prev}
      </TngButton>

      {/* Hidden live region to announce button click */}
      <TngButtonLiveRegion liveRegionRef={liveRegionRef} />
    </>
  );
};

export const PaginationNumberButtons: React.FC<PaginationNumberButtonsProps> = ({
  buttonValue,
  goToPage,
}) => {
  const { pageNumber, totalPages } = useGridState();
  // Create a ref for the live region
  const liveRegionRef = useRef<HTMLDivElement | null>(null);

  // Function to handle button click and update live region
  const handleClick = (page: number) => {
    const message = `${Translations.orderHistory.goto} ${page} ${Translations.orderHistory.page}`;
    // Update live region content
    updateLiveRegionTextContet(liveRegionRef, message);
    // Navigate to the clicked page
    goToPage(page);
  };

  return (
    <>
      {buttonValue.map((value) => (
        <TngButton
          key={value.id}
          id={value.id}
          aria-label={`${Translations.orderHistory.goto} ${value.value} ${Translations.orderHistory.page}`}
          disabled={value.value > totalPages}
          onClick={() => handleClick(value.value)}
          className={`pagination_number_btn ${pageNumber === value.value && 'btn_selected'}`}
        >
          {value.value.toString()}
        </TngButton>
      ))}
      {/* Hidden live region to announce button click */}
      <TngButtonLiveRegion liveRegionRef={liveRegionRef} />
    </>
  );
};

export const NextButton: React.FC<ButtonProps> = ({ buttonValue, setButtonValue, goToPage }) => {
  const { pageNumber, totalPages } = useGridState();
  const liveRegionRef = useRef<HTMLDivElement | null>(null);

  const handleClick = ({ pageNumber, buttonValue, setButtonValue, goToPage }: NextButtonClick) => {
    handleNextClick(pageNumber, buttonValue, setButtonValue, goToPage);
    // Update live region content
    updateLiveRegionTextContet(liveRegionRef, Translations.orderHistory.gotoNextPage);
  };

  return (
    <>
      <TngButton
        className="pagination_btn next_btn"
        aria-label={Translations.orderHistory.gotoNextPage}
        onClick={() => handleClick({ pageNumber, buttonValue, setButtonValue, goToPage })}
        disabled={pageNumber >= totalPages}
        endIcon={<KeyboardArrowRightIcon />}
      >
        {Translations.orderHistory.next}
      </TngButton>
      {/* Hidden live region to announce button click */}
      <TngButtonLiveRegion liveRegionRef={liveRegionRef} />
    </>
  );
};

export const LastButton: React.FC<ButtonProps> = ({ buttonValue, setButtonValue, goToPage }) => {
  const { pageNumber, totalPages } = useGridState();
  const liveRegionRef = useRef<HTMLDivElement | null>(null);

  const handleClick = ({ totalPages, buttonValue, setButtonValue, goToPage }: LastButtonClick) => {
    handleLastClick(totalPages, buttonValue, setButtonValue, goToPage);
    // Update live region content
    updateLiveRegionTextContet(liveRegionRef, Translations.orderHistory.gotoLastPage);
  };

  return (
    <>
      <TngButton
        className="pagination_btn last_btn"
        aria-label={Translations.orderHistory.gotoLastPage}
        onClick={() => handleClick({ totalPages, buttonValue, setButtonValue, goToPage })}
        disabled={pageNumber >= totalPages}
      >
        {Translations.orderHistory.last}
      </TngButton>
      {/* Hidden live region to announce button click */}
      <TngButtonLiveRegion liveRegionRef={liveRegionRef} />
    </>
  );
};
