import React, { ReactNode } from 'react';

import { ErrorBoundary } from 'react-error-boundary';
import { useSelector } from 'react-redux';

import FallbackUI from './FallbackUI';
import { RootState } from '../../store'; // Adjust the path based on where your store is located

interface ErrorBoundaryComponentProps {
  children: ReactNode;
}

const ReactErrorBoundary: React.FC<ErrorBoundaryComponentProps> = ({ children }) => {
  // Assuming you have a global error state in Redux like isError
  const { isError } = useSelector((state: RootState) => state.master);

  // Function to log errors to a logging service
  const logErrorToService = (error: Error, info: React.ErrorInfo) => {
    console.error('Logging an error:', error, info);
  };

  // Conditionally render the fallback UI based on Redux error state
  if (isError) {
    // Manually pass the props `error` and `resetErrorBoundary` to FallbackUI
    return <FallbackUI />;
  }

  return (
    <ErrorBoundary FallbackComponent={FallbackUI} onError={logErrorToService}>
      {children}
    </ErrorBoundary>
  );
};

export default ReactErrorBoundary;
