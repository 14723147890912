import Translations from '../../../assets/locale/en/content.json';
import { FieldConfig } from '../modals/fieldConfig';

// create config which used to render the fields using map
export const CreateFieldConfig = (fieldDetails: any): FieldConfig[] => {
  const fieldConfig: FieldConfig[] = [
    { name: Translations.OrderSummary.customerName, value: fieldDetails?.customerName },
    { name: Translations.OrderSummary.poDate, value: fieldDetails?.poDate },
    {
      name: Translations.OrderSummary.orderCreationDate,
      value: fieldDetails?.orderCreationDateAndTime,
    },
    { name: Translations.OrderSummary.contractNumber, value: fieldDetails?.contractNumber },
    { name: Translations.OrderSummary.quoteId, value: fieldDetails?.quoteId },
    { name: Translations.OrderSummary.orderType, value: fieldDetails?.orderType },
    { name: Translations.OrderSummary.soldThrough, value: fieldDetails?.soldThrough },
    {
      name: Translations.OrderSummary.salesRepresentative,
      value: fieldDetails?.salesRep,
    },
  ];

  return fieldConfig;
};
