import React, { useRef, useState } from 'react';

import { CSSObject, Theme } from '@mui/material';

import ExpandCollapsedIcon from './ExpandCollapsedIcon';
import MenuItem from './MenuItem';
import { MenuItemModal } from './modal/MenuItemModal';
import useIsMobile from '../../../../common/hooks/useIsMobile';
import { TngList } from '../../../common';
import Drawer from '../../../Drawer';

const navItemsConfig: MenuItemModal[] = [
  { url: '/dashboard', label: 'Dashboard', icon: 'dashboardIcon', activeItems: ['/dashboard'] },
  {
    url: '/order-history',
    label: 'Order History',
    icon: 'orderIcon',
    activeItems: ['/order-history', '/order-summary'],
  },
  {
    url: '/manage-quotes',
    label: 'Manage Quotes',
    icon: 'manageQuotesIcon',
    activeItems: ['/manage-quotes', '/quotes-details'],
  },
  { url: '#', label: 'Manage Renewals', icon: 'manageRenewalsIcon', activeItems: [''] },
  { url: '#', label: 'Eval & NFR License', icon: 'evalIcon', activeItems: [''] },
  {
    url: '/manage-users',
    label: 'Manage Users',
    icon: 'manageUsersIcon',
    activeItems: ['/manage-users', '/user-details'],
  },
  { url: '#', label: 'Knowledge Center', icon: 'knowledgeCenterIcon', activeItems: [''] },
  { url: '#', label: 'Community Forum', icon: 'communityForumIcon', activeItems: [''] },
  { url: '#', label: 'High Spot', icon: 'highSpotIcon', activeItems: [''] },
  { url: '#', label: 'Case Management', icon: 'caseManagementIcon', activeItems: [''] },
];

const SideMenu = () => {
  const isMobile = useIsMobile();
  const [open, setOpen] = useState<boolean>(!isMobile);

  const buttonRef = useRef<HTMLButtonElement>(null);

  const toggleDrawer = () => {
    setOpen(!open);
    // to maintain focus on current button
    // Use a timeout to ensure the state change completes before focusing
    setTimeout(() => {
      if (buttonRef.current) {
        buttonRef.current.focus();
      }
    }, 0);
  };

  const responsiveWidthMixin = (theme: Theme): CSSObject => ({
    [theme.breakpoints.down('md')]: {
      width: '25%', // Width for screens below 850px
    },
    [theme.breakpoints.down(700)]: {
      width: '30%', // Width for screens below 700px
    },
    [theme.breakpoints.down('sm')]: {
      width: '38%', // Width for screens below 600px
    },
    [theme.breakpoints.down(450)]: {
      width: '45%', // Width for screens below 450px
    },
  });

  return (
    <Drawer
      role="navigation"
      id="navigation_landmark"
      className="sidenav_drawer"
      width="18.4%"
      top="64px"
      variant="permanent"
      anchor="left"
      open={open}
      toggleDrawer={toggleDrawer}
      responsiveWidthMixin={responsiveWidthMixin}
      ModalProps={{
        keepMounted: true,
      }}
    >
      <TngList className="sidenav_list_container">
        {navItemsConfig.map((item: MenuItemModal) => (
          <MenuItem
            key={item.label}
            item={item}
            open={open}
            setOpen={setOpen}
            isMobile={isMobile}
          />
        ))}
      </TngList>
      <ExpandCollapsedIcon open={open} toggleDrawer={toggleDrawer} ref={buttonRef} />
    </Drawer>
  );
};

export default SideMenu;
