import { isArrayEquals } from '../../../utils/isArrayEquals';
import { ManageQuotesFilter } from '../modals/ManageQuotesFilter';
import { ManageQuotesFilterContext } from '../modals/ManageQuotesFilterContext';

export const initializeFilterState = (filters: ManageQuotesFilterContext) => {
  const initialFilter: ManageQuotesFilter = {
    opportunities: filters.opportunities || [],
    quoteStatus: filters.quoteStatus || [],
    opportunityValue: filters.opportunityValue || [],
  };
  return initialFilter;
};

// Check if filters have been modified or not
export const isAnyFilterSelected = (filterObj: ManageQuotesFilter): boolean => {
  return filterObj.opportunities.length > 0 || filterObj.quoteStatus.length > 0;
};

// Check if filters have been modified or not
export const checkIfManageQuotesFiltersChanged = (
  filterObj: ManageQuotesFilter,
  defaultFilterObj: ManageQuotesFilter,
): boolean => {
  // Check if any filters have changed by comparing with default values
  return (
    !isArrayEquals(filterObj.opportunities, defaultFilterObj.opportunities) ||
    !isArrayEquals(filterObj.quoteStatus, defaultFilterObj.quoteStatus)
  );
};
