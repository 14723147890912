import React from 'react';

import Translations from '../../assets/locale/en/content.json';
import TngErrorFallbackUI from '../../components/common/TngErrorFallbackUI';

const ErrorPage: React.FC = () => {
  return <TngErrorFallbackUI errorMessage={Translations.error.somethingWentWrongMessage} />;
};

export default ErrorPage;
