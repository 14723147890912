/**
 * fetchGlobalPriceList Service
 *
 * Fetches the global price list for a specified account ID from the server.
 * Returns an array of GlobalPriceList objects. Throws an error if the fetch fails.
 *
 * @param {string} accountId - The ID of the account for which to fetch the price list.
 * @returns {Promise<GlobalPriceList[]>} - A promise that resolves to the global price list.
 */

import {
  GlobalPriceList,
  GlobalPriceListResponse,
} from '../../../common/interfaces/globalPriceList/GlobalPriceList';
import axiosInstance from '../../axiosInstance';
import { getUrl, URLS } from '../../urls';

export const fetchGlobalPriceList = async (accountId: string): Promise<GlobalPriceList[]> => {
  try {
    const url = getUrl(URLS.GLOBAL_PRICE_LIST, { accountId: accountId });

    const { data } = await axiosInstance.get<GlobalPriceListResponse>(url);
    return data.data;
  } catch (error) {
    throw new Error('Failed to fetch global price list');
  }
};
