import React from 'react';

import { useSelector } from 'react-redux';
import { RouteObject, useRoutes } from 'react-router-dom';

import PermissionRoute from './permissionRoute';
import ProtectedRoute from './protectedRoute';
import PublicRoute from './publicRoute';
import { TngRouteObject } from './tngRouteObject';
import DashboardLayout from '../components/Layout/DashboardLayout';
import HeaderLayout from '../components/Layout/HeaderLayout';
import LoginLayout from '../components/Layout/LoginLayout';
import SkipLink from '../components/SkipContent/SkipLink';
import SkipToMainContent from '../components/SkipContent/SkipToMainContent';
import AccountSettings from '../pages/AccountSettings';
import Dashboard from '../pages/Dashboard';
import ForgotPassword from '../pages/ForgotPassword';
import Login from '../pages/Login';
import ManageQuotes from '../pages/ManageQuotes';
import ManageUser from '../pages/ManageUsers/ManageUser';
import UserDetails from '../pages/ManageUsers/UserDetail';
import QuoteCreation from '../pages/NewQuoteCreation';
import NotFound from '../pages/NotFound';
import OrderHistory from '../pages/OrderHistory';
import OrderSummary from '../pages/OrderSummary';
import PartnerProgramGuides from '../pages/PartnerProgramGuide';
import PartnerProgramPrice from '../pages/PartnerProgramPriceList';
import QuoteManageDetails from '../pages/QuoteDetails';
import ResetPassword from '../pages/ResetPassword';
import TrainingCertification from '../pages/TrainingCertifications';
import UnAuthorized from '../pages/UnAuthorized';
import { RootState } from '../store';

export interface ChildRoute {
  path: string;
  element: JSX.Element;
}

export const TungstenRoutes = () => {
  const permissions = useSelector((state: RootState) => state.user.permissions);

  const routes: TngRouteObject[] = [
    {
      path: '/',
      element: (
        <>
          <ProtectedRoute />
        </>
      ),
      children: [
        {
          path: 'dashboard',
          element: (
            <PermissionRoute isView={true} routeName="dashboard">
              <SkipLink /> <DashboardLayout />
            </PermissionRoute>
          ),
          children: [
            {
              index: true,
              element: (
                <SkipToMainContent>
                  <Dashboard />
                </SkipToMainContent>
              ),
            },
          ],
        },
        {
          path: 'order-history',
          element: (
            <PermissionRoute
              isView={permissions.orderHistory && permissions.orderHistory?.list}
              routeName="order-history"
            >
              <SkipLink /> <DashboardLayout />
            </PermissionRoute>
          ),
          children: [
            {
              index: true,
              element: (
                <SkipToMainContent>
                  <OrderHistory />
                </SkipToMainContent>
              ),
            },
          ],
        },
        {
          path: 'order-summary',
          element: (
            <PermissionRoute
              isView={permissions?.orderHistory && permissions.orderHistory?.list}
              routeName="order-summary"
            >
              <SkipLink /> <DashboardLayout />
            </PermissionRoute>
          ),
          children: [
            {
              index: true,
              element: (
                <SkipToMainContent>
                  <OrderSummary />
                </SkipToMainContent>
              ),
            },
          ],
        },
        {
          path: 'manage-users',
          element: (
            <PermissionRoute
              isView={permissions?.manageUser && permissions.manageUser?.list}
              routeName="manage-users"
            >
              <SkipLink /> <DashboardLayout />
            </PermissionRoute>
          ),
          children: [
            {
              index: true,
              element: (
                <SkipToMainContent>
                  <ManageUser />
                </SkipToMainContent>
              ),
            },
          ],
        },
        {
          path: 'user-details',
          element: (
            <PermissionRoute
              isView={permissions?.manageUser && permissions.manageUser?.list}
              routeName="user-details"
            >
              <SkipLink /> <DashboardLayout />
            </PermissionRoute>
          ),
          children: [
            {
              index: true,
              element: (
                <SkipToMainContent>
                  <UserDetails />
                </SkipToMainContent>
              ),
            },
          ],
        },
        {
          path: 'quote-details',
          element: (
            <PermissionRoute isView={true} routeName="quote-details">
              <SkipLink /> <DashboardLayout />
            </PermissionRoute>
          ),
          children: [
            {
              index: true,
              element: (
                <SkipToMainContent>
                  <QuoteManageDetails />
                </SkipToMainContent>
              ),
            },
          ],
        },
        {
          path: 'manage-quotes',
          element: (
            <PermissionRoute isView={true} routeName="manage-quotes">
              <SkipLink /> <DashboardLayout />
            </PermissionRoute>
          ),
          children: [
            {
              index: true,
              element: (
                <SkipToMainContent>
                  <ManageQuotes />
                </SkipToMainContent>
              ),
            },
          ],
        },
      ],
    },
    // Adding Training Certifications and Account Settings as protected routes
    {
      path: 'training-certifications',
      element: <ProtectedRoute />,
      children: [
        {
          index: true,
          element: (
            <PermissionRoute
              isView={permissions?.trainingCertification && permissions.trainingCertification?.list}
              routeName="training-certifications"
            >
              <HeaderLayout>
                <TrainingCertification />
              </HeaderLayout>
            </PermissionRoute>
          ),
        },
      ],
    },
    {
      path: 'account-settings',
      element: <ProtectedRoute />,
      children: [
        {
          index: true,
          element: (
            <PermissionRoute isView={true} routeName="account-settings">
              <HeaderLayout>
                <AccountSettings />
              </HeaderLayout>
            </PermissionRoute>
          ),
        },
      ],
    },
    {
      path: 'partner-program-guide',
      element: <ProtectedRoute />,
      children: [
        {
          index: true,
          element: (
            <PermissionRoute
              isView={permissions?.programGuide && permissions.programGuide?.list}
              routeName="partner-program-guide"
            >
              <HeaderLayout>
                <PartnerProgramGuides />
              </HeaderLayout>
            </PermissionRoute>
          ),
        },
      ],
    },
    {
      path: 'partner-program-price-list',
      element: <ProtectedRoute />,
      children: [
        {
          index: true,
          element: (
            <PermissionRoute
              isView={permissions?.programPriceList && permissions.programPriceList?.list}
              routeName="partner-program-price-list"
            >
              <HeaderLayout>
                <PartnerProgramPrice />
              </HeaderLayout>
            </PermissionRoute>
          ),
        },
      ],
    },
    {
      path: 'quote-creation',
      element: <ProtectedRoute />,
      children: [
        {
          index: true,
          element: (
            <PermissionRoute isView={true} routeName="quote-creation">
              <HeaderLayout>
                <QuoteCreation />
              </HeaderLayout>
            </PermissionRoute>
          ),
        },
      ],
    },

    {
      path: '/',
      element: <PublicRoute />,
      children: [
        {
          index: true,
          element: (
            <LoginLayout>
              <Login />
            </LoginLayout>
          ),
        },
      ],
    },
    {
      path: 'forgot-password',
      element: <PublicRoute />,
      children: [
        {
          index: true,
          element: (
            <LoginLayout>
              <ForgotPassword />
            </LoginLayout>
          ),
        },
      ],
    },
    {
      path: 'reset-password',
      element: <PublicRoute />,
      children: [
        {
          index: true,
          element: <ResetPassword />,
        },
      ],
    },
    {
      path: 'unAuthorized',
      children: [
        {
          index: true,
          element: <UnAuthorized />,
        },
      ],
    },
    {
      path: '*',
      element: <NotFound />,
    },
  ];

  return useRoutes(routes as RouteObject[]);
};
