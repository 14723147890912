import React from 'react';

import { Typography } from '@mui/material';

import Translations from '../../../../assets/locale/en/content.json';
import { QuoteStatus } from '../../../../common/interfaces/manageQuotes/QuoteStatus';
import { TngGrid } from '../../../../components/common';
import TngCheckbox from '../../../../components/common/TngCheckbox';
import { ManageQuotesFilter } from '../../modals/ManageQuotesFilter';

interface QuoteStatusProps {
  quoteStatus: QuoteStatus[] | undefined;
  filterObj: ManageQuotesFilter;
  setFiltersObj: React.Dispatch<React.SetStateAction<ManageQuotesFilter>>;
}

const FilterQuoteStatus: React.FC<QuoteStatusProps> = ({
  quoteStatus,
  filterObj,
  setFiltersObj,
}) => {
  // update state after user select/deselect order status in filter
  const handleQuoteStatusToggle = (quoteStatusValue: string) => () => {
    const existingQuoteStatus = filterObj?.quoteStatus || [];
    const newFilters = existingQuoteStatus.includes(quoteStatusValue)
      ? existingQuoteStatus.filter((value: any) => value !== quoteStatusValue)
      : [...existingQuoteStatus, quoteStatusValue];

    setFiltersObj({ ...filterObj, quoteStatus: newFilters });
  };

  return (
    <TngGrid container marginTop="20px">
      <TngGrid item xs={12} className="order_status_text">
        <Typography variant="h5">{Translations.filterSection.quoteStatus}</Typography>
      </TngGrid>
      <TngGrid item xs={12} className="order_status_checkbox">
        {quoteStatus &&
          quoteStatus.map((status: QuoteStatus) => (
            <TngCheckbox
              key={status.id}
              ariaLabel={Translations.filterSection.quoteStatus}
              checked={(filterObj.quoteStatus || []).includes(status.name)}
              label={status.name}
              handleToggleCheckbox={handleQuoteStatusToggle(status.name)}
            />
          ))}
      </TngGrid>
    </TngGrid>
  );
};

export default FilterQuoteStatus;
