// define sorting types enum
export enum Sorting {
  ASC = 'Asc',
  DESC = 'Desc',
}

export const sortOrderMap: { [key in Sorting]: 'asc' | 'desc' | null } = {
  [Sorting.ASC]: 'asc',
  [Sorting.DESC]: 'desc',
};
