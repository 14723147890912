import { useQuery } from '@tanstack/react-query';

import { fetchOrderStatus } from '../../api/services/order/orderService';
import { StatusWithColor } from '../interfaces/StatusColor';

/**

Custom hook to fetch and manage order status data using React Query.
@returns {object} - An object containing order status data, error, and loading state.
*/
export const useOrderStatus = () => {
  const { data, error, isLoading } = useQuery<StatusWithColor[], Error>({
    queryKey: ['orderStatus'],
    queryFn: fetchOrderStatus,
  });

  return { data, error, isLoading };
};
