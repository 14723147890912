import axios, { AxiosInstance } from 'axios';

const axiosInstance: AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL, // Set your base URL here
  timeout: 10000, // Set default timeout (in milliseconds)
  maxRedirects: 10,
  headers: {
    'Content-Type': 'application/json',
    'Ocp-Apim-Subscription-Key': process.env.REACT_APP_BACKEND_CODE_PARAMS,
  },
});

export default axiosInstance;
