// src/common/hooks/useUserLogin.ts
import { useState, useCallback } from 'react';

import { useDispatch } from 'react-redux';

import { initiateFlow } from '../../api/services/auth/authService';
import Translations from '../../assets/locale/en/content.json';
import { login } from '../../store/auth/authSlice';
import { TokenCodes } from '../interfaces/LoginRequest';
import { AuthTokens, UserData } from '../interfaces/UserAccount';

export const useUserLogin = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const dispatch = useDispatch();

  const loginUser = useCallback(
    async (data: UserData): Promise<AuthTokens | null> => {
      setLoading(true);
      setError(null);

      try {
        const authData: TokenCodes = await initiateFlow(data);

        if (authData && authData.accessToken && authData.idToken && authData.refreshToken) {
          // Dispatch login action to Redux store
          // Dispatch login action to Redux store with tokens from authCode
          dispatch(
            login({
              token: authData.accessToken,
              idToken: authData.idToken,
              refreshToken: authData.refreshToken,
            }),
          );
        } else {
          throw new Error('Invalid token structure received');
        }
        setLoading(false);
        return authData;
      } catch (error: any) {
        // Handle/display error according to the error code
        setError(Translations.Login.loginClientError);
        setLoading(false);
        throw Error(Translations.Login.loginClientError);
      }
    },
    [dispatch],
  );

  return { loginUser, loading, error };
};
