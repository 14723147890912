import React from 'react';

import Translations from '../../../assets/locale/en/content.json';
import { OpportunityModel } from '../../../common/interfaces/quotes/OppertunityDetailsResponse';
import TngInfoCard from '../../../components/TngInfoCard';
import { TransformDate } from '../../../utils/dateTimeConvertor';

interface OpportunityDetailsProps {
  data: OpportunityModel;
}

const OpportunityDetails: React.FC<OpportunityDetailsProps> = ({ data: opportunityData }) => {
  const fieldConfig = [
    { name: Translations.QuoteDetails.opportunityName, value: opportunityData?.name },
    { name: Translations.QuoteDetails.opportunityId, value: opportunityData?.opportunityId },
    {
      name: Translations.QuoteDetails.opportunityCloseDate,
      value: TransformDate(opportunityData?.closeDate),
    },
    { name: Translations.QuoteDetails.dealClassification, value: opportunityData?.type },
    { name: Translations.QuoteDetails.billingFrequency, value: opportunityData?.billingFrequency },
    { name: Translations.QuoteDetails.salesRepresentative, value: opportunityData?.userName },
  ];

  return (
    <TngInfoCard
      fieldConfig={fieldConfig}
      isHeaderDetailsVisible={false}
      fieldDetailLabel={Translations.QuoteDetails.opportunityDetails}
    />
  );
};

export default OpportunityDetails;
