import { PartnerProgramGuideGrid } from '../../../common/interfaces/partnerProgramGuide';
import { PublicSectorGridModal } from '../modal/PublicSectorGridModal';

// to map the data according to grid columns
export const mapFileDetailsToFileGridModal = (
  files: PartnerProgramGuideGrid[],
): PublicSectorGridModal[] => {
  if (files.length === 0) return [];
  return files.map((file) => ({
    id: file.id,
    fileName: file.name,
    fileType: file.fileType,
  }));
};
