import React, { useRef, useState } from 'react';

import { CircularProgress } from '@mui/material'; // Assuming you're using Material-UI
import { SubmitHandler, useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';

import Translations from '../../../assets/locale/en/content.json';
import { useUserLogin } from '../../../common/hooks/useUserLogin';
import { InputFieldConfig } from '../../../common/interfaces/InputFieldConfig';
import { TngGrid, TngButton, TngTypography } from '../../../components/common';
import FieldRendering from '../../../components/CustomFormFields/FieldRendering';
import { focusFirstInteractiveElement } from '../../../utils/findInteractiveElement';
import { getCleanErrorMessage } from '../../../utils/getCleanErrorMessage';
import { FormError } from '../modals/FormError';
import { LoginFormType } from '../modals/LoginFormType';
import { isValidEmail, isValidPassword } from '../utils/FormValidations';

const fieldConfig: InputFieldConfig[] = [
  {
    label: Translations.Login.emailAddress,
    htmlFor: 'username',
    fieldType: 'text',
    placeholder: Translations.Login.enterEmailAddress,
    validationRules: { required: true, maxLength: 80 },
    hideAstrick: true,
  },
  {
    label: Translations.Login.password,
    htmlFor: 'password',
    fieldType: 'password',
    placeholder: Translations.Login.enterPassword,
    validationRules: { required: true },
  },
];

const LoginForm: React.FC = () => {
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [formError, setFormError] = useState<FormError>({ isError: false, message: '' });
  const errorRef = useRef<HTMLParagraphElement>(null);

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    setError,
    clearErrors,
  } = useForm<LoginFormType>();
  const { loginUser, loading } = useUserLogin();
  const navigate = useNavigate();

  const onSubmit: SubmitHandler<LoginFormType> = async (data: LoginFormType) => {
    try {
      if (isFormValid(data)) {
        // if form valid, clear all the errors first and call Ping API
        cleanFormErrors();
        const tokens = await loginUser(data);
        if (tokens) {
          navigate('/dashboard');
        }
      } else {
        // set client-side error
        setFromErrorsWithBorder(Translations.Login.loginClientError);
      }
      if (formError.isError && errorRef.current) {
        errorRef.current.focus(); // Move focus to error message when form error occurs
      }
      focusFirstInteractiveElement();
    } catch (err: any) {
      setFromErrorsWithBorder(getCleanErrorMessage(err));
    }
  };

  const isFormValid = (data: LoginFormType) => {
    const { username, password } = data;
    const isEmailValid = isValidEmail(username);
    const isPassValid = isValidPassword(password);
    if (isEmailValid && isPassValid) {
      return true;
    }
    return false;
  };

  const cleanFormErrors = () => {
    clearErrors('username');
    clearErrors('password');
    setFormError({ isError: false, message: '' });
  };

  const setFromErrorsWithBorder = (message: string) => {
    setFormError({ isError: true, message: message });
    setError('username', { type: 'invalid', message: '' });
    setError('password', { type: 'invalid', message: '' });
  };

  const IsFieldError = () => {
    if (Object.keys(errors).length === 0) {
      return false;
    }
    // check for if it contains error type invalid
    // for this we just displaying the border, no field error message - we need to display form level error
    let isFieldError = false;
    for (const [, error] of Object.entries(errors)) {
      if (error.type !== 'invalid') {
        isFieldError = true;
      }
    }
    return isFieldError;
  };

  return (
    <form aria-label="Login" className="login_form" onSubmit={handleSubmit(onSubmit)}>
      <TngGrid className="login_form_fields">
        <FieldRendering
          fieldRow={fieldConfig}
          showPassword={showPassword}
          register={register}
          errors={errors}
          setShowPassword={setShowPassword}
          control={control}
        />
      </TngGrid>
      {/* Forgot Password Link */}
      <TngGrid className="forgot_password" item>
        <Link to="/forgot-password" aria-label="Forgot password">
          Forgot password?
        </Link>
      </TngGrid>
      {/* Error messages for failed login */}
      {!IsFieldError() && formError.isError && (
        <TngGrid aria-live="assertive" role="alert">
          <TngTypography className="login_form_error" ref={errorRef}>
            {formError.message}
          </TngTypography>
        </TngGrid>
      )}
      <TngGrid className="_login_form_button">
        <TngButton
          type="submit"
          variant="contained"
          aria-label="Login"
          className="login_button"
          disabled={loading}
          startIcon={loading ? <CircularProgress size={20} color="inherit" /> : null}
        >
          {Translations.Login.login}
        </TngButton>
      </TngGrid>
    </form>
  );
};

export default LoginForm;
