import React, { useEffect } from 'react';

import { Filter } from './Filter/Filter';
import { GridActionEnum } from '../../../../common/enums/GridActionEnum';
import { useGridDispatch, useGridState } from '../../../../common/GridContext/GridContextProvider';
import { OrderHistoryGridUrl } from '../../../../common/interfaces/urls/OrderHistoryGridUrl';
import { TngGrid } from '../../../../components/common';
import TngAgGrid from '../../../../components/TngGridSection/TngAgGrid/TngAgGrid';
import { defaultUsersAPIParams } from '../gridHelper/apiParams';
import { getUsersGridConfig } from '../gridHelper/gridConfig';
import { mapContactsToGridModal } from '../gridHelper/mapperFunction';

const ManageUsersGrid = () => {
  const { gridData, search, pageNumber, pageSize, sorting, filters } = useGridState();
  const dispatch = useGridDispatch();

  //#temperory
  //here we are hard-coded the Id because right now we don't know what it should be and how we will get this
  const accountId: string = '001dy000000HRbhAAG';

  // check if gridData is present than called the mapper to map the data according to grid columns
  useEffect(() => {
    if (gridData) {
      const mappedData = mapContactsToGridModal(gridData);
      dispatch({ type: GridActionEnum.SET_GRID_MAPPED_DATA, payload: mappedData });
      dispatch({ type: GridActionEnum.SET_LOADER, payload: false });
    }
  }, [dispatch, gridData]);

  // create or get default api params
  const apiParams: OrderHistoryGridUrl = defaultUsersAPIParams({
    pageNumber,
    pageSize,
    search,
    sorting,
    filters,
  });

  const filterCount = (): number => {
    let count = 0;
    if (filters?.country && filters.country.length >= 0) {
      count += filters.country.length;
    }
    if (filters?.userTypes && filters.userTypes.length >= 0) {
      count += filters.userTypes.length;
    }
    return count;
  };

  return (
    <TngGrid className="manage_user_container">
      <TngAgGrid
        gridConfig={getUsersGridConfig(apiParams, accountId)}
        filterComponent={<Filter />}
        filterCount={filterCount()}
      />
    </TngGrid>
  );
};

export default ManageUsersGrid;
