import React, { useState, useEffect } from 'react';

import Translations from '../../../assets/locale/en/content.json';
import { TngList, TngListItem } from '../../../components/common';

type PasswordRulesProps = {
  passwordValue: string;
  confirmPasswordValue?: string;
  isPasswordRulesRender?: boolean;
};

const PasswordRules: React.FC<PasswordRulesProps> = ({
  passwordValue = '',
  confirmPasswordValue,
  isPasswordRulesRender = true,
}) => {
  const [passwordTouched, setPasswordTouched] = useState<boolean>(false);
  const [confirmPasswordTouched, setConfirmPasswordTouched] = useState<boolean>(false);

  useEffect(() => {
    if (passwordValue !== '') {
      setPasswordTouched(true);
    }
    if (confirmPasswordValue !== undefined && confirmPasswordValue !== '') {
      setConfirmPasswordTouched(true);
    }
  }, [passwordValue, confirmPasswordValue]);

  const isPasswordMatch = confirmPasswordValue ? passwordValue === confirmPasswordValue : false;

  const getClassForPasswordRule = (condition: boolean, touched: boolean) => {
    if (!touched) return 'untouched';
    return condition ? 'valid' : 'invalid';
  };

  return (
    <TngList>
      {/* Render these rules only if isPasswordRulesRender is true */}
      {isPasswordRulesRender && (
        <>
          <TngListItem
            className={getClassForPasswordRule(passwordValue.length >= 8, passwordTouched)}
          >
            <span className="circle circle_normal"></span>{' '}
            {Translations.ResetPassword.minLengthRule}
          </TngListItem>
          <TngListItem
            className={getClassForPasswordRule(
              /[A-Z]/.test(passwordValue) &&
                /[a-z]/.test(passwordValue) &&
                /[0-9]/.test(passwordValue) &&
                /[\W_]/.test(passwordValue),
              passwordTouched,
            )}
          >
            <span className="circle circle_special"></span>{' '}
            {Translations.ResetPassword.caseNumberSpecialCharRule}
          </TngListItem>
        </>
      )}

      {/* Render this rule only if confirmPasswordValue is provided */}
      {!isPasswordRulesRender && (
        <TngListItem
          className={getClassForPasswordRule(
            confirmPasswordValue === '' ? false : isPasswordMatch,
            confirmPasswordTouched,
          )}
        >
          <span className="circle circle_normal"></span>{' '}
          {Translations.ResetPassword.passwordsMatchRule}
        </TngListItem>
      )}
    </TngList>
  );
};

export default PasswordRules;
