import React from 'react';

import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import { useNavigate } from 'react-router-dom';

import { TngButton } from './TngButton';

interface BackNavigationProps {
  label: string;
  path: string;
}

const TngBackNavigation: React.FC<BackNavigationProps> = ({ label, path }) => {
  const navigate = useNavigate();
  return (
    <TngButton
      className="back_navigation_btn"
      startIcon={<KeyboardArrowLeftIcon />}
      onClick={() => navigate(path)}
    >
      {label}
    </TngButton>
  );
};

export default TngBackNavigation;
