import { isArrayEquals } from '../../../../utils/isArrayEquals';
import { ManageUserFilter } from '../modals/ManageUserFilter';
import { ManageUserFilterContext } from '../modals/ManageUserFilterContext';

export const initializeFilterState = (filters: ManageUserFilterContext) => {
  const initialFilter: ManageUserFilter = {
    country: filters.country || [],
    userTypes: filters.userTypes || [],
  };
  return initialFilter;
};

// Check if filters have been modified or not
export const isAnyFilterSelected = (filterObj: ManageUserFilter): boolean => {
  return filterObj.country.length > 0 || filterObj.userTypes.length > 0;
};

// Check if filters have been modified or not
export const checkIfManageUserFiltersChanged = (
  filterObj: ManageUserFilter,
  defaultFilterObj: ManageUserFilter,
): boolean => {
  // Check if any filters have changed by comparing with default values
  return (
    !isArrayEquals(filterObj.country, defaultFilterObj.country) ||
    !isArrayEquals(filterObj.userTypes, defaultFilterObj.userTypes)
  );
};
