/**
 * Converts a Blob object into a downloadable file and triggers its download in the browser.
 *
 * @param {Blob} fileBlob - The Blob object representing the file's data.
 * @param {string} fileName - The name of the file to be downloaded.
 *
 * This function creates a temporary anchor element, sets its href to the Blob URL,
 * and triggers a click event to initiate the download. The URL and link are cleaned up afterward.
 */

export const convertBlobToFile = (fileBlob: Blob, fileName: string) => {
  try {
    if (!fileBlob || !fileName || fileName.trim() === '') {
      throw new Error();
    }
    // Create a URL for the Blob object
    const url = window.URL.createObjectURL(fileBlob);

    // Create a temporary link element
    const link = document.createElement('a');
    link.href = url;

    // Set the file name for the download
    link.download = fileName; // Replace with the file name you want

    // Append the link to the document body (not displayed)
    document.body.appendChild(link);

    // Programmatically click the link to trigger the download
    link.click();

    // Clean up and remove the link
    link.remove();

    // Release the Blob object URL
    window.URL.revokeObjectURL(url);
  } catch (err) {
    throw new Error();
  }
};
