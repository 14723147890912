import { useQuery } from '@tanstack/react-query';

import { fetchStates } from '../../api/services/state/stateService';
import { State } from '../interfaces/State';

/**
Custom hook to fetch the states using React Query.
@returns {object} - An object containing states data, error, and loading state.
*/
export const useGetState = (isAuthenticated: boolean) => {
  const { data, error, isLoading } = useQuery<State[], Error>({
    queryKey: ['state'],
    queryFn: fetchStates,
    enabled: isAuthenticated,
  });
  return { data, error, isLoading };
};
