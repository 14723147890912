import { isArrayEquals } from '../../../utils/isArrayEquals';
import { TrainingCertificateFilter } from '../modal/TrainingCertificateFilter';
import { TrainingCertificationFilterContext } from '../modal/TrainingCertificateFilterContext';

export const initializeFilterState = (filters: TrainingCertificationFilterContext) => {
  const initialFilter: TrainingCertificateFilter = {
    contactIds: filters.contactIds || [],
  };
  return initialFilter;
};

// Check if filters have been modified or not
export const isAnyFilterSelected = (filterObj: TrainingCertificateFilter): boolean => {
  return filterObj.contactIds.length > 0;
};

// Check if filters have been modified or not
export const checkIfFiltersChanged = (
  filterObj: TrainingCertificateFilter,
  defaultFilterObj: TrainingCertificateFilter,
): boolean => {
  return !isArrayEquals(filterObj.contactIds, defaultFilterObj.contactIds);
};
