import Translations from '../../../assets/locale/en/content.json';
import { TransformDate } from '../../../utils/dateTimeConvertor';
import { QuoteGrid } from '../interfaces/QuoteGrid';
import { Quote } from '../interfaces/Quotes';

// to map the data according to grid columns
export const mapQuotesToGridModal = (quotes: Quote[]): QuoteGrid[] => {
  return quotes.map((quote) => ({
    id: quote.id,
    opportunityId: quote.opportunityId,
    name: quote.name,
    status: quote.quoteStatus,
    customerName: quote.customerName,
    quoteCreationDate: TransformDate(quote.quoteCreationDate),
    createdBy: quote.createdBy,
    opportunityName: quote.opportunityName,
    primary: quote.isPrimary ? Translations.common.yes : Translations.common.no,
  }));
};
