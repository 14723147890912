import React from 'react';

const EvalIcon = () => {
  return (
    <svg width="24" height="23" viewBox="0 0 24 23" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12 11.25L21.75 1.5" stroke="#00A0FB" strokeMiterlimit="10" strokeLinecap="square" />
      <path
        d="M19.5 3.75L22.875 7.125L19.875 10.125L16.5 6.75"
        stroke="#00A0FB"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <path
        d="M6.375 22.5C9.4816 22.5 12 19.9816 12 16.875C12 13.7684 9.4816 11.25 6.375 11.25C3.2684 11.25 0.75 13.7684 0.75 16.875C0.75 19.9816 3.2684 22.5 6.375 22.5Z"
        stroke="#00A0FB"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
    </svg>
  );
};

export default EvalIcon;
