import React from 'react';

import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import { useNavigate } from 'react-router-dom';

import Translations from '../../../assets/locale/en/content.json';
import { TngButton } from '../../../components/common';

const BackToLoginButton: React.FC = () => {
  const navigate = useNavigate();

  return (
    <TngButton
      className="navigation_btn"
      startIcon={<KeyboardArrowLeftIcon />}
      onClick={() => navigate('/')}
    >
      {Translations.ForgotPassword.backToLoginPage}
    </TngButton>
  );
};

export default BackToLoginButton;
