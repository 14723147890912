import React, { useEffect } from 'react';

import FieldContainer from './components/FieldContainer';
import GridTabs from './components/GridTabs';
import { OrderSummaryContextProvider } from './context/ContextProvider';
import Translations from '../../assets/locale/en/content.json';
import { TngGrid } from '../../components/common';
import TngBackNavigation from '../../components/common/TngBackNavigation';
import { focusFirstInteractiveElement } from '../../utils/findInteractiveElement';

const OrderSummary = () => {
  useEffect(() => {
    const mainDiv = document.getElementById('main-content');
    focusFirstInteractiveElement(mainDiv);
  }, []);
  return (
    <OrderSummaryContextProvider>
      <TngGrid className="order_summary">
        <TngBackNavigation
          label={Translations.common.back}
          path={Translations.navigation.orderHistory}
        />
        <TngGrid className="order_summary_details">
          <FieldContainer />
          <GridTabs />
        </TngGrid>
      </TngGrid>
    </OrderSummaryContextProvider>
  );
};

export default OrderSummary;
