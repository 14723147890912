import React from 'react';

import {
  FieldErrors,
  UseFormRegister,
  UseFormTrigger,
  UseFormWatch,
  RegisterOptions,
} from 'react-hook-form';

import { FormValues } from './ResetPasswordForm';
import Translations from '../../../assets/locale/en/content.json';
import { ResetPasswordInputFieldConfig } from '../../../common/interfaces/InputFieldConfig';
import {
  TngGrid,
  TngInputLabel,
  TngPasswordVisibleIcon,
  TngTextField,
} from '../../../components/common';

interface FieldRenderingProps {
  field: ResetPasswordInputFieldConfig;
  showPassword: boolean;
  showConfirmPassword: boolean;
  errors: FieldErrors<FormValues>;
  register: UseFormRegister<FormValues>;
  setShowPassword: React.Dispatch<React.SetStateAction<boolean>>;
  setShowConfirmPassword: React.Dispatch<React.SetStateAction<boolean>>;
  watch: UseFormWatch<FormValues>;
  trigger: UseFormTrigger<FormValues>;
}

const FieldRendering: React.FC<FieldRenderingProps> = ({
  field,
  showPassword,
  showConfirmPassword,
  errors,
  register,
  setShowPassword,
  setShowConfirmPassword,
  watch,
}) => {
  const passwordValue = watch('password');

  // Define the validation function
  const validateFunction = (value: string): string | boolean => {
    const passwordMismatchMessage = Translations.ResetPassword.passwordMismatchMessage;
    const passwordUpperLowerMessage = Translations.ResetPassword.passwordUpperLowerMessage;

    if (field.htmlFor === 'confirmPassword' && value !== passwordValue) {
      return passwordMismatchMessage;
    }

    if (field.htmlFor !== 'verificationCode') {
      const hasUppercase = /[A-Z]/.test(value);
      const hasLowercase = /[a-z]/.test(value);
      const hasNumber = /[0-9]/.test(value);
      const hasSpecialChar = /[@$!%*?&#.,(^)~\-_=+[\]{}|;:<>/]/.test(value);

      if (!(hasUppercase && hasLowercase && hasNumber && hasSpecialChar)) {
        return passwordUpperLowerMessage;
      }
    }

    return true;
  };

  // Define validation rules based on the field configuration
  const validateRules: RegisterOptions<FormValues> = {
    required: field.validationRules.required ? Translations.ResetPassword.fieldError : false,
    minLength: field.validationRules.minLength
      ? {
          value: field.validationRules.minLength,
          message: `${Translations.ResetPassword.passwordMinLengthMessage} ${field.validationRules.minLength} ${Translations.ResetPassword.characters}`,
        }
      : undefined,
    validate: field.htmlFor === 'verificationCode' ? undefined : validateFunction,
  };

  return (
    <TngGrid item key={field.label}>
      <TngInputLabel htmlFor={field.htmlFor}>{field.label}</TngInputLabel>
      <TngTextField
        id={field.htmlFor}
        aria-label={field.label}
        type={
          field.htmlFor === 'password'
            ? showPassword
              ? 'text'
              : field.type
            : field.htmlFor === 'confirmPassword'
              ? showConfirmPassword
                ? 'text'
                : field.type
              : field.type
        }
        placeholder={field.placeholder}
        fullWidth
        error={!!errors[field.htmlFor as keyof FormValues]}
        helperText={
          errors[field.htmlFor as keyof FormValues]?.message // Display the correct error message
        }
        FormHelperTextProps={{ role: 'alert' }}
        {...register(field.htmlFor as keyof FormValues, validateRules)}
        className={`login_input_field ${errors[field.htmlFor as keyof FormValues] ? 'login_input_field_error' : ''}`}
        InputProps={{
          endAdornment: (field.htmlFor === 'password' || field.htmlFor === 'confirmPassword') && (
            <TngPasswordVisibleIcon
              showPassword={field.htmlFor === 'password' ? showPassword : showConfirmPassword}
              setShowPassword={
                field.htmlFor === 'password' ? setShowPassword : setShowConfirmPassword
              }
            />
          ),
          inputProps: { maxLength: field.validationRules.maxLength },
        }}
      />
    </TngGrid>
  );
};

export default FieldRendering;
