import React, { useEffect } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import { Dialog, DialogActions, DialogContent, DialogContentText } from '@mui/material';

import { TngButton } from './TngButton';
import { TngDivider } from './TngDivider';
import { TngGrid } from './TngGrid';
import { TngIconButton } from './TngIconButton';
import { TngTypography } from './TngTypography';
import Translations from '../../assets/locale/en/content.json';
import { focusFirstInteractiveElement } from '../../utils/findInteractiveElement';

interface TngAlertBoxProps {
  open: boolean;
  title: string;
  message: string;
  onClose: () => void;
  onConfirm: () => void;
  cancelText?: string; // Add prop for cancel text
  confirmText?: string; // Add prop for confirm text
}

export const TngAlertBox: React.FC<TngAlertBoxProps> = ({
  open,
  title,
  message,
  onClose,
  onConfirm,
  cancelText = 'Cancel',
  confirmText = 'Confirm',
}) => {
  // this is used to set focus on close button
  useEffect(() => {
    if (open) {
      const handle = requestAnimationFrame(() => {
        const filterPanel = document.getElementById('logout_panel');
        if (filterPanel) {
          //focusFirstInteractiveElement is a function to focus the first interactive element
          focusFirstInteractiveElement(filterPanel);
        }
      });
      return () => cancelAnimationFrame(handle);
    }
  }, [open]);
  return (
    <Dialog
      open={open}
      onClose={onClose}
      className="alert_box_dialog"
      id="logout_panel"
      aria-describedby="logout_popup"
    >
      <DialogContent>
        <TngGrid container className="alert_box_close_container">
          <TngGrid item xs={12} className="alert_box_close_button">
            <TngIconButton
              disableRipple
              disableTouchRipple
              aria-label={Translations.ariaLabels.closeLogout}
              onClick={onClose}
            >
              {Translations.logOut.close} <CloseIcon />
            </TngIconButton>
          </TngGrid>
        </TngGrid>
        <TngGrid container className="alert_box_content">
          <TngGrid item xs={12} className="alert_box_title">
            <TngTypography id="logout_popup" variant="body1">
              {title}
            </TngTypography>
          </TngGrid>
          <TngDivider />
          <TngGrid item xs={12} className="alert_box_content_text">
            <DialogContentText>{message}</DialogContentText>
          </TngGrid>
        </TngGrid>
      </DialogContent>
      <DialogActions>
        <TngGrid container className="alert_box_button_container">
          <TngGrid item className="alert_box_primary_button">
            <TngButton onClick={onClose} color="primary">
              {cancelText}
            </TngButton>
          </TngGrid>
          <TngGrid item className="alert_box_confirm_button">
            <TngButton onClick={onConfirm} color="primary">
              {confirmText}
            </TngButton>
          </TngGrid>
        </TngGrid>
      </DialogActions>
    </Dialog>
  );
};
