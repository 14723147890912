import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { ColDef, GridReadyEvent, SortChangedEvent } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';

import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

import { GridActionEnum } from '../../../../../common/enums/GridActionEnum';
import { Sorting } from '../../../../../common/enums/Sorting';
import {
  useGridDispatch,
  useGridState,
} from '../../../../../common/GridContext/GridContextProvider';
import useAriaLiveMessage from '../../../../../common/hooks/useAriaLiveMessage';
import { ColumnDef } from '../../../../../common/interfaces/ColumnDef';
import { GridMoreOptionsConfig } from '../../../../../common/interfaces/GridMoreOptionsConfig';
import { GridSortingConfig } from '../../../../../common/interfaces/GridSortingConfig';
import { TngGrid } from '../../../../common';
import TngLiveRegionS508 from '../../../../common/TngLiveRegionS508';
import {
  CustomLoadingOverlay,
  CustomNoRowsOverlay,
} from '../../../common/components/CustomCellRender';
import { applySortStyles } from '../../../common/utils/applySortStyle';
import { suppressKeyboardEvent } from '../../../common/utils/gridKeyboardNavigation';
import { TransformColumnHeader } from '../../../common/utils/transformColumnHeader';

interface TngDataGridProps {
  ColumnHeader: ColumnDef[];
  sortingConfig: GridSortingConfig;
  moreOptions: GridMoreOptionsConfig;
  columnWidth: number;
  rowHeight: number;
}

const TngDataGrid = ({
  ColumnHeader,
  sortingConfig,
  moreOptions,
  columnWidth,
  rowHeight,
}: TngDataGridProps) => {
  const [columnDef, setColumnDef] = useState<ColDef[]>([]);
  const { gridMappedData, loading, sorting, gridApi, isFilterApplied, isSearchApplied } =
    useGridState();

  const dispatch = useGridDispatch();

  const [ariaLiveMessage, setAriaLiveMessage] = useState<string | null>(null);

  // custom hook for s508
  useAriaLiveMessage({ gridData: gridMappedData, loading: loading, setAriaLiveMessage });

  useEffect(() => {
    if (gridApi) applySortStyles(sorting.colId);
  }, [sorting, gridApi]);

  //transform the column header according to the ag-grid ColDef modal
  useEffect(() => {
    const transformedColumnHeader = TransformColumnHeader({
      ColumnHeader,
      moreOptions,
      isSortingApplicable: sortingConfig.isApplicable,
    });

    const sortedColumnsInfo = transformedColumnHeader.map((col) => {
      const { sort, field, ...rest } = col;
      return { updatedColumns: { field, ...rest }, defaultHeader: sort ? field : '', sort };
    });

    const updatedColumns = sortedColumnsInfo.map(({ updatedColumns }) => updatedColumns);
    const defaultHeader = sortedColumnsInfo.find(
      ({ defaultHeader }) => defaultHeader,
    )?.defaultHeader;

    // Extracting the sort value
    const sortValue = sortedColumnsInfo.find(({ sort }) => sort)?.sort;
    if (
      (defaultHeader !== sorting.colId && sorting.colId) ||
      sorting.sortOrder.toLowerCase() !== sortValue?.toLowerCase()
    ) {
      setColumnDef(updatedColumns);
    } else {
      setColumnDef(transformedColumnHeader);
    }
  }, [ColumnHeader, moreOptions, sortingConfig.isApplicable, sorting.colId, sorting.sortOrder]);

  const onGridReady = useCallback(
    (params: GridReadyEvent) => {
      params.api.sizeColumnsToFit();
      dispatch({ type: GridActionEnum.SET_GRID_API, payload: params.api });
    },
    [dispatch, sortingConfig],
  );

  const onSortChanged = useCallback(
    (event: SortChangedEvent) => {
      const sortedColumns = event.columnApi.getAllGridColumns()?.filter((col) => col.getSort());

      sortedColumns?.forEach((col) => {
        const colId = col.getColId();
        const sort = col.getSort() as Sorting;

        if (sort) {
          dispatch({
            type: GridActionEnum.SET_SORTING,
            payload: { colId, sortOrder: sort },
          });
        }
      });
      dispatch({ type: GridActionEnum.SET_PAGE_NUMBER, payload: 1 });
      dispatch({ type: GridActionEnum.SET_IS_SORTING_APPLIED, payload: true });
    },
    [dispatch],
  );

  const noRowsOverlayComponentParams = useMemo(
    () => ({ isSearchedOrFiltered: isFilterApplied || isSearchApplied }),
    [isFilterApplied, isSearchApplied],
  );

  const gridOptions = useMemo(
    () => ({
      defaultColDef: {
        suppressMovable: true,
        minWidth: columnWidth,
        resizable: true,
        sortable: false,
        flex: 1,
        suppressKeyboardEvent,
        comparator: () => {
          return 0;
        },
      },
      reactiveCustomComponents: true,
      headerHeight: 45,
      loadingOverlayComponent: CustomLoadingOverlay,
    }),
    [columnWidth],
  );

  return (
    <TngGrid className="ag-theme-alpine ag_grid_container" data-testid="data_grid">
      {/* ARIA Live Region */}
      <TngLiveRegionS508 message={ariaLiveMessage} />
      <AgGridReact
        rowData={loading ? null : gridMappedData}
        columnDefs={columnDef}
        gridOptions={gridOptions}
        suppressClickEdit={true}
        onGridReady={onGridReady}
        suppressRowHoverHighlight={true}
        getRowHeight={() => rowHeight}
        onFirstDataRendered={applySortStyles.bind(null, sorting.colId)}
        onSortChanged={onSortChanged}
        tooltipShowDelay={0}
        noRowsOverlayComponent={CustomNoRowsOverlay}
        noRowsOverlayComponentParams={noRowsOverlayComponentParams}
      />
    </TngGrid>
  );
};

export default TngDataGrid;
