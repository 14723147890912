import React from 'react';

const PDFIcon = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.6668 9.00065H1.3335L0.333496 7.66732L1.66683 6.33398H14.3335L15.6668 7.66732L14.6668 9.00065Z"
        fill="#870000"
      />
      <path
        d="M13.667 15.6673H2.33366C1.96533 15.6673 1.66699 15.369 1.66699 15.0007V1.00065C1.66699 0.632318 1.96533 0.333984 2.33366 0.333984H10.3337L14.3337 4.33398V15.0007C14.3337 15.369 14.0353 15.6673 13.667 15.6673Z"
        fill="#E6E6E6"
      />
      <path
        d="M10.3335 0.333984V3.66732C10.3335 4.03565 10.6318 4.33398 11.0002 4.33398H14.3335L10.3335 0.333984Z"
        fill="#B3B3B3"
      />
      <path
        d="M15.0002 13.668H1.00016C0.631829 13.668 0.333496 13.3696 0.333496 13.0013V7.66797H15.6668V13.0013C15.6668 13.3696 15.3685 13.668 15.0002 13.668Z"
        fill="#BD0100"
      />
      <path
        d="M6.69068 10.2155C6.69068 10.5081 6.60435 10.7341 6.43202 10.8931C6.25935 11.0528 6.01468 11.1321 5.69702 11.1321H5.49835V12.0005H4.79102V9.38281H5.69702C6.02768 9.38281 6.27602 9.45515 6.44168 9.59915C6.60768 9.74381 6.69068 9.94948 6.69068 10.2155ZM5.49835 10.5558H5.62735C5.73368 10.5558 5.81802 10.5258 5.88068 10.4658C5.94335 10.4061 5.97468 10.3238 5.97468 10.2191C5.97468 10.0425 5.87702 9.95415 5.68102 9.95415H5.49835V10.5558Z"
        fill="white"
      />
      <path
        d="M9.32942 10.6361C9.32942 11.0731 9.20909 11.4095 8.96842 11.6458C8.72809 11.8825 8.39009 12.0005 7.95442 12.0005H7.10742V9.38281H8.01342C8.43342 9.38281 8.75775 9.49015 8.98642 9.70515C9.21509 9.92015 9.32942 10.2301 9.32942 10.6361ZM8.59542 10.6611C8.59542 10.4211 8.54809 10.2435 8.45309 10.1275C8.35842 10.0115 8.21409 9.95381 8.02076 9.95381H7.81476V11.4198H7.97242C8.18742 11.4198 8.34475 11.3571 8.44509 11.2325C8.54542 11.1078 8.59542 10.9178 8.59542 10.6611Z"
        fill="white"
      />
      <path
        d="M10.4947 12.0005H9.79834V9.38281H11.3453V9.95015H10.495V10.4498H11.2793V11.0171H10.495V12.0005H10.4947Z"
        fill="white"
      />
    </svg>
  );
};

export default PDFIcon;
