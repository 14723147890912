import React, { useState, useEffect } from 'react';

import BackToLoginButton from './components/BackToLoginButton';
import ResetPasswordForm from './components/ResetPasswordForm';
import ResetPasswordHeader from './components/ResetPasswordHeader';
import ResetPasswordMessage from './components/ResetPasswordMessage';
import useFetchOTP from '../../common/hooks/useFetchOtp';
import { TngGrid } from '../../components/common';
import { focusFirstInteractiveElement } from '../../utils/findInteractiveElement';

const ResetPassword: React.FC = () => {
  const [timer, setTimer] = useState(30);
  const [resendEnabled, setResendEnabled] = useState(true);

  const searchParams = new URLSearchParams(location.search);
  const username = searchParams.get('username') || '';
  const { fetchOTP } = useFetchOTP();

  useEffect(() => {
    let interval: NodeJS.Timeout | null = null;

    if (timer > 0 && !resendEnabled) {
      interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    } else if (timer === 0) {
      setResendEnabled(true);
    }

    focusFirstInteractiveElement();
    return () => {
      if (interval) clearInterval(interval);
    };
  }, [timer, resendEnabled]);

  const handleResendCode = async () => {
    try {
      setResendEnabled(false);
      setTimer(30);
      await fetchOTP(username);
    } catch (error) {
      // Optionally, show a user-friendly error message
      setResendEnabled(true); // Re-enable button if fetching OTP fails
    }
  };

  return (
    <>
      <TngGrid container className="reset_password_parent_container">
        <ResetPasswordHeader />
        <TngGrid container className="reset_password_children_container">
          <BackToLoginButton />
          <ResetPasswordMessage username={username} />
          <ResetPasswordForm
            username={username}
            timer={timer}
            setTimer={setTimer}
            resendEnabled={resendEnabled}
            setResendEnabled={setResendEnabled}
            handleResendCode={handleResendCode}
          />
        </TngGrid>
      </TngGrid>
    </>
  );
};

export default ResetPassword;
