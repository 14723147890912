import React from 'react';

import { FormControl, FormHelperText } from '@mui/material';
import { Control, Controller, FieldErrors, FieldValues, Path } from 'react-hook-form';

import Translations from '../../assets/locale/en/content.json';
import { InputFieldConfig } from '../../common/interfaces/InputFieldConfig';
import { TngGrid, TngInputLabel, TngMenuItem, TngSelect } from '../common';

interface DropdownFieldProps<TFormField extends FieldValues> {
  field: InputFieldConfig;
  errors: FieldErrors<TFormField>;
  control: Control<TFormField>;
  dropdownCallback?: ((field: InputFieldConfig, e: any) => void) | undefined;
}

const DropdownField = <TFormField extends FieldValues>({
  field,
  errors,
  control,
  dropdownCallback,
}: DropdownFieldProps<TFormField>) => {
  const isError = !!errors[field.htmlFor as Path<TFormField>];

  return (
    <TngGrid item key={field.label} className="form_field">
      <TngInputLabel
        id={`${field.htmlFor}-label`}
        className="form_field_input_label"
        aria-label={
          field.validationRules.required
            ? `${field.label} ${Translations.ariaLabels.mandatoryField}`
            : field.label
        }
      >
        {field.validationRules.required ? field.label + ' *' : field.label}
      </TngInputLabel>

      <Controller
        name={field.htmlFor as Path<TFormField>}
        control={control}
        rules={field.validationRules}
        render={({ field: { value, onChange } }) => (
          <FormControl fullWidth error={isError}>
            <TngSelect
              id={field.htmlFor}
              labelId={`${field.htmlFor}-label`}
              variant="outlined"
              value={value || ''}
              displayEmpty
              onChange={(e) => {
                onChange(e); // Update react-hook-form
                dropdownCallback?.(field, e);
              }}
              renderValue={(selected) => {
                if (!selected) {
                  return field.placeholder;
                }
                const selectedOption = field.options?.find((option) => option.value === selected);
                return selectedOption ? selectedOption.label : (selected as string);
              }}
              className={`form_input_field select_field ${
                isError ? 'form_input_field_error select_field_error' : ''
              }`}
              disabled={field.validationRules?.disabled}
            >
              <TngMenuItem value="" disabled>
                {field.placeholder}
              </TngMenuItem>
              {field.options?.map((option) => (
                <TngMenuItem key={option.value} value={option.value}>
                  {option.label}
                </TngMenuItem>
              ))}
            </TngSelect>

            {isError && (
              <FormHelperText>
                <div aria-live="assertive" role="alert">
                  {Translations.AccountSettingsProfile.fieldError}
                </div>
              </FormHelperText>
            )}
          </FormControl>
        )}
      />
    </TngGrid>
  );
};

export default DropdownField;
