import { getColumnHeaders } from './columnDetails';
import { getOrderHistoryUrl } from '../../../api/urlGenerator';
import Translations from '../../../assets/locale/en/content.json';
import { GridMoreOptionsEnum } from '../../../common/enums/GridMoreOptionsEnum';
import { Sorting } from '../../../common/enums/Sorting';
import { FindGridConfig } from '../../../common/interfaces/FindGridConfig';
import { SearchDropdown } from '../../../common/interfaces/SearchDropdown';
import { StatusColors } from '../../../common/interfaces/StatusColor';
import { OrderHistoryGridUrl } from '../../../common/interfaces/urls/OrderHistoryGridUrl';

// grid config contains the details about grid which will render for this screen
export const getGridConfig = (
  apiParams: OrderHistoryGridUrl,
  statusColors: StatusColors,
): FindGridConfig => ({
  baseUrl: getOrderHistoryUrl(apiParams),
  navigateToRoute: '/order-summary',
  navigateRouteParams: 'orderId',
  ColumnHeader: getColumnHeaders(statusColors),
  search: {
    isApplicable: true,
    defaultSearch: { searchBy: 'PONum', searchValue: '' },
    isDropdownApplicable: true,
    searchDropdownOptions: dropDownValues,
    placeholder: Translations.orderHistory.searchHereFor3CharLimit,
  },
  sorting: {
    isApplicable: true,
    defaultSorting: { colId: 'PODateFrom', sortOrder: Sorting.DESC },
  },
  moreOptions: {
    isApplicable: true,
    moreOptionNavigation: [
      {
        label: Translations.orderHistory.viewMoreDetail,
        navigationTo: 'order-summary',
        navigationProps: ['orderId'],
        eventType: GridMoreOptionsEnum.VIEW,
      },
    ],
  },
  rowHeight: 75,
  columnWidth: 160,
  isPaginationVisible: true,
});

export const dropDownValues: SearchDropdown[] = [
  { label: 'PONum', value: Translations.orderHistory.poNumberComplete },
  { label: 'QuoteId', value: Translations.orderHistory.quoteId },
  { label: 'ConNum', value: Translations.orderHistory.contractNumberComplete },
];
