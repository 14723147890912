import React, { useEffect } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import { Drawer, IconButton, Typography } from '@mui/material';

import Translations from '../../../../../assets/locale/en/content.json';
import { focusFirstInteractiveElement } from '../../../../../utils/findInteractiveElement';
import { TngDivider, TngGrid, TngTypography } from '../../../../common';

interface FilterPanelProps {
  open: boolean;
  onClose: () => void;
  filterComponent: React.ReactElement;
}

const TngFilterPanel: React.FC<FilterPanelProps> = ({ open, onClose, filterComponent }) => {
  // this is used to set focus on close button
  useEffect(() => {
    if (open) {
      const handle = requestAnimationFrame(() => {
        const filterPanel = document.getElementById('filter_panel');
        if (filterPanel) {
          //focusFirstInteractiveElement is a function to focus the first interactive element
          focusFirstInteractiveElement(filterPanel);
        }
      });
      return () => cancelAnimationFrame(handle);
    }
  }, [open]);

  return (
    <Drawer anchor="right" open={open} onClose={onClose} className="filter_drawer">
      <TngGrid
        width="89%"
        margin="0 auto"
        marginTop="32px"
        height="100%"
        display="flex"
        flexDirection="column"
        id="filter_panel"
      >
        <TngGrid direction="row" alignItems="center">
          <TngGrid item className="drawer_close_button">
            <IconButton
              edge="start"
              disableRipple
              disableTouchRipple
              onClick={onClose}
              aria-label={Translations.ariaLabels.closeFilter}
            >
              <CloseIcon sx={{ color: '#00A0FB' }} />
              <Typography variant="body1" sx={{ marginLeft: '8px' }}>
                {Translations.filterSection.closeDrawer}
              </Typography>
            </IconButton>
          </TngGrid>
        </TngGrid>

        <TngDivider orientation="horizontal" variant="middle" flexItem />

        <TngGrid>
          <TngGrid item className="drawer_filter_heading">
            <TngTypography variant="h5" id="filter-panel-heading">
              {Translations.filterSection.filter}
            </TngTypography>
          </TngGrid>
        </TngGrid>

        {filterComponent}
      </TngGrid>
    </Drawer>
  );
};

export default TngFilterPanel;
