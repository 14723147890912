/**

Mapped the userType data which we get from api to userType dropdown options
@parameter {userTypes} - List of Usertypes.
@returns UserTypeOptions[] - list of usertypes Options for dropdown
*/

import { UserTypes, UserTypeOptions } from '../../../../common/interfaces/user/UserTypes';

export const userTypeMapper = (userTypes: UserTypes[]): UserTypeOptions[] => {
  return userTypes?.map((userType, index) => ({
    id: index,
    label: userType.label,
    value: userType.value,
  }));
};
