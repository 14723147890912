import React from 'react';

import { useNavigate } from 'react-router-dom';

import { TngButton } from './TngButton';
import { TngGrid } from './TngGrid';
import { ButtonProps } from '../../pages/NewQuoteCreation/modals/ButtonProps';

interface BottomPanelProps {
  prevButtonProps: ButtonProps;
  nextButtonProps: ButtonProps;
  setConfigStep: (step: number) => void; // Pass the setConfigStep function as a prop
  currentStep: number; // Receive the current step for additional logic if needed
  navigateToRoute: string;
}

const TngBottomPanel: React.FC<BottomPanelProps> = ({
  prevButtonProps,
  nextButtonProps,
  setConfigStep,
  currentStep,
  navigateToRoute,
}) => {
  const navigate = useNavigate();

  const handlePrevClick = () => {
    if (currentStep <= 0 && navigateToRoute) navigate(navigateToRoute);
    else if (currentStep > 0) setConfigStep(currentStep - 1); // Go to the previous step
  };

  const handleNextClick = () => {
    setConfigStep(currentStep + 1); // Go to the next step
  };

  return (
    <TngGrid container className="bottom_panel_buttons">
      <TngGrid className="bottom_panel_prev_button">
        <TngButton onClick={handlePrevClick} {...prevButtonProps}>
          {prevButtonProps.value}
        </TngButton>
      </TngGrid>
      <TngGrid className="bottom_panel_next_button">
        <TngButton onClick={handleNextClick} {...nextButtonProps}>
          {nextButtonProps.value}
        </TngButton>
      </TngGrid>
    </TngGrid>
  );
};

export default TngBottomPanel;
