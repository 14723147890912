import LoginForm from './components/LoginForm';
import LoginFormText from './components/LoginFormText';
import { TngGrid } from '../../components/common';

const Login = () => {
  return (
    <TngGrid className="login_container">
      <TngGrid className="login_form_wrapper">
        <LoginFormText />
        <LoginForm />
      </TngGrid>
    </TngGrid>
  );
};

export default Login;
