/**
 * Custom React Query hook to fetch the global price list for a given account.
 * Utilizes React Query's `useQuery` to manage the fetch state (loading, error, and data).
 *
 * @param {string} accountId - The account ID for which to fetch the global price list.
 * @returns {Object} - Contains `data`, `error`, and `isLoading` state of the query.
 */

import { useQuery } from '@tanstack/react-query';

import { fetchGlobalPriceList } from '../../../api/services/globalPriceList/globalPriceListService';
import { GlobalPriceList } from '../../../common/interfaces/globalPriceList/GlobalPriceList';

export const useGetGlobalPriceList = (accountId: string) => {
  const queryKey = 'getGlobalPriceList';
  const { data, error, isLoading } = useQuery<GlobalPriceList[], Error>({
    queryKey: [queryKey],
    queryFn: () => fetchGlobalPriceList(accountId),
  });
  return { data, error, isLoading };
};
