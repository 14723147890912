import React from 'react';

import { FieldConfig } from './TngInfoCardDetails';
import { TngGrid, TngTypography } from '../../../components/common';

const TngInfoCardWrapper: React.FC<FieldConfig> = ({ name, value }) => {
  return (
    <TngGrid className="field_value_wrapper">
      <TngTypography className="field_name">{name}</TngTypography>
      <TngTypography className="field_value">{value}</TngTypography>
    </TngGrid>
  );
};

export default TngInfoCardWrapper;
