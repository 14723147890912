import { TngGrid } from '../common';

interface TabPanelProps {
  children?: React.ReactNode | React.ReactElement;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <TngGrid
      item
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      className={`custom_tab_panel_children ${value !== index && 'panel_children_disabled'}`}
    >
      {value === index && children}
    </TngGrid>
  );
}

export default CustomTabPanel;
