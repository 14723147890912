import React from 'react';

import PartnerProfileGridContainer from './components/PartnerProfileGridContainer';
import PartnerProgramPriceList from './components/PartnerProgramPriceList';
import Translations from '../../assets/locale/en/content.json';
import useFocusOnFirstElement from '../../common/hooks/useFocusOnFirstElement';
import { TngGrid, TngTypography } from '../../components/common';
import TngBackNavigation from '../../components/common/TngBackNavigation';

const PartnerProgramPrice: React.FC = () => {
  // custom hook to focus on first interactive element
  useFocusOnFirstElement('partner_program_price_list');
  return (
    <TngGrid
      container
      className="partner_program_price_list_parent_container"
      id="partner_program_price_list"
    >
      <TngGrid container className="partner_program_price_list_child_container">
        <TngGrid item xs={12}>
          <TngBackNavigation
            label={Translations.trainingCertifications.backToDashboard}
            path={Translations.navigation.dashboard}
          />
        </TngGrid>
        <TngGrid item xs={12} className="partner_program_price_list_main_heading">
          <TngTypography variant="h3">
            {Translations.PartnerProgram.partnerProgramPriceList}
          </TngTypography>
        </TngGrid>
        <TngGrid item xs={12} className="partner_program_price_list_tab_container">
          <PartnerProgramPriceList />
          <PartnerProfileGridContainer />
        </TngGrid>
      </TngGrid>
    </TngGrid>
  );
};

export default PartnerProgramPrice;
