import React from 'react';

import Translations from '../../../assets/locale/en/content.json';
import { useQuotesStatus } from '../../../common/hooks/useQuotesStatus';
import { QuoteModel } from '../../../common/interfaces/quotes/QuoteDetailsResponse';
import TngInfoCard from '../../../components/TngInfoCard';
import { HeaderDetails } from '../../../components/TngInfoCard/components/TngInfoCardHeader';
import { TransformDate } from '../../../utils/dateTimeConvertor';

interface QuoteDetailsProps {
  data: QuoteModel;
}

const QuoteDetails: React.FC<QuoteDetailsProps> = ({ data: quoteData }) => {
  const { data: quoteStatuses = [] } = useQuotesStatus({ fetchStatuses: true });

  const headerDetails: HeaderDetails = {
    fieldName: Translations.orderHistory.quoteId,
    fieldValue: quoteData.name,
    fieldStatus: quoteData.quoteStatus,
  };

  const fieldConfig = [
    { name: Translations.QuoteDetails.customerName, value: quoteData.customerName },
    { name: Translations.QuoteDetails.currencyISOCode, value: quoteData.currencyIsoCode },
    {
      name: Translations.QuoteDetails.quoteCreationDate,
      value: TransformDate(quoteData.quoteCreationDate),
    },
    {
      name: Translations.QuoteDetails.quoteValidityDate,
      value: TransformDate(quoteData.quoteValidityDate),
    },
    { name: Translations.QuoteDetails.quoteLanguage, value: quoteData.quoteLanguage },
    { name: Translations.QuoteDetails.primary, value: quoteData.isPrimary.toString() },
    { name: Translations.QuoteDetails.createdBy, value: quoteData.createdBy },
    { name: Translations.QuoteDetails.yourReference, value: quoteData.yourReference },
  ];

  const dropdownOptions = [
    { label: Translations.QuoteDetails.downloadPDF, value: Translations.QuoteDetails.downloadPDF },
    { label: Translations.QuoteDetails.cancelQuote, value: Translations.QuoteDetails.cancelQuote },
  ];

  return (
    <TngInfoCard
      fieldConfig={fieldConfig}
      headerDetails={headerDetails}
      statusColors={quoteStatuses}
      dropdownOptions={dropdownOptions}
      fieldDetailLabel={Translations.QuoteDetails.quoteDetails}
    />
  );
};

export default QuoteDetails;
