import CaseManagementIcon from '../assets/icons/CaseManagementIcon';
import CommunityForumIcon from '../assets/icons/CommunityForumIcon';
import DashboardIcon from '../assets/icons/DashboardIcon';
import EvalIcon from '../assets/icons/EvalIcon';
import HighSpotIcon from '../assets/icons/HighSpotIcon';
import KnowledgeCenterIcon from '../assets/icons/KnowledgeCenterIcon';
import ManageQuotesIcon from '../assets/icons/ManageQuotesIcon';
import ManageRenewalsIcon from '../assets/icons/ManageRenewalsIcon';
import ManageUsersIcon from '../assets/icons/ManageUsersIcon';
import OrderIcon from '../assets/icons/OrderIcon';
import { IconMapModal } from '../common/interfaces/IconMapModal';

// define all icons with key
const iconMap: IconMapModal = {
  dashboardIcon: DashboardIcon,
  orderIcon: OrderIcon,
  manageQuotesIcon: ManageQuotesIcon,
  manageRenewalsIcon: ManageRenewalsIcon,
  evalIcon: EvalIcon,
  manageUsersIcon: ManageUsersIcon,
  knowledgeCenterIcon: KnowledgeCenterIcon,
  communityForumIcon: CommunityForumIcon,
  highSpotIcon: HighSpotIcon,
  caseManagementIcon: CaseManagementIcon,
};

export const getIconByText = (icon: string) => {
  return iconMap[icon];
};
