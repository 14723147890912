import React, { useEffect } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import { Drawer } from '@mui/material';
import { ToastContainer } from 'react-toastify';

import AccountContactForm from './AccountContactForm';
import Translations from '../../../../../assets/locale/en/content.json';
import {
  TngDivider,
  TngGrid,
  TngIconButton,
  TngTypography,
} from '../../../../../components/common';
import { focusFirstInteractiveElement } from '../../../../../utils/findInteractiveElement';

interface EditFormDrawerProps {
  open: boolean;
  onClose: () => void;
}

const EditFormDrawer: React.FC<EditFormDrawerProps> = ({ open, onClose }) => {
  useEffect(() => {
    if (open) {
      const handle = requestAnimationFrame(() => {
        const filterPanel = document.getElementById('edit_user_panel');
        if (filterPanel) {
          //focusFirstInteractiveElement is a function to focus the first interactive element
          focusFirstInteractiveElement(filterPanel);
        }
      });
      return () => cancelAnimationFrame(handle);
    }
  }, [open]);
  return (
    <Drawer anchor="right" open={open} onClose={onClose} className="edit_user_drawer">
      <TngGrid
        width="89%"
        margin="0 auto"
        marginTop="32px"
        height="100%"
        display="flex"
        flexDirection="column"
        id="edit_user_panel"
      >
        <TngGrid direction="row" alignItems="center">
          <TngGrid item className="drawer_close_button">
            <TngIconButton
              edge="start"
              disableRipple
              disableTouchRipple
              data-testid="close_drawer_btn"
              onClick={onClose}
              aria-label={Translations.ariaLabels.closeEditUserDetails}
            >
              <CloseIcon sx={{ color: '#00A0FB' }} />
              <TngTypography variant="body1" sx={{ marginLeft: '8px' }}>
                {Translations.filterSection.closeDrawer}
              </TngTypography>
            </TngIconButton>
          </TngGrid>
        </TngGrid>
        <TngDivider orientation="horizontal" variant="middle" flexItem />
        <TngGrid className="edit_user_header_container">
          <TngGrid item className="edit_user_panel_heading">
            <TngTypography variant="h5" id="edit_user-panel-heading">
              {Translations.ManageUsers.editUserDetails}
            </TngTypography>
          </TngGrid>
          <TngGrid item className="edit_user_panel_helper_text">
            <TngTypography id="edit_user-panel-helper-text">
              {Translations.ManageUsers.editUserFormHelperText}
            </TngTypography>
          </TngGrid>
        </TngGrid>
        <AccountContactForm onClose={onClose} />
      </TngGrid>
      <ToastContainer containerId="drawer" />
    </Drawer>
  );
};

export default EditFormDrawer;
