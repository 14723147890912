import React from 'react';

const ExcelIcon = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.6668 9.00065H1.3335L0.333496 7.66732L1.66683 6.33398H14.3335L15.6668 7.66732L14.6668 9.00065Z"
        fill="#0E512B"
      />
      <path
        d="M13.667 15.6673H2.33366C1.96533 15.6673 1.66699 15.369 1.66699 15.0007V1.00065C1.66699 0.632318 1.96533 0.333984 2.33366 0.333984H10.3337L14.3337 4.33398V15.0007C14.3337 15.369 14.0353 15.6673 13.667 15.6673Z"
        fill="#E6E6E6"
      />
      <path
        d="M10.3335 0.333984V3.66732C10.3335 4.03565 10.6318 4.33398 11.0002 4.33398H14.3335L10.3335 0.333984Z"
        fill="#B3B3B3"
      />
      <path
        d="M15.0002 13.668H1.00016C0.631829 13.668 0.333496 13.3696 0.333496 13.0013V7.66797H15.6668V13.0013C15.6668 13.3696 15.3685 13.668 15.0002 13.668Z"
        fill="#1B7343"
      />
      <path
        d="M5.86414 12.0005H5.0458L4.53547 11.1821L4.0308 12.0005H3.23047L4.0988 10.6611L3.28247 9.38281H4.0668L4.53947 10.1921L4.99414 9.38281H5.80147L4.97247 10.7165L5.86414 12.0005Z"
        fill="white"
      />
      <path d="M6.15771 12.0005V9.38281H6.86505V11.4295H7.87305V12.0005H6.15771Z" fill="white" />
      <path
        d="M10.0033 11.2057C10.0033 11.368 9.96192 11.512 9.87959 11.638C9.79726 11.764 9.67825 11.862 9.52325 11.9317C9.36825 12.0017 9.18592 12.0364 8.97725 12.0364C8.80292 12.0364 8.65692 12.024 8.53859 11.9997C8.42025 11.9754 8.29759 11.9327 8.16992 11.8717V11.2414C8.30492 11.3107 8.44492 11.3647 8.59059 11.4034C8.73625 11.442 8.86992 11.4614 8.99159 11.4614C9.09659 11.4614 9.17359 11.4434 9.22259 11.407C9.27159 11.3707 9.29592 11.3237 9.29592 11.2664C9.29592 11.2307 9.28592 11.1994 9.26659 11.1724C9.24659 11.1457 9.21525 11.1184 9.17159 11.091C9.12825 11.0637 9.01192 11.0074 8.82359 10.9227C8.65292 10.8454 8.52492 10.77 8.43959 10.697C8.35426 10.624 8.29092 10.5407 8.24992 10.4464C8.20859 10.352 8.18792 10.2407 8.18792 10.1117C8.18792 9.87037 8.27559 9.68237 8.45126 9.5477C8.62692 9.41304 8.86792 9.3457 9.17459 9.3457C9.44559 9.3457 9.72192 9.40837 10.0036 9.53337L9.78692 10.0794C9.54226 9.96737 9.33092 9.91137 9.15325 9.91137C9.06125 9.91137 8.99459 9.92737 8.95259 9.9597C8.91059 9.99204 8.88992 10.032 8.88992 10.0794C8.88992 10.1307 8.91659 10.1767 8.96959 10.2174C9.02259 10.258 9.16659 10.332 9.40192 10.4394C9.62759 10.541 9.78392 10.6497 9.87192 10.766C9.95925 10.8827 10.0033 11.029 10.0033 11.2057Z"
        fill="white"
      />
      <path
        d="M12.766 12.0005H11.9477L11.4373 11.1821L10.9327 12.0005H10.1323L11.0007 10.6611L10.1843 9.38281H10.9687L11.4413 10.1921L11.896 9.38281H12.7033L11.8743 10.7165L12.766 12.0005Z"
        fill="white"
      />
    </svg>
  );
};

export default ExcelIcon;
