import { UserFormField } from '../interfaces/ManageUsers';
/**
Initialize the react hook form with default/empty value
@returns UserFormField- object of manage user form fields
*/

export const initializeUserForm = () => {
  const defaultValues: UserFormField = {
    firstName: '',
    lastName: '',
    emailAddress: '',
    jobTitle: '',
    userType: '',
    country: '',
    state: '',
    street: '',
    city: '',
    postalCode: '',
    phoneNumber: '',
  };
  return defaultValues;
};
