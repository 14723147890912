import Translations from '../../../assets/locale/en/content.json';

export const resetPasswordFieldConfig = [
  {
    label: Translations.ResetPassword.password,
    htmlFor: 'password',
    type: 'password',
    placeholder: Translations.ResetPassword.enterPassword,
    validationRules: { required: true, minLength: 8 },
  },
  {
    label: Translations.ResetPassword.confirmPassword,
    htmlFor: 'confirmPassword',
    type: 'password',
    placeholder: Translations.ResetPassword.enterConfirmPassword,
    validationRules: { required: true },
  },
  {
    label: Translations.ResetPassword.verificationCode,
    htmlFor: 'verificationCode',
    type: 'text',
    placeholder: Translations.ResetPassword.enterVerificationCode,
    validationRules: { required: true, maxLength: 8 },
  },
];
