import { useQuery } from '@tanstack/react-query';

import { fetchAccountContactById } from '../../../../api/services/user/userService';
import { AccountActiveContact } from '../interfaces/ManageUsers';

/**
Custom hook to fetch the contact using contact ID for specific account.
@returns {object} - An object containing states data, error, and loading state.
*/
export const useGetAccountContactById = (contactId: string) => {
  const queryKey = 'activeAccountContact';
  const { data, error, isLoading } = useQuery<AccountActiveContact[], Error>({
    queryKey: [queryKey, contactId],
    queryFn: () => fetchAccountContactById(contactId),
  });
  return { data, error, isLoading };
};
