import React from 'react';

import { getFinancialDetailFields } from './utils/financialFieldConfig';
import { TngGrid } from '../../../../components/common';
import { useOrderSummaryState } from '../../context/ContextProvider';
import { FieldConfig } from '../../modals/fieldConfig';
import FieldValueWrapper from '../FieldValueWrapper';

const FinancialDetailFields = () => {
  const { financialDetailFields } = useOrderSummaryState();

  const fieldConfig: FieldConfig[] | null = getFinancialDetailFields(financialDetailFields);
  return (
    <TngGrid className="financial_details_fields">
      {fieldConfig?.map((field) => (
        <FieldValueWrapper key={field.name} name={field.name} value={field.value} />
      ))}
    </TngGrid>
  );
};

export default FinancialDetailFields;
