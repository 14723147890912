import { useQuery } from '@tanstack/react-query';

import { fetchUserPermissions } from '../../api/services/userAdmin/userAdminService';
import { PermissionItem } from '../interfaces/userAdmin/Permissions';

/**
Custom hook to fetch the user permissions using React Query.
@returns {PermissionItem[]} - An object containing user permissions, error, and loading state.
*/
export const useUserPermissions = (isAuthenticated: boolean) => {
  const { data, error, isLoading } = useQuery<PermissionItem[], Error>({
    queryKey: ['userPermissions'],
    queryFn: fetchUserPermissions,
    enabled: isAuthenticated,
  });
  return { data, error, isLoading };
};
