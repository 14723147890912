import { Filter } from '../../../common/interfaces/GridContextState';
import { DefaultSearch } from '../../../common/interfaces/GridSearchConfig';
import { DefaultSorting } from '../../../common/interfaces/GridSortingConfig';
import { OrderHistoryGridUrl } from '../../../common/interfaces/urls/OrderHistoryGridUrl';
import { ListToPipeSeperator } from '../../../utils/listToPipeSeperator';

interface defaultAPIParamsProps {
  pageNumber: number;
  pageSize: number;
  search: DefaultSearch;
  sorting: DefaultSorting;
  filters: Filter;
}

// used to create default API params for grid
export const defaultAPIParams = ({
  pageNumber,
  pageSize,
  search,
  sorting,
  filters,
}: defaultAPIParamsProps): OrderHistoryGridUrl => {
  const apiParams: OrderHistoryGridUrl = {
    pageNumber: pageNumber,
    pageSize: pageSize,
    searchParam: search.searchBy,
    searchValue: search.searchValue,
    sortingField: sorting?.colId,
    sortingOrder: sorting?.sortOrder,
    customerIds: filters?.customerIds && ListToPipeSeperator(filters?.customerIds),
    orderStatus: filters?.OrdStatus && ListToPipeSeperator(filters?.OrdStatus),
    soldThrough: filters?.soldThrough && ListToPipeSeperator(filters?.soldThrough),
    purchaseOrderFromDate: filters?.poDateFrom,
    purchaseOrderToDate: filters?.poDateTo,
  };
  return apiParams;
};
