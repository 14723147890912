export const stringAvatar = (userName: string) => {
  if (userName) {
    const name = userName.split(' ');

    if (name.length === 1) {
      return userName.split(' ')[0][0];
    } else if (name.length >= 2) {
      return userName.split(' ')[0][0] + userName.split(' ')[1][0];
    }
  }
};
