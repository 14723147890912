import React, { useEffect, useState } from 'react';

import { useGridState } from '../../../../../common/GridContext/GridContextProvider';
import { TngGrid, TngTypography } from '../../../../common';

const TngPaginationDetails = () => {
  const { pageNumber, pageSize, totalRecords } = useGridState();
  const [startNumber, setStartNumber] = useState<number>(1);
  const [lastNumber, setLastNumber] = useState<number>(10);

  useEffect(() => {
    const start = (pageNumber - 1) * pageSize + 1;
    const last = Math.min(pageNumber * pageSize, totalRecords);
    setStartNumber(start);
    setLastNumber(last);
  }, [pageNumber, pageSize, totalRecords]);

  return (
    <TngGrid container>
      <TngGrid item>
        <TngTypography className="pagination_details_text">
          {startNumber} - {lastNumber} of {totalRecords}{' '}
        </TngTypography>
      </TngGrid>
    </TngGrid>
  );
};

export default TngPaginationDetails;
