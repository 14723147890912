import React, { useEffect, useState } from 'react';

import ManageQuotesGrid from './ManageQuotesGrid';
import { GridContextProvider } from '../../../common/GridContext/GridContextProvider';
import { useQuotesStatus } from '../../../common/hooks/useQuotesStatus';
import { StatusColors } from '../../../common/interfaces/StatusColor';
import { TngGrid } from '../../../components/common';
import { CreateStatusColorObj } from '../../../utils/createStatusColorObj';

const QuotesList = () => {
  const [statusColors, setStatusColors] = useState<StatusColors>({} as StatusColors);
  const { data: quoteStatuses = [] } = useQuotesStatus({ fetchStatuses: true });

  useEffect(() => {
    // Function to populate status colors from API response
    if (quoteStatuses && quoteStatuses.length > 0) {
      const colors = CreateStatusColorObj(quoteStatuses);
      setStatusColors(colors);
    }
  }, [quoteStatuses]);
  return (
    <GridContextProvider>
      <TngGrid>
        <ManageQuotesGrid statusColors={statusColors} />
      </TngGrid>
    </GridContextProvider>
  );
};

export default QuotesList;
