import Translations from '../../../../../assets/locale/en/content.json';
import { FieldConfig } from '../../../modals/fieldConfig';
import { FinancialDetailField } from '../../../modals/FinancialDetailFields';

// return the field name with values which will render on UI
export const getFinancialDetailFields = (fieldData: FinancialDetailField | null) => {
  if (!fieldData) return null;
  const fieldConfig: FieldConfig[] = [
    {
      name: Translations.OrderSummary.orderAmount,
      value: fieldData.orderAmount,
    },
    { name: Translations.OrderSummary.currency, value: fieldData.currency },
    { name: Translations.OrderSummary.billingFrequency, value: fieldData.billingFrequency },
    { name: Translations.OrderSummary.paymentTerms, value: fieldData.paymentTerms },
  ];
  return fieldConfig;
};
