import { jwtDecode, JwtPayload } from 'jwt-decode';

const isTokenValid = (token: string): boolean => {
  try {
    const decoded: JwtPayload = jwtDecode<JwtPayload>(token);

    if (decoded && decoded.exp) {
      // Convert expiration time to milliseconds and compare with current time
      const currentTime = Date.now() / 1000; // Get current time in seconds
      return decoded.exp > currentTime;
    }
    return false;
  } catch (error) {
    console.error('Invalid JWT token', error);
    return false;
  }
};

export default isTokenValid;
