import React from 'react';

import { Card, CardContent } from '@mui/material';

import ProfileForm from './components/ProfileForm';
import Translations from '../../../../assets/locale/en/content.json';
import { TngGrid, TngTypography } from '../../../../components/common';

const Profile = () => {
  return (
    <TngGrid container className="my_profile_container" marginBottom="100px">
      <TngGrid item xs={10} md={12} lg={12}>
        <Card className="my_profile_card" data-testid="profile-card">
          <CardContent className="card_content_container" data-testid="card-content">
            <TngGrid className="my_profile_card_header">
              <TngTypography variant="h5" className="header_text">
                {Translations.AccountSettingsProfile.myProfile}
              </TngTypography>
              <TngTypography className="header_form_info">
                {Translations.AccountSettingsProfile.formRequiredFieldText}
              </TngTypography>
            </TngGrid>
            <ProfileForm />
          </CardContent>
        </Card>
      </TngGrid>
    </TngGrid>
  );
};

export default Profile;
