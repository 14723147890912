import React from 'react';

import ManageQuotesGrid from './ManageQuotesGrid';
import { GridContextProvider } from '../../../common/GridContext/GridContextProvider';
import { TngGrid } from '../../../components/common';

const DraftQuotes = () => {
  return (
    <GridContextProvider>
      <TngGrid>
        <ManageQuotesGrid />
      </TngGrid>
    </GridContextProvider>
  );
};

export default DraftQuotes;
