import React from 'react';

import { FieldErrors, FieldValues, Path, UseFormRegister } from 'react-hook-form';

import Translations from '../../assets/locale/en/content.json';
import { InputFieldConfig } from '../../common/interfaces/InputFieldConfig';
import { TngGrid, TngInputLabel, TngTextField } from '../common';

interface FieldRenderingProps<TFormField extends FieldValues> {
  field: InputFieldConfig;
  errors: FieldErrors<TFormField>;
  register: UseFormRegister<TFormField>;
}

const InputField = <TFormField extends FieldValues>({
  field,
  errors,
  register,
}: FieldRenderingProps<TFormField>) => {
  return (
    <TngGrid item key={field.label} className="form_field">
      <TngInputLabel
        htmlFor={field.htmlFor}
        className="form_field_input_label"
        aria-label={
          field.validationRules.required
            ? `${field.label} ${Translations.ariaLabels.mandatoryField}`
            : field.label
        }
      >
        {field.validationRules.required && !field.hideAstrick ? field.label + ' *' : field.label}
      </TngInputLabel>
      <TngTextField
        id={field.htmlFor}
        type={field.fieldType}
        variant="outlined"
        placeholder={field.placeholder}
        fullWidth
        error={!!errors[field.htmlFor as Path<TFormField>]}
        helperText={
          errors[field.htmlFor as Path<TFormField>]?.type === 'required' &&
          Translations.Login.fieldError
        }
        {...register(field.htmlFor as Path<TFormField>, {
          required: field.validationRules.required,
        })}
        className={`form_input_field ${
          errors[field.htmlFor as Path<TFormField>] ? 'form_input_field_error' : ''
        }`}
        InputProps={{
          disabled: field.validationRules?.readonly || false,
          inputProps: { maxLength: field.validationRules?.maxLength },
        }}
      />
    </TngGrid>
  );
};

export default InputField;
