import React from 'react';

import { Link, useLocation } from 'react-router-dom';

import IconTextWrapper from './IconTextWrapper';
import { MenuItemModal } from './modal/MenuItemModal';
import { getIconByText } from '../../../../utils/getIcon';
import { TngGrid, TngListItem } from '../../../common';

interface MenuItemProps {
  item: MenuItemModal;
  open: boolean;
  isMobile: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const MenuItem: React.FC<MenuItemProps> = ({ item, open, isMobile, setOpen }) => {
  const location = useLocation();

  const isLinkActive = (activeItems: string[]) => {
    const segments = location.pathname.split('/');
    const lastSegment = segments[segments.length - 1];
    return activeItems.includes('/' + lastSegment);
    // return '/' + lastSegment === link;
  };

  const IconComponent = getIconByText(item.icon);

  const onListItemClick = () => {
    isMobile && setOpen(false);
  };

  return (
    <TngListItem
      key={item.label}
      disablePadding
      className={`sidenav_list_item ${isLinkActive(item.activeItems) && 'list_item_active'}`}
    >
      <Link
        to={item.url}
        onClick={isMobile ? onListItemClick : undefined}
        className={`sidenav_link ${open && 'sidenav_link_open'}`}
      >
        <TngGrid className="sidenav_link_border_box"></TngGrid>
        <IconTextWrapper Icon={IconComponent} label={item.label} />
      </Link>
    </TngListItem>
  );
};

export default MenuItem;
