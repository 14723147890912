import React, { ReactNode } from 'react';

import TngTextOverlayImage from './TextOverlayImage/TextOverlayImage';
import { TngContainer } from '../../common';

interface LayoutProps {
  children?: ReactNode;
}

const LoginLayout: React.FC<LayoutProps> = ({ children }) => {
  return (
    <TngContainer leftWidth={6.8} rightWidth={5.2} className="login_layout_container">
      <TngTextOverlayImage />
      {children}
    </TngContainer>
  );
};

export default LoginLayout;
