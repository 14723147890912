import React, { ReactNode } from 'react';

import MenuItem, { MenuItemProps } from '@mui/material/MenuItem';

interface TngMenuProp extends MenuItemProps {
  children: ReactNode;
}

export const TngMenuItem: React.FC<TngMenuProp> = ({ children, ...menuItemProps }) => {
  return <MenuItem {...menuItemProps}>{children}</MenuItem>;
};
