import React from 'react';

import Translations from '../../assets/locale/en/content.json';
import TngErrorFallbackUI from '../../components/common/TngErrorFallbackUI';

const NotFound: React.FC = () => {
  return (
    <TngErrorFallbackUI
      code={Translations.error.notFoundCode}
      errorHeading={Translations.error.notFoundTitle}
      errorMessage={Translations.error.notFoundMessage}
    />
  );
};

export default NotFound;
