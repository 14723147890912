import React from 'react';

import { FieldErrors, FieldValues, Path, UseFormRegister } from 'react-hook-form';

import Translations from '../../assets/locale/en/content.json';
import { InputFieldConfig } from '../../common/interfaces/InputFieldConfig';
import { TngGrid, TngInputLabel, TngPasswordVisibleIcon, TngTextField } from '../common';

interface PasswordFieldProps<TFormField extends FieldValues> {
  field: InputFieldConfig;
  showPassword?: boolean;
  errors: FieldErrors<TFormField>;
  register: UseFormRegister<TFormField>;
  setShowPassword?: React.Dispatch<React.SetStateAction<boolean>>;
}
const PasswordField = <TFormField extends FieldValues>({
  field,
  showPassword = false,
  errors,
  register,
  setShowPassword = () => {},
}: PasswordFieldProps<TFormField>) => {
  return (
    <TngGrid item key={field.label} className="form_field">
      <TngInputLabel htmlFor={field.htmlFor} className="form_field_input_label">
        {field.label}
      </TngInputLabel>
      <TngTextField
        id={field.htmlFor}
        aria-label={field.label}
        type={showPassword ? 'string' : field.fieldType}
        variant="outlined"
        placeholder={field.placeholder}
        fullWidth
        error={!!errors[field.htmlFor as Path<TFormField>]}
        helperText={
          errors[field.htmlFor as Path<TFormField>]?.type === 'required' &&
          Translations.Login.fieldError
        }
        {...register(`${field.htmlFor}` as Path<TFormField>, {
          required: field.validationRules.required,
        })}
        className={`form_input_field password_field ${
          errors[field.htmlFor as Path<TFormField>]
            ? 'form_input_field_error password_field_error'
            : ''
        }`}
        InputProps={{
          endAdornment: (
            <TngPasswordVisibleIcon showPassword={showPassword} setShowPassword={setShowPassword} />
          ),
          inputProps: { maxLength: field.validationRules.maxLength },
        }}
      />
    </TngGrid>
  );
};

export default PasswordField;
