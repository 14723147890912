import axios, { AxiosError } from 'axios';

import { HttpStatus } from '../common/enums/ResponseCode';
import { ErrorResponse } from '../common/interfaces/ApiErrorResponse';

// handle error for response
export const responseErrorHandler = (error: AxiosError) => {
  const NETWORK_ERROR = 'Network Error';
  const UNKNOWN_ERROR = 'Unknown Error';

  // initialize the test error object
  const standardizedError: ErrorResponse = {
    status: '500',
    title: UNKNOWN_ERROR,
    detail: error.message,
    url: error.config?.url || '',
    method: error.config?.method || '',
  };
  if (error.response) {
    standardizedError.status = error.response.status.toString();
    switch (error.response.status) {
      case HttpStatus.BAD_REQUEST:
        standardizedError.title = 'Bad Request';
        break;
      case HttpStatus.UNAUTHORIZED:
        standardizedError.title = 'Unauthorized';
        // Optionally handle token refresh here
        break;
      case HttpStatus.FORBIDDEN:
        standardizedError.title = 'Forbidden';
        break;
      case HttpStatus.NOT_FOUND:
        standardizedError.title = 'Not Found';
        break;
      case HttpStatus.INTERNAL_SERVER_ERROR:
        standardizedError.title = 'Internal Server Error';
        break;
      default:
        standardizedError.title = `Unexpected error (status code: ${error.response.status})`;
    }
  } else if (error.request) {
    standardizedError.title = 'No response received';
    standardizedError.detail = 'The request was made but no response was received';
  }
  // Handle network errors or other unknown errors
  else if ((error as Error).message === NETWORK_ERROR) {
    standardizedError.title = NETWORK_ERROR;
  } else if (axios.isCancel(error)) {
    standardizedError.title = 'Request Canceled';
  }

  // use toste notifications to display proper message insted of console

  return Promise.reject(error);
};

// handle error for request
export const requestErrorHandler = (error: AxiosError) => {
  return Promise.reject(error);
};
