import { getManageQuotesColumnHeaders } from './columnDetails';
import { getManageQuotesUrl } from '../../../api/urlGenerator';
import Translations from '../../../assets/locale/en/content.json';
import { GridMoreOptionsEnum } from '../../../common/enums/GridMoreOptionsEnum';
import { FindGridConfig } from '../../../common/interfaces/FindGridConfig';
import { SearchDropdown } from '../../../common/interfaces/SearchDropdown';
import { StatusColors } from '../../../common/interfaces/StatusColor';
import { ManageQuotesGridUrl } from '../../../common/interfaces/urls/ManageQuotesGridUrl';
import { ManageQuotesTabEnum } from '../enums/TabsEnum';

// grid config contains the details about grid which will render for this screen
export const getManageUserGridConfig = (
  apiParams: ManageQuotesGridUrl,
  isDraftQuote: boolean,
  statusColors?: StatusColors,
): FindGridConfig => ({
  baseUrl: getManageQuotesUrl(apiParams, isDraftQuote),
  navigateToRoute: '/quotes-details',
  navigateRouteParams: 'id',
  ColumnHeader: getManageQuotesColumnHeaders(isDraftQuote, statusColors),
  search: {
    isApplicable: true,
    isDropdownApplicable: true,
    defaultSearch: { searchBy: 'name', searchValue: '' },
    searchDropdownOptions: dropDownValues,
    placeholder: Translations.ManageQuotes.searchHereFor3CharLimit,
  },
  sorting: {
    isApplicable: false,
  },
  moreOptions: {
    isApplicable: true,
    moreOptionNavigation: [
      {
        label: Translations.ManageQuotes.viewMoreDetails,
        navigationTo: 'quote-details',
        navigationProps: ['name', 'opportunityId'],
        navigationTab: [
          {
            name: 'tab',
            value: `${isDraftQuote ? ManageQuotesTabEnum.DRAFT_QUOTE : ManageQuotesTabEnum.QUOTE_LIST}`,
          },
        ],
        eventType: GridMoreOptionsEnum.VIEW,
      },
      {
        label: isDraftQuote
          ? Translations.ManageQuotes.resume
          : Translations.ManageQuotes.downloadPDF,
        navigationTo: '',
        navigationProps: [],
        eventType: GridMoreOptionsEnum.DUMMY,
      },
      {
        label: Translations.ManageQuotes.cancelQuote,
        navigationTo: '',
        navigationProps: [],
        eventType: GridMoreOptionsEnum.DUMMY,
      },
    ],
  },
  rowHeight: 75,
  columnWidth: 120,
  isPaginationVisible: true,
});

export const dropDownValues: SearchDropdown[] = [
  { label: 'name', value: Translations.ManageQuotes.quoteId },
  { label: 'customerName', value: Translations.ManageQuotes.customerName },
  { label: 'createdBy', value: Translations.ManageQuotes.createdBy },
];
