import { createTheme } from '@mui/material/styles';

import { fontSize, lineHeight, fontWeight } from './ThemeTypography';

const customTheme = createTheme({
  palette: {
    primary: {
      main: '#002854',
      light: '#335376',
      dark: '#001c3b',
      contrastText: '#ffffff',
    },
    secondary: {
      main: '#00a0fb',
      light: '#4dbdfc',
      dark: '#0080c9',
      contrastText: '#ffffff',
    },
    error: {
      main: '#b41f30',
    },
    warning: {
      main: '#ff9800',
    },
    info: {
      main: '#2196f3',
    },
    success: {
      main: '#4caf50',
    },
    background: {
      default: '#ffffff',
      paper: '#ffffff',
    },
    text: {
      primary: '#002854',
      secondary: '#00a0fb',
      disabled: '#b1bdca',
    },
  },
  typography: {
    fontFamily: 'RedHatDisplay-Medium',
    h1: {
      fontSize: '40px',
      fontWeight: 500,
      lineHeight: 1.2,
      letterSpacing: '-0.01562em',
    },
    h2: {
      fontSize: '32px',
      fontWeight: 500,
      lineHeight: 1.3,
      letterSpacing: '-0.00833em',
    },
    h3: {
      fontSize: '28px',
      fontWeight: 500,
      lineHeight: 1.4,
      letterSpacing: '0em',
    },
    h4: {
      fontSize: '24px',
      fontWeight: 500,
      lineHeight: 1.5,
      letterSpacing: '0.00735em',
    },
    h5: {
      fontSize: '20px',
      fontWeight: 500,
      lineHeight: 1.6,
      letterSpacing: '0em',
    },
    h6: {
      fontSize: '16px',
      fontWeight: 500,
      lineHeight: 1.6,
      letterSpacing: '0.0075em',
    },
    body1: {
      fontSize: '16px',
      fontWeight: 400,
      lineHeight: 1.5,
      letterSpacing: '0.00938em',
    },
    body2: {
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: 1.43,
      letterSpacing: '0.01071em',
    },
    button: {
      fontSize: '14px',
      fontWeight: 500,
      lineHeight: 1.75,
      letterSpacing: '0.02857em',
      textTransform: 'uppercase',
    },
  },
  spacing: 8,
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  shape: {
    borderRadius: 4,
  },
  customProperties: {
    fontSize: fontSize,
    lineHeight: lineHeight,
    fontWeight: fontWeight,
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 8,
          '&:hover': {
            backgroundColor: 'inherit', // Disable hover effect
          },
          '&:active': {
            boxShadow: 'none', // Disable on-click effect
            backgroundColor: 'inherit',
          },
        },
        containedPrimary: {
          backgroundColor: '#00a0fb',
          '&:hover': {
            backgroundColor: '#00a0fb',
            '&.Mui-disabled': {
              backgroundColor: '#b1bdca',
              color: '#ffffff',
            },
          },
        },
        containedSecondary: {
          backgroundColor: '#dc004e',
          '&:hover': {
            backgroundColor: '#9a0036',
            '&.Mui-disabled': {
              backgroundColor: '#b1bdca',
              color: '#ffffff',
            },
          },
        },
      },
      defaultProps: {
        disableRipple: true, // Disable ripple effect globally
      },
    },
  },
});

export default customTheme;
