import React, { forwardRef } from 'react';

import IconButton, { IconButtonProps } from '@mui/material/IconButton';

interface TngIconButtonProps extends IconButtonProps {
  children: React.ReactNode;
}

// Forward ref to the underlying IconButton
export const TngIconButton = forwardRef<HTMLButtonElement, TngIconButtonProps>(
  ({ children, ...props }, ref) => {
    return (
      <IconButton {...props} ref={ref}>
        {children}
      </IconButton>
    );
  },
);

TngIconButton.displayName = 'TngIconButton';
