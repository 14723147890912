import React, { createContext, useContext, ReactNode, useReducer, Dispatch } from 'react';

import { GridReducers } from './GridReducers';
import { Sorting } from '../enums/Sorting';
import { GridActions } from '../interfaces/GridActionInterfaces';
import { GridContextState } from '../interfaces/GridContextState';

export interface GridContextType {
  state: GridContextState;
  dispatch: Dispatch<GridActions>; // Adjust 'any' as per your specific action types
}

// Initial state factory function to support multiple configurations
export const initialState = (): GridContextState => ({
  gridData: null,
  gridMappedData: null,
  filters: {},
  search: {
    searchBy: '',
    searchValue: '',
  },
  sorting: {
    colId: '',
    sortOrder: Sorting.ASC,
  },
  totalRecords: 0,
  totalPages: 0,
  pageNumber: 1,
  pageSize: 10,
  loading: true,
  gridApi: null,
  isFilterApplied: false,
  isFilterOpen: false,
  isSearchApplied: false,
  isSortingApplied: false,
});

interface GridContextProviderProps {
  children: ReactNode | ReactNode[];
}

// Creating context with an undefined default value
export const DataContext = createContext<GridContextType | undefined>(undefined);

export const GridContextProvider: React.FC<GridContextProviderProps> = ({ children }) => {
  // State to manage the grid context
  const [state, dispatch] = useReducer(GridReducers, initialState());

  return <DataContext.Provider value={{ state, dispatch }}>{children}</DataContext.Provider>;
};

// method to get state
export const useGridState = () => {
  const context = useContext(DataContext);
  if (!context) {
    throw new Error('useGridState must be used within a GridProvider');
  }
  return context.state;
};

// method to get dispatch dunction
export const useGridDispatch = () => {
  const context = useContext(DataContext);
  if (!context) {
    throw new Error('useGridDispatch must be used within a GridProvider');
  }
  return context.dispatch;
};
