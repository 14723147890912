import { ContextActions } from '../modals/contextAction';
import { ContextState } from '../modals/contextState';

// Action types
export const SET_EXISTING_OPPORTUNITY = 'SET_EXISTING_OPPORTUNITY';
export const SET_PREV_BUTTON_PROPS = 'SET_PREV_BUTTON_PROPS';
export const SET_NEXT_BUTTON_PROPS = 'SET_NEXT_BUTTON_PROPS';

// all reducers for grid context
export const ContextReducers = (state: ContextState, action: ContextActions) => {
  switch (action.type) {
    case SET_EXISTING_OPPORTUNITY:
      return { ...state, existingOpportunity: action.payload };
    case SET_PREV_BUTTON_PROPS:
      return { ...state, prevButtonProps: action.payload };
    case SET_NEXT_BUTTON_PROPS:
      return { ...state, nextButtonProps: action.payload };
    default:
      throw new Error(`Unknown action: ${action.type}`);
  }
};
