import React, { ReactNode } from 'react';

import List, { ListProps } from '@mui/material/List';

interface TngListProp extends ListProps {
  children: ReactNode | ReactNode[];
}

export const TngList: React.FC<TngListProp> = ({ children, ...listProps }) => {
  return <List {...listProps}>{children}</List>;
};
