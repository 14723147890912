import {
  QuoteStatus,
  QuoteStatusResponse,
} from '../../../common/interfaces/manageQuotes/QuoteStatus';
import axiosInstance from '../../axiosInstance';
import { getUrl, URLS } from '../../urls';

/**
  Fetches the quote statuses from the API.
  @returns {Promise<QuoteStatus[]>} - A promise that resolves to an array of quote statuses.
  @throws {Error} - Throws an error if the request fails.
*/

export const fetchQuoteStatus = async (): Promise<QuoteStatus[]> => {
  try {
    const url = getUrl(URLS.QUOTES_STATUS);

    const { data } = await axiosInstance.get<QuoteStatusResponse>(url);
    return data.data;
  } catch (err: any) {
    throw new Error('Failed to fetch quote status');
  }
};
