import React from 'react';

const ExpandIcon = () => {
  return (
    <svg width="32" height="32" viewBox="0 0 36 33" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M27.9997 28.0498H7.99972C6.42176 28.0498 5.14258 26.8559 5.14258 25.3831V6.71647C5.14258 5.24371 6.42176 4.0498 7.99972 4.0498H27.9997C29.5777 4.0498 30.8569 5.24371 30.8569 6.71647V25.3831C30.8569 26.8559 29.5777 28.0498 27.9997 28.0498Z"
        stroke="#002854"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.71484 18.7168L11.2148 16.0501L8.71484 13.3835"
        stroke="#002854"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.4297 28.0498V4.0498"
        stroke="#002854"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ExpandIcon;
