import { AccountActiveContact } from '../../common/interfaces/ManageUsers';
import { UserDetailsModal } from '../modals/UserDetailsModal';

export const dataMapper = (userDetails: AccountActiveContact) => {
  const userDetailsInfo: UserDetailsModal = {
    firstName: userDetails.firstName,
    lastName: userDetails.lastName,
    jobTitle: userDetails.jobTitle,
    phoneNumber: userDetails.phoneNumber,
    userType: userDetails.userTypeId,
    createdDate: userDetails.createdDate,
    emailAddress: userDetails.emailAddress,
    address: userDetails.address,
  };
  return { userDetailsInfo };
};
