import React from 'react';

import CreateQuoteWorkflow from './components/CreateQuoteWorkflow';
import { QuoteCreationContextProvider } from './context/ContextProvider';

const QuoteCreation = () => {
  return (
    <QuoteCreationContextProvider>
      <CreateQuoteWorkflow />
    </QuoteCreationContextProvider>
  );
};

export default QuoteCreation;
