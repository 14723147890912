import React from 'react';

import { CircularProgress } from '@mui/material';

import { TngButton, TngGrid } from '../common';

interface FormButtonProps {
  isFormUpdated: boolean;
  loading: boolean;
  btnLabel: string;
}

const FormButtons: React.FC<FormButtonProps> = ({ isFormUpdated, loading, btnLabel }) => {
  return (
    <TngGrid className="form_buttons">
      <TngButton
        type="submit"
        variant="contained"
        className={`save_button ${!isFormUpdated && 'button_disabled'} ${loading && 'button_loader'} `}
        disabled={!isFormUpdated || loading}
        startIcon={loading ? <CircularProgress size={20} color="inherit" /> : null}
        data-testid="submit-button"
      >
        {btnLabel}
      </TngButton>
    </TngGrid>
  );
};

export default FormButtons;
