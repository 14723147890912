import React from 'react';

import Translations from '../../../../assets/locale/en/content.json';
import { TngGrid } from '../../../common';

const MoreOptionHeader = () => {
  return <TngGrid aria-label={Translations.orderHistory.moreOptionCell}></TngGrid>;
};

export default MoreOptionHeader;
