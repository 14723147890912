import React, { useEffect, useState } from 'react';

import FieldDetail from './FieldDetail';
import FieldHeader from './FieldHeader';
import { TngDivider, TngGrid } from '../../../components/common';
import { useOrderSummaryDispatch } from '../context/ContextProvider';
import { ContextActionEnum } from '../enums/ContextActionEnums';
import { useOrderSummary } from '../hook/useOrderSummary';
import { FieldDetails } from '../modals/fieldDetails';
import { HeaderDetails } from '../modals/headerDetails';
import { dataMapper } from '../utils/dataMapper';

const FieldContainer = () => {
  const [fieldDetails, setFieldDetails] = useState<FieldDetails>();
  const [headerDetails, setHeaderDetails] = useState<HeaderDetails>();
  const dispatch = useOrderSummaryDispatch();

  const currentUrl = window.location.href;
  const urlObject = new URL(currentUrl);
  const orderId = urlObject.searchParams.get('orderId');

  const { data } = useOrderSummary(orderId!);

  useEffect(() => {
    if (data && orderId) {
      const { headerInfo, fieldInfo, financialFieldsInfo } = dataMapper(data[0]);
      setHeaderDetails(headerInfo);
      setFieldDetails(fieldInfo);

      // set orderId in context for product list
      dispatch({ type: ContextActionEnum.SET_ORDER_ID, payload: orderId });

      // set data for financial detail fields
      if (financialFieldsInfo) {
        dispatch({ type: ContextActionEnum.SET_FINANCIAL_FIELDS, payload: financialFieldsInfo });
        dispatch({ type: ContextActionEnum.SET_IS_FINANCIAL_TAB_VISIBLE, payload: true });
      }
    }
  }, [data, dispatch, orderId]);

  return (
    <TngGrid className="tnginfo_fields_container">
      <FieldHeader headerDetails={headerDetails} />
      <TngDivider className="fields_container_divider" />
      <FieldDetail fieldDetails={fieldDetails} />
    </TngGrid>
  );
};

export default FieldContainer;
