/**
 * TngLiveRegionS508 is a visually hidden live region component that announces
 * dynamic content changes for screen readers, improving accessibility (Section 508 compliance).
 * It uses `aria-live="assertive"` to ensure messages are read out immediately.
 *
 * @param {string | null} message - The message to be announced. When null, nothing is announced.
 */

import React from 'react';

const TngLiveRegionS508 = ({ message }: { message: string | null }) => {
  return (
    //    need to use div because TngGrid won't work for this
    <div
      aria-live="assertive"
      style={{
        position: 'absolute',
        left: '-9999px',
        height: '1px',
        width: '1px',
        overflow: 'hidden',
      }}
    >
      {message}
    </div>
  );
};

export default TngLiveRegionS508;
