// convert orderDetails to header/field level for each use and fil field values to NA is not given
import { TransformDate } from '../../../utils/dateTimeConvertor';
import { OrderHistoryModal } from '../../OrderHistory/modals/OrderHistoryModal';
import { FieldDetails } from '../modals/fieldDetails';
import { FinancialDetailField } from '../modals/FinancialDetailFields';
import { HeaderDetails } from '../modals/headerDetails';

export const dataMapper = (orderDetail: OrderHistoryModal) => {
  const headerInfo: HeaderDetails = {
    poNumber: orderDetail.purchaseOrderNumber,
    orderStatus: orderDetail.orderStatus,
  };
  const fieldInfo: FieldDetails = {
    customerName: orderDetail.customerName,
    poDate: TransformDate(orderDetail.purchaseOrderDate),
    orderCreationDateAndTime: TransformDate(orderDetail.orderCreationDateAndTime),
    contractNumber: orderDetail.contractNumber,
    quoteId: orderDetail.quoteId,
    orderType: orderDetail.orderType,
    soldThrough: orderDetail.soldThrough,
    salesRep: orderDetail.salesRepresentative,
  };
  let financialFieldsInfo: FinancialDetailField | null = null;
  if (orderDetail.billingInformation) {
    financialFieldsInfo = {
      orderAmount: orderDetail.billingInformation.orderAmount,
      currency: orderDetail.billingInformation.currencyCode,
      billingFrequency: orderDetail.billingInformation.billingFrequency,
      paymentTerms: orderDetail.billingInformation.paymentTerms,
    };
  }
  return { headerInfo, fieldInfo, financialFieldsInfo };
};
