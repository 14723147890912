import { getUrl, URLS } from './urls';
import { ExistingOpportunityGridUrl } from '../common/interfaces/urls/ExistingOpportunityGridUrl';
import { ManageQuotesGridUrl } from '../common/interfaces/urls/ManageQuotesGridUrl';
import { ManageUsersGridUrl } from '../common/interfaces/urls/ManageUsersGridUrl';
import { OrderHistoryGridUrl } from '../common/interfaces/urls/OrderHistoryGridUrl';
import { ProductListGridUrl } from '../common/interfaces/urls/ProductListGridUrl';
import { TrainingCertificationGridUrl } from '../common/interfaces/urls/TrainingCertificationGridUrl';
import { createEncodedUrl } from '../utils/createEncodedUrl';

/**
Generates a URL for fetching the order history with the specified query parameters.
@param {OrderHistoryGridUrl} params - The query parameters for the order history grid.
@returns {string} - The constructed URL with query parameters.
*/
export const getOrderHistoryUrl = (params: OrderHistoryGridUrl): string => {
  const query = new URLSearchParams();

  /**
  Generates a URL for fetching order history with the specified query parameters.
  @param {OrderHistoryGridUrl} params - The query parameters for the order history grid.
  @returns {string} - The constructed URL with query parameters.
*/
  const baseURL = getUrl(URLS.ORDERS);
  if (params.searchParam && params.searchValue) {
    query.append('searchParam', params.searchParam);
    query.append('searchValue', params.searchValue);
  }

  if (params.sortingField && params.sortingField) {
    query.append('sortingField', params.sortingField);
    query.append('sortingOrder', params.sortingOrder);
  }

  query.append('pageNumber', params?.pageNumber.toString());
  query.append('pageSize', params?.pageSize.toString());
  if (params.orderStatus) query.append('orderStatus', params.orderStatus);
  if (params.customerIds) query.append('customerIds', params.customerIds);
  if (params.soldThrough) query.append('soldThroughIds', params.soldThrough);
  if (params.orderId) query.append('orderId', params.orderId);
  if (params.purchaseOrderFromDate)
    query.append('purchaseOrderFromDate', params.purchaseOrderFromDate);
  if (params.purchaseOrderToDate) query.append('purchaseOrderToDate', params.purchaseOrderToDate);

  return createEncodedUrl(baseURL, query);
};

/**
Generates a URL for fetching the product list with the specified query parameters and order ID.
@param {ProductListGridUrl} params - The query parameters for the product list grid.
@param {string} orderId - The order ID to include in the URL.
@returns {string} - The constructed URL with query parameters.
*/
export const getProductListUrl = (params: ProductListGridUrl, orderId: string) => {
  const query = new URLSearchParams();

  const baseURL = getUrl(URLS.PRODUCT_LIST, { orderId: orderId });

  if (params.productName) query.append('productName', params.productName);
  query.append('pageNumber', params.pageNumber.toString());
  query.append('pageSize', params.pageSize.toString());
  query.append('sortingField', params.sortingField);
  query.append('sortingOrder', params.sortingOrder);

  return createEncodedUrl(baseURL, query);
};

/**
Generates a URL for fetching the training and certification list with the specified query parameters and account ID.
@param {TrainingCertificationGridUrl} params - The query parameters for the training and certification list grid.
@param {string} accountId - The account ID to include in the URL.
@returns {string} - The constructed URL with query parameters.
*/

export const getTrainingCertificationListUrl = (
  params: TrainingCertificationGridUrl,
  accountId: string,
) => {
  const query = new URLSearchParams();
  const baseURL = getUrl(URLS.TRAINING_CERTIFICATION, { accountId: accountId });
  if (params.searchByValue) {
    query.append('searchValue', params.searchByValue);
  }

  query.append('pageNumber', params.pageNumber.toString());
  query.append('pageSize', params.pageSize.toString());
  if (params.contactIds) query.append('contactIds', params.contactIds);

  return createEncodedUrl(baseURL, query);
};

/**
Generates a URL for fetching the manage users with the specified query parameters.
@param {ManageUsersGridUrl} params - The query parameters for the manage users grid.
@returns {string} - The constructed URL with query parameters.
*/
export const getManageUsersUrl = (params: ManageUsersGridUrl, accountId: string): string => {
  const query = new URLSearchParams();
  const baseURL = getUrl(URLS.ACCOUNT_CONTACTS, { accountId: accountId });

  query.append('pageNumber', params.pageNumber.toString());
  query.append('pageSize', params.pageSize.toString());

  // for optional params
  if (params.searchParam && params.searchValue)
    query.append(params.searchParam, params.searchValue);
  if (params.countryCodes) query.append('countryCodes', params.countryCodes);
  if (params.userTypes) query.append('userTypes', params.userTypes);
  if (params.Id) query.append('Id', params.Id);

  return createEncodedUrl(baseURL, query);
};

/**
Generates a URL for fetching the manage quotes with the specified query parameters.
@param {ManageQuotesGridUrl} params - The query parameters for the manage quotes grid.
@returns {string} - The constructed URL with query parameters.
*/
export const getManageQuotesUrl = (params: ManageQuotesGridUrl, isDraftQuote: boolean): string => {
  const query = new URLSearchParams();
  const baseURL = getUrl(URLS.QUOTES);

  query.append('isDraft', isDraftQuote.toString());
  query.append('pageNumber', params.pageNumber.toString());
  query.append('pageSize', params.pageSize.toString());

  // for optional params
  if (params.searchParam && params.searchValue)
    query.append(params.searchParam, params.searchValue);
  if (params.opportunityName) query.append('opportunityName', params.opportunityName);
  if (params.quoteStatus) query.append('quoteStatus', params.quoteStatus);
  if (params.id) query.append('Id', params.id);

  return createEncodedUrl(baseURL, query);
};

/**
Generates a URL for fetching the existing opportunities with the specified query parameters.
@param {ExistingOpportunityGridUrl} params - The query parameters for the existing opportunity grid.
@returns {string} - The constructed URL with query parameters.
*/
export const getExistingOpportunityUrl = (params: ExistingOpportunityGridUrl): string => {
  const query = new URLSearchParams();

  /** Generates a URL for fetching existing opportunity with the specified query parameters.
  @param {ExistingOpportunityGridUrl} params - The query parameters for the existing opportunity grid.
  @returns {string} - The constructed URL with query parameters. */

  const baseURL = getUrl(URLS.GET_OPPORTUNITIES);
  if (params.searchValue) query.append('searchValue', params.searchValue);
  query.append('pageNumber', params?.pageNumber.toString());
  query.append('pageSize', params?.pageSize.toString());
  query.append('sortingField', params.sortingField);
  query.append('sortingOrder', params.sortingOrder);

  return createEncodedUrl(baseURL, query);
};
