import React from 'react';

import { Typography } from '@mui/material';

import Translations from '../../../../../assets/locale/en/content.json';
import { UserTypes } from '../../../../../common/interfaces/user/UserTypes';
import { TngGrid } from '../../../../../components/common';
import TngCheckbox from '../../../../../components/common/TngCheckbox';
import { sortAscending } from '../../../../../utils/sortAscending';
import { ManageUserFilter } from '../../modals/ManageUserFilter';

interface UserTypesProps {
  userTypes: UserTypes[];
  filterObj: ManageUserFilter;
  setFiltersObj: React.Dispatch<React.SetStateAction<ManageUserFilter>>;
}

const FilterUserType: React.FC<UserTypesProps> = ({ userTypes, filterObj, setFiltersObj }) => {
  // update state after user select/deselect order status in filter

  // Sorting the usertypes in ascending order
  userTypes.sort((a, b) => {
    return sortAscending(a.value, b.value);
  });

  const handleUserTypeToggle = (userTypeValue: string) => () => {
    const existingUserType = filterObj?.userTypes || [];
    const newFilters = existingUserType.includes(userTypeValue)
      ? existingUserType.filter((value: any) => value !== userTypeValue)
      : [...existingUserType, userTypeValue];

    setFiltersObj({ ...filterObj, userTypes: newFilters });
  };

  return (
    <TngGrid container marginTop="20px">
      <TngGrid item xs={12} className="order_status_text">
        <Typography variant="h5">{Translations.filterSection.userType}</Typography>
      </TngGrid>
      <TngGrid item xs={12} className="order_status_checkbox">
        {userTypes.map((status: UserTypes) => (
          <TngCheckbox
            key={status.value}
            ariaLabel={Translations.filterSection.userType}
            checked={(filterObj.userTypes || []).includes(status.value)}
            label={status.label}
            handleToggleCheckbox={handleUserTypeToggle(status.value)}
          />
        ))}
      </TngGrid>
    </TngGrid>
  );
};

export default FilterUserType;
