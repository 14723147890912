import { useEffect, useState } from 'react';

import { useNavigate, useSearchParams } from 'react-router-dom';

import ManageQuotesTabs from './components/ManageQuotesTabs';
import { ManageQuotesTabEnum } from './enums/TabsEnum';
import Translations from '../../assets/locale/en/content.json';
import { TngButton, TngGrid, TngTypography } from '../../components/common';
import { focusFirstInteractiveElement } from '../../utils/findInteractiveElement';

const ManageQuotes = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  // Deriving initial active tab index based on search params directly
  const [activeTabIndex, setActiveTabIndex] = useState<number>(() => {
    const initialTab = searchParams.get('tab');
    return initialTab === ManageQuotesTabEnum.DRAFT_QUOTE ? 2 : 1;
  });

  // for S508 Accessibility (focus on first interactive element after rendering)
  useEffect(() => {
    const mainDiv = document.getElementById('main-content');
    focusFirstInteractiveElement(mainDiv);
  }, []);

  // Helper to manage tab and navigation
  const handleTabChange = (tabIndex: number) => {
    setActiveTabIndex(tabIndex);
    const tabName =
      tabIndex === 2 ? ManageQuotesTabEnum.DRAFT_QUOTE : ManageQuotesTabEnum.QUOTE_LIST;
    navigate(`?tab=${tabName}`, { replace: true });
  };

  // Sync URL and state on initial load based on tab parameter
  useEffect(() => {
    const tabParam = searchParams.get('tab');
    if (
      !tabParam ||
      ![ManageQuotesTabEnum.DRAFT_QUOTE, ManageQuotesTabEnum.QUOTE_LIST].includes(
        tabParam as ManageQuotesTabEnum,
      )
    ) {
      navigate(`?tab=quote-list`, { replace: true });
      setActiveTabIndex(1); // Default to Quote List
    }
  }, [activeTabIndex, navigate, searchParams]);

  return (
    <TngGrid className="manage_quotes">
      <TngGrid className="manage_quotes_header">
        <TngTypography variant="h2" tabIndex={0}>
          {Translations.ManageQuotes.manageQuotes}
        </TngTypography>
        <TngButton
          className="create_new_quote_btn"
          variant="contained"
          onClick={() => navigate('/quote-creation')}
        >
          {Translations.ManageQuotes.createNewQuote}
        </TngButton>
      </TngGrid>
      <ManageQuotesTabs onTabSelection={handleTabChange} activeTabIndex={activeTabIndex} />
    </TngGrid>
  );
};

export default ManageQuotes;
