import { useQuery } from '@tanstack/react-query';

import { fetchAccountDetails } from '../../../../../api/services/accountsettings/accountDetailsService';
import { AccountDetailsResponse } from '../../../../../common/interfaces/accountsettings/accountdetails/accountDetailsResponse';

export const useAccountDetails = (accountId: string) => {
  const { data, error, isLoading } = useQuery<AccountDetailsResponse, Error>({
    queryKey: ['accountdetails', accountId],
    queryFn: async () => {
      const result = await fetchAccountDetails(accountId);
      if (!result) {
        throw new Error('Failed to fetch account details');
      }
      return result;
    },
  });

  return { data, error, isLoading };
};
