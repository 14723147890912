import React, { useEffect } from 'react';

import { useSearchParams } from 'react-router-dom';

import OpportunityDetails from './components/opportunityDetails';
import QuoteDetails from './components/quoteDetails';
import { useOpportunityDetails } from './hook/useOpportunityDetails';
import { useQuoteDetails } from './hook/useQuoteDetails';
import Translations from '../../assets/locale/en/content.json';
import { TngGrid } from '../../components/common';
import { TngLoader } from '../../components/common';
import TngBackNavigation from '../../components/common/TngBackNavigation';
import { focusFirstInteractiveElement } from '../../utils/findInteractiveElement';
import { ManageQuotesTabEnum } from '../ManageQuotes/enums/TabsEnum';

const QuoteManageDetails = () => {
  const [searchParams] = useSearchParams(); // Get the search params using useSearchParams hook
  const quoteName = searchParams.get('name') || ''; // Fetch the 'name' param from the URL
  const opportunityId = searchParams.get('opportunityId') || ''; // Fetch the 'opportunityId' param
  const tab = searchParams.get('tab') || ManageQuotesTabEnum.QUOTE_LIST; // Fetch the 'tab' param if needed

  const { data: opportunityData, isLoading: isOpportunityDetailsLoading } =
    useOpportunityDetails(opportunityId);
  const { data: quoteData, isLoading: isQuoteDetailsLoading } = useQuoteDetails(quoteName, tab);

  useEffect(() => {
    const mainDiv = document.getElementById('main-content');
    focusFirstInteractiveElement(mainDiv);
  }, []);

  return (
    <TngGrid className="quote_details">
      <TngBackNavigation
        label={Translations.common.back}
        path={`${Translations.navigation.manageQuotes}?tab=${tab}`}
      />
      {/* Conditionally render the loader or content based on the loading state */}
      {isOpportunityDetailsLoading || isQuoteDetailsLoading ? (
        <TngGrid className="quote_details_loader">
          <TngLoader />
        </TngGrid>
      ) : (
        <TngGrid className="quote_details_summary">
          {quoteData && <QuoteDetails data={quoteData} />}
          {opportunityData && <OpportunityDetails data={opportunityData} />}
        </TngGrid>
      )}
    </TngGrid>
  );
};

export default QuoteManageDetails;
