import { getColumnHeaders } from './columnDetails';
import { getUrl, URLS } from '../../../api/urls';
import { FindGridConfig } from '../../../common/interfaces/FindGridConfig';

// grid config contains the details about grid which will render for this screen
export const getGridConfig = (tabIndex: number): FindGridConfig => ({
  baseUrl: getUrl(URLS.PARTNER_PROGRAM_GUIDE_LIST, {
    accountId: '001dy000000HRbhAAG',
    programGuideType: tabIndex.toString(),
  }),
  ColumnHeader: getColumnHeaders(),
  search: {
    isApplicable: false,
  },
  moreOptions: {
    isApplicable: false,
  },
  rowHeight: 75,
  columnWidth: 120,
  sorting: {
    isApplicable: false,
  },
  isPaginationVisible: false,
});
