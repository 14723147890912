import { useState } from 'react';

import { CircularProgress, Radio } from '@mui/material';
import { Link } from 'react-router-dom';

import ExcelIcon from '../../../../assets/icons/ExcelIcon';
import PDFIcon from '../../../../assets/icons/PDFIcon';
import SadIcon from '../../../../assets/icons/SadIcon';
import WordIcon from '../../../../assets/icons/WordIcon';
import Translations from '../../../../assets/locale/en/content.json';
import { FileTypeEnum } from '../../../../common/enums/FileTypeEmun';
import { useFileDownload } from '../../../../common/hooks/useFileDownload';
import { TngButton, TngGrid, TngLoader, TngTypography } from '../../../common';

// this will render cell with 2 divs
export const CellWithMultipleValue = (props: any) => {
  const { value } = props;
  return (
    <TngGrid className="cell_value_render multiple_value_container">
      <TngTypography className="name"> {value.name}</TngTypography>
      <TngTypography className="type">{value.type}</TngTypography>
    </TngGrid>
  );
};

// this will render cell with link
export const CellWithlink = (props: any) => {
  const { value, data, navigateToRoute, navigateRouteParams } = props;
  const absoluteURL = new URL(navigateToRoute, window.location.origin);
  absoluteURL.searchParams.set(navigateRouteParams, data.orderId);

  return (
    value && (
      <Link
        to={absoluteURL.href}
        className="grid_cell_link cell_value_render"
        aria-label={`${props.column.colId} ${value}`}
      >
        {value}
      </Link>
    )
  );
};

// this will render cell with border
export const CellWithBorder = (props: any) => {
  const { value, statusColor } = props;
  const color = `#${statusColor[value as keyof typeof statusColor]}`;
  return (
    <TngGrid
      className="cell_with_border_container cell_value_render"
      style={{ border: `1px solid ${color}` }}
    >
      <TngTypography className="cell_value"> {value}</TngTypography>
    </TngGrid>
  );
};

// this will render when no data is present in grid
export const CustomNoRowsOverlay = ({
  isSearchedOrFiltered,
}: {
  isSearchedOrFiltered: boolean;
}) => {
  const renderedMessage = isSearchedOrFiltered
    ? Translations.TngGrid.noResultsFound
    : Translations.TngGrid.noListAvailable;

  return (
    <TngGrid className="custom_no_rows_overlay">
      <SadIcon height="42" width="42" />
      <TngTypography
        role="alert"
        aria-live="assertive"
        data-testid="no_results_message"
        className="custom_message"
      >
        {renderedMessage}
      </TngTypography>
    </TngGrid>
  );
};

// this will render loader
export const CustomLoadingOverlay: React.FC = () => {
  return (
    <TngGrid className="custom-loading-overlay" data-testid="grid_loader">
      <CircularProgress role="progressbar" />
    </TngGrid>
  );
};

// this will render tooltip tith data on value property
export const CustomTooltip = ({ value }: any) => {
  return <TngGrid className="grid-tooltip">{value}</TngGrid>;
};

// Custom cell renderer component
export const TextCellRenderer = (props: any) => {
  const { value } = props;

  return (
    <TngGrid
      className="cell_value_render"
      aria-label={`${value ? value : Translations.TngGrid.dataNotAvailable}`}
    >
      {value}
    </TngGrid>
  );
};

export const CellWithDownloadAction = (props: any) => {
  const { id, fileName } = props.data;
  const [isLoading, setIsLoading] = useState(false);
  const download = useFileDownload();
  const downloadFile = async (id: string) => {
    if (id) {
      try {
        setIsLoading(true);
        const res = await download(id);
        if (res?.isSuccess) {
          setIsLoading(false);
        }
      } catch (err) {
        setIsLoading(false);
      }
    }
  };
  return (
    <TngGrid className="cell_with_download_action">
      <TngButton
        className="download_button"
        onClick={() => downloadFile(id)}
        aria-label={`download ${fileName}`}
        disabled={isLoading ? true : false}
      >
        {Translations.common.download}
      </TngButton>
      {isLoading && <TngLoader />}
    </TngGrid>
  );
};

export const CellWithFileIcon = (props: any) => {
  const { value } = props;

  const filterIcon = () => {
    switch (value) {
      case FileTypeEnum.EXCEL:
        return <ExcelIcon />;
      case FileTypeEnum.PDF:
        return <PDFIcon />;
      case FileTypeEnum.WORD:
        return <WordIcon />;
      default:
        return null;
    }
  };
  return (
    <TngGrid className="cell_with_File_Icon">
      {filterIcon()}
      <TngTypography component="span" variant="body1">
        {value}
      </TngTypography>
    </TngGrid>
  );
};

export const CellWithRadioButton = (props: any) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    props.setExistingOpportunityValue({
      selectedOpportunity: event.target.value,
    });
  };

  props.eGridCell?.parentElement?.classList.toggle(
    'selected_row',
    props.data?.id === props?.existingOpportunityValue?.selectedOpportunity,
  );

  return (
    <TngGrid className="cell_with_radio_button">
      <Radio
        checked={props?.existingOpportunityValue?.selectedOpportunity === props.data?.id}
        onChange={handleChange}
        value={props.data?.id}
        name="radio-buttons"
        inputProps={{ 'aria-label': props.data?.name }}
      />
    </TngGrid>
  );
};
