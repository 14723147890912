import { useQuery } from '@tanstack/react-query';

import { fetchCountries } from '../../api/services/country/countryService';
import { Country } from '../interfaces/Country';

/**
Custom hook to fetch the countries using React Query.
@returns {object} - An object containing countries data, error, and loading state.
*/
export const useGetCountries = (isAuthenticated: boolean) => {
  const { data, error, isLoading } = useQuery<Country[], Error>({
    queryKey: ['country'],
    queryFn: fetchCountries,
    enabled: isAuthenticated,
  });
  return { data, error, isLoading };
};
