import React, { useEffect, useState } from 'react';

import { useLocation, useNavigate } from 'react-router-dom';

import ManageUsersGrid from './components/ManageUserGrid';
import Translations from '../../../assets/locale/en/content.json';
import { GridContextProvider } from '../../../common/GridContext/GridContextProvider';
import { TngGrid, TngTypography } from '../../../components/common';
import { focusFirstInteractiveElement } from '../../../utils/findInteractiveElement';
import EditUserDrawer from '../common/components/AccountContactFrom/EditFormDrawer';

const ManageUser = () => {
  // State to track whether edit-user is true
  const [isEditUser, setIsEditUser] = useState<boolean>(false);

  // Access the current location (URL)
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    // Parse the query parameters
    const searchParams = new URLSearchParams(location.search);

    // Check if edit-user is true in the query parameters
    if (searchParams.get('edit') === 'true' && searchParams.get('Id')?.trim()) {
      setIsEditUser(true);
    } else {
      setIsEditUser(false);
    }
  }, [location.search]); // Trigger useEffect whenever the URL's query string changes

  useEffect(() => {
    const mainDiv = document.getElementById('main-content');
    focusFirstInteractiveElement(mainDiv);
  }, [isEditUser]);

  const handleEditClose = () => {
    setIsEditUser(false);
    // Remove 'edit-user' from query params
    const searchParams = new URLSearchParams(location.search);
    searchParams.delete('edit'); // Remove the 'edit' param
    searchParams.delete('Id'); // Remove the 'Id' param

    // Update the URL without the 'edit-user' parameter
    navigate(`${location.pathname}?${searchParams.toString()}`, { replace: true });
  };

  return (
    <GridContextProvider>
      <TngGrid className="manage_users">
        <TngGrid className="manage_users_header">
          <TngTypography variant="h2" tabIndex={0}>
            {Translations.ManageUsers.manageUsersHeading}
          </TngTypography>
        </TngGrid>
        <ManageUsersGrid />
      </TngGrid>
      {isEditUser && <EditUserDrawer open={isEditUser} onClose={handleEditClose} />}
    </GridContextProvider>
  );
};

export default ManageUser;
