import React, { ReactNode } from 'react';

import Box, { BoxProps } from '@mui/material/Box';

interface TngBoxProp extends BoxProps {
  children: ReactNode | ReactNode[];
}

export const TngBox: React.FC<TngBoxProp> = ({ children, ...boxProps }) => {
  return <Box {...boxProps}>{children}</Box>;
};
