import { useQuery } from '@tanstack/react-query';

import { getOrderById } from '../../../api/services/order/orderService';
import { Orders } from '../../../common/interfaces/order/Orders';

// this hook called the api and get data to be displayed on grid
export const useOrderSummary = (orderId: string) => {
  const queryKey = 'Order Summary';
  const { data, error, isLoading } = useQuery<Orders[], Error>({
    queryKey: [queryKey, orderId],
    queryFn: () => getOrderById(orderId),
  });
  return { data, error, isLoading };
};
