import React, { useEffect } from 'react';

import OpportunityTabs from './OpportunityTabs/OpportunityTabs';
import Translations from '../../../assets/locale/en/content.json';
import { TngDivider, TngGrid, TngTypography } from '../../../components/common';
import { focusFirstInteractiveElement } from '../../../utils/findInteractiveElement';

const CreateQuoteStepOne = () => {
  useEffect(() => {
    const mainDiv = document.getElementById('header_container');
    focusFirstInteractiveElement(mainDiv);
  });
  return (
    <TngGrid container className="add_new_quote_parent_container" id="header_container">
      <TngGrid className="add_new_quote_child_container">
        <TngTypography className="add_new_quote_text">
          {Translations.quoteCreation.createNewQuote}
        </TngTypography>
        <TngTypography variant="h3" className="add_new_quote_heading">
          {Translations.quoteCreation.opportunityTypeCapital}
        </TngTypography>
        <TngDivider />
        <TngTypography className="add_new_quote_capsule_heading">
          {Translations.quoteCreation.opportunityType}
        </TngTypography>
        <TngGrid className="add_new_quote_tabs_container">
          <OpportunityTabs />
        </TngGrid>
      </TngGrid>
    </TngGrid>
  );
};

export default CreateQuoteStepOne;
