import React from 'react';

import DraftQuotes from './DraftQuotes';
import QuotesList from './QuotesList';
import Translations from '../../../assets/locale/en/content.json';
import { TngGrid } from '../../../components/common';
import { CustomTabs } from '../../../components/CustomTab/CustomTabs';

interface TabConfig {
  label: string;
  id: number;
}

interface ManageQuotesTabs {
  onTabSelection: (index: number) => void;
  activeTabIndex: number;
}

const ManageQuotesTabs: React.FC<ManageQuotesTabs> = ({ onTabSelection, activeTabIndex }) => {
  const tabConfig: TabConfig[] = [
    { label: Translations.ManageQuotes.quotesList, id: 1 },
    { label: Translations.ManageQuotes.draftQuotes, id: 2 },
  ];
  return (
    <TngGrid container className="manage_quote_grid_container">
      <CustomTabs tabs={tabConfig} onTabSelection={onTabSelection} selectedTab={activeTabIndex}>
        <QuotesList />
        <DraftQuotes />
      </CustomTabs>
    </TngGrid>
  );
};

export default ManageQuotesTabs;
