import React, { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useNavigate } from 'react-router-dom';

import { RootState } from '../store';
import { logout } from '../store/auth/authSlice';
import isTokenValid from '../utils/jwtTokenValidation';

const PublicRoute: React.FC = () => {
  const navigate = useNavigate();
  const { token } = useSelector((state: RootState) => state.auth);
  const isAuthenticated = token ? isTokenValid(token) : false;
  const dispatch = useDispatch();

  useEffect(() => {
    if (isAuthenticated) {
      // If authenticated, redirect to the dashboard or any other protected page
      navigate('/dashboard');
    } else if (token) {
      dispatch(logout());
    }
  }, [isAuthenticated, navigate]);

  if (!isAuthenticated) {
    return <Outlet />; // Render the public pages if not authenticated
  }

  return null; // Don't render anything if authenticated
};

export default PublicRoute;
