// src/features/auth/authSlice.ts
import { createSlice } from '@reduxjs/toolkit';

import { UserState } from './interfaces/userState';
import { setUser, setUserPermissions } from './userReducer';
import { UserProfile } from '../../common/interfaces/user/UserProfile';
import { MappedPermissions } from '../../common/interfaces/userAdmin/Permissions';

const initialState: UserState = {
  user: {} as UserProfile,
  permissions: {} as MappedPermissions,
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser,
    setUserPermissions,
  },
});

export const { setUser: setUserAction, setUserPermissions: setUserPermissionsAction } =
  userSlice.actions;
export default userSlice.reducer;
