// this function is used to create column definitions for existing opportunity grid

import Translations from '../../../../../../assets/locale/en/content.json';
import { ColumnDef } from '../../../../../../common/interfaces/ColumnDef';
import { CellWithRadioButton } from '../../../../../../components/TngGridSection/common/components/CustomCellRender';
import { ExistingOpportunity } from '../../../../modals/ExistingOpportunity';

export const getColumnHeaders = (
  existingOpportunityValue: ExistingOpportunity,
  setExistingOpportunityValue: React.Dispatch<React.SetStateAction<ExistingOpportunity>>,
): ColumnDef[] => {
  return [
    {
      name: '',
      customComponent: [
        {
          name: CellWithRadioButton,
          props: {
            existingOpportunityValue: existingOpportunityValue,
            setExistingOpportunityValue: setExistingOpportunityValue,
          },
        },
      ],
      displayName: '',
      sortable: false,
    },
    {
      name: 'name',
      displayName: Translations.quoteCreation.opportunityName,
    },
    {
      name: 'opportunityId',
      displayName: Translations.quoteCreation.opportunityID,
    },
    {
      name: 'closeDate',
      displayName: Translations.quoteCreation.opportunityCloseDate,
      sort: 'desc',
    },
  ];
};
