import React from 'react';

import Translations from '../../../../../assets/locale/en/content.json';
import { TngDivider, TngGrid, TngTypography } from '../../../../../components/common';
import { AccountInfoDetails } from '../modals/AccountInfoDetails';
import { FieldConfig } from '../modals/FieldConfig';
import { CreateFieldConfigForAccountInfo } from '../utils/FieldConfig';

interface AccountInfoProps {
  accountInfo: AccountInfoDetails | undefined;
}

const AccountInfo: React.FC<AccountInfoProps> = ({ accountInfo }) => {
  const fullWidthValues: string[] = [
    Translations.accountSettings.accountName,
    Translations.accountSettings.phoneNumber,
  ];
  const fieldConfig: FieldConfig[] = CreateFieldConfigForAccountInfo(accountInfo);
  return (
    <TngGrid container>
      <TngGrid item xs={12} className="account_details_header">
        <TngTypography variant="h6">{Translations.accountSettings.accountInfo}</TngTypography>
        <TngDivider />
      </TngGrid>
      {fieldConfig.map((acc) => (
        <TngGrid
          item
          xs={12}
          md={12}
          lg={fullWidthValues.includes(acc.name) ? 12 : 6}
          key={acc.name}
          className="account_details_values"
        >
          <>
            <TngTypography className="account_details_values_key">{acc.name}</TngTypography>
            <TngTypography>{acc.value ?? Translations.accountSettings.notAvailable}</TngTypography>
          </>
        </TngGrid>
      ))}
    </TngGrid>
  );
};

export default AccountInfo;
