import React, { useEffect, useMemo, useState } from 'react';

import { ColDef } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';

import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

import useAriaLiveMessage from '../../../../../common/hooks/useAriaLiveMessage';
import { ColumnDef } from '../../../../../common/interfaces/ColumnDef';
import { GridMoreOptionsConfig } from '../../../../../common/interfaces/GridMoreOptionsConfig';
import { GridSortingConfig } from '../../../../../common/interfaces/GridSortingConfig';
import { TngGrid } from '../../../../common';
import TngLiveRegionS508 from '../../../../common/TngLiveRegionS508';
import {
  CustomLoadingOverlay,
  CustomNoRowsOverlay,
} from '../../../common/components/CustomCellRender';
import { suppressKeyboardEvent } from '../../../common/utils/gridKeyboardNavigation';
import { TransformColumnHeader } from '../../../common/utils/transformColumnHeader';

interface TngDataGridProps {
  ColumnHeader: ColumnDef[];
  sortingConfig: GridSortingConfig;
  moreOptions: GridMoreOptionsConfig;
  columnWidth: number;
  rowHeight: number;
  gridData: any[];
}

const TngMultiDataGrid = ({
  ColumnHeader,
  sortingConfig,
  moreOptions,
  columnWidth,
  rowHeight,
  gridData,
}: TngDataGridProps) => {
  const [columnDef, setColumnDef] = useState<ColDef[]>([]);

  const [ariaLiveMessage, setAriaLiveMessage] = useState<string | null>(null);

  // custom hook for s508
  useAriaLiveMessage({ gridData, setAriaLiveMessage });

  //transform the column header according to the ag-grid ColDef modal
  useEffect(() => {
    const transformedColumnHeader = TransformColumnHeader({
      ColumnHeader,
      moreOptions,
      isSortingApplicable: sortingConfig.isApplicable,
    });
    setColumnDef(transformedColumnHeader);
  }, [ColumnHeader, moreOptions, sortingConfig.isApplicable]);

  const gridOptions = useMemo(
    () => ({
      defaultColDef: {
        suppressMovable: true,
        minWidth: columnWidth,
        resizable: true,
        sortable: false,
        flex: 1,
        suppressKeyboardEvent,
      },
      reactiveCustomComponents: true,
      headerHeight: 45,
      loadingOverlayComponent: CustomLoadingOverlay,
    }),
    [columnWidth],
  );

  return (
    <TngGrid className="ag-theme-alpine ag_grid_container" data-testid="data-grid">
      {/* ARIA Live Region */}
      <TngLiveRegionS508 message={ariaLiveMessage} />
      <AgGridReact
        rowData={gridData}
        columnDefs={columnDef}
        gridOptions={gridOptions}
        suppressClickEdit={true}
        suppressRowHoverHighlight={true}
        getRowHeight={() => rowHeight}
        noRowsOverlayComponent={CustomNoRowsOverlay}
        tooltipShowDelay={0}
      />
    </TngGrid>
  );
};

export default TngMultiDataGrid;
