import { useQuery } from '@tanstack/react-query';

import { fetchOrderStatusCount } from '../../../api/services/order/orderService';
import { OrderStatusCount } from '../../../common/interfaces/order/OrderStatusCount';

/**
Custom hook to fetch the order statuses count using React Query.
@returns {object} - An object containing states data, error, and loading state.
*/
export const useOrderStatusCount = () => {
  const { data, error, isLoading } = useQuery<OrderStatusCount[], Error>({
    queryKey: ['orderstatuscount'],
    queryFn: fetchOrderStatusCount,
  });
  return { data, error, isLoading };
};
