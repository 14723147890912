import React, { useEffect } from 'react';

import { getGridConfig } from './gridHelper/gridConfig';
import { mapFileDetailsToFileGridModal } from './gridHelper/mapperFunction';
import { GridActionEnum } from '../../common/enums/GridActionEnum';
import { useGridDispatch, useGridState } from '../../common/GridContext/GridContextProvider';
import { TngGrid } from '../../components/common';
import TngAgGrid from '../../components/TngGridSection/TngAgGrid/TngAgGrid';

const PublicSectorProgramGrid = ({ tabIndex }: { tabIndex: number }) => {
  const { gridData, loading } = useGridState();
  const dispatch = useGridDispatch();

  useEffect(() => {
    if (!gridData) return; // Early return if no gridData

    const mappedData = mapFileDetailsToFileGridModal(gridData);
    dispatch({
      type: GridActionEnum.SET_GRID_MAPPED_DATA,
      payload: mappedData,
    });

    if (loading) {
      dispatch({
        type: GridActionEnum.SET_LOADER,
        payload: false,
      });
    }
  }, [gridData, loading, dispatch]);

  return (
    <TngGrid className="partner_program_grid_container">
      <TngAgGrid gridConfig={getGridConfig(tabIndex)} />
    </TngGrid>
  );
};

export default PublicSectorProgramGrid;
