import React, { forwardRef } from 'react';

import CollapsedIcon from '../../../../assets/icons/CollapsedIcon';
import ExpandIcon from '../../../../assets/icons/ExpandIcon';
import { TngGrid, TngListItemButton } from '../../../common';

interface ExpandCollapsedIconProps {
  open: boolean;
  toggleDrawer: () => void;
}

const ExpandCollapsedIcon = forwardRef<HTMLButtonElement, ExpandCollapsedIconProps>(
  ({ open, toggleDrawer }, ref) => {
    return (
      <TngGrid className="drawer_icon_container">
        <TngListItemButton
          component="button"
          aria-label={open ? 'Menu Collapsed Icon' : 'Menu Expand Icon'}
          onClick={toggleDrawer}
          className="sidenav_drawer_icon"
          id="sidenav_expand_collapsed_button"
          ref={ref}
        >
          {open ? <CollapsedIcon /> : <ExpandIcon />}
        </TngListItemButton>
      </TngGrid>
    );
  },
);

ExpandCollapsedIcon.displayName = 'ExpandCollapsedIcon';

export default ExpandCollapsedIcon;
