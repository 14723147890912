import Translations from '../../../assets/locale/en/content.json';
import {
  SaveUserProfile,
  UserProfileAPIResponse,
} from '../../../common/interfaces/user/UserProfile';
import { UserTypeAPIResponse, UserTypes } from '../../../common/interfaces/user/UserTypes';
import {
  AccountActiveContact,
  AccountActiveContactAPIRes,
  ContactSaveForm,
} from '../../../pages/ManageUsers/common/interfaces/ManageUsers';
import axiosInstance from '../../axiosInstance';
import { getUrl, URLS } from '../../urls';

/**
Fetches the user profile from the API.
@returns {Promise<UserProfileAPIResponse>} - A promise that resolves to UserProfile.
@throws {Error} - Throws an error if the request fails.
*/
export const fetchUserProfile = async (): Promise<UserProfileAPIResponse> => {
  try {
    const url = getUrl(URLS.CONTACT_INFO);

    const { data } = await axiosInstance.get<UserProfileAPIResponse>(url);
    return data;
  } catch (err: any) {
    throw new Error(Translations.common.failedToFetchData);
  }
};

/**
save the user profile by the API.
@param {SaveUserProfile} user - user profile detail
@throws {Error} - Throws an error if the request fails.
*/
export const saveUserProfile = async (contactId: string, user: SaveUserProfile) => {
  try {
    const url = getUrl(URLS.UPDATE_USER_PROFILE_INFO, { contactId: contactId });

    const { data } = await axiosInstance.put(url, user);
    return data;
  } catch (err: any) {
    throw new Error(Translations.common.formErrorMessage);
  }
};

/**
Fetches the user types from the API.
@returns {Promise<UserProfile>} - A promise that resolves to userTypes.
@throws {Error} - Throws an error if the request fails.
*/
export const fetchUserTypes = async (): Promise<UserTypes[]> => {
  try {
    const url = getUrl(URLS.USER_TYPES);

    const { data } = await axiosInstance.get<UserTypeAPIResponse>(url);
    return data.data;
  } catch (err: any) {
    throw new Error(Translations.common.failedToFetchData);
  }
};

/**
Fetches Account Contact by the specified contact ID from the API.
@param {string} userId - The ID of the contact to fetch.
@returns {Promise<Orders[]>} - A promise that resolves to an contact.
@throws {Error} - Throws an error if the request fails.
*/
export const fetchAccountContactById = async (
  contactId: string,
): Promise<AccountActiveContact[]> => {
  try {
    const baseURL = getUrl(URLS.ACCOUNT_CONTACTS, { accountId: '001dy000000HRbhAAG' });

    const query = new URLSearchParams();
    query.append('Id', contactId);

    const url = `${baseURL}?${query.toString()}`;

    const { data } = await axiosInstance.get<AccountActiveContactAPIRes>(url);
    return data.data;
  } catch (err: any) {
    throw new Error(Translations.common.failedToFetchData);
  }
};

/**
Save Account Contact by the specified contact ID from the API.
@param {string} contact - Updated data to be saved.
@param {string} contactId - The ID of the contact to fetch.
@throws {Error} - Throws an error if the request fails.
*/
export const saveUserContactById = async (contact: ContactSaveForm, contactId: string) => {
  try {
    const baseURL = getUrl(URLS.UPDATE_CONTACT, { contactId: contactId });

    const { data } = await axiosInstance.put(baseURL, contact);
    return data;
  } catch (err: any) {
    throw new Error(Translations.common.formErrorMessage);
  }
};
