import React from 'react';

import { TngGrid, TngTypography } from '../../../components/common';

interface FieldValueWrapperProps {
  name: string;
  value: string;
}

const FieldValueWrapper: React.FC<FieldValueWrapperProps> = ({ name, value }) => {
  return (
    <TngGrid className="field_value_wrapper">
      <TngTypography className="field_name">{name}</TngTypography>
      <TngTypography className="field_value">{value}</TngTypography>
    </TngGrid>
  );
};

export default FieldValueWrapper;
