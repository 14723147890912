import { useQuery } from '@tanstack/react-query';

import { getQuoteById } from '../../../api/services/quote/quoteService';
import { QuoteModel } from '../../../common/interfaces/quotes/QuoteDetailsResponse';

// this hook called the api and get data to be displayed on Quote detail page
export const useQuoteDetails = (quoteId: string, status: string) => {
  const queryKey = `Quote details${quoteId}`;
  const { data, error, isLoading } = useQuery<QuoteModel, Error>({
    queryKey: [queryKey, quoteId],
    queryFn: () => getQuoteById(quoteId, status),
  });
  return { data, error, isLoading };
};
