import { Filter } from '../../../../common/interfaces/GridContextState';
import { DefaultSearch } from '../../../../common/interfaces/GridSearchConfig';
import { DefaultSorting } from '../../../../common/interfaces/GridSortingConfig';
import { ManageUsersGridUrl } from '../../../../common/interfaces/urls/ManageUsersGridUrl';
import { ListToPipeSeperator } from '../../../../utils/listToPipeSeperator';

interface defaultAPIParamsProps {
  pageNumber: number;
  pageSize: number;
  search: DefaultSearch;
  sorting: DefaultSorting;
  filters: Filter;
}

// used to create default API params for grid
export const defaultUsersAPIParams = ({
  pageNumber,
  pageSize,
  search,
  sorting,
  filters,
}: defaultAPIParamsProps): ManageUsersGridUrl => {
  const apiParams: ManageUsersGridUrl = {
    pageNumber: pageNumber,
    pageSize: pageSize,
    searchParam: search.searchBy,
    searchValue: search.searchValue,
    sortingField: sorting?.colId,
    sortingOrder: sorting?.sortOrder,
    countryCodes: filters?.country && ListToPipeSeperator(filters?.country),
    userTypes: filters?.userTypes && ListToPipeSeperator(filters?.userTypes),
  };
  return apiParams;
};
