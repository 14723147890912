import {
  ProgramGuideSectionNames,
  ProgramGuideSectionNamesResponse,
} from '../../../common/interfaces/partnerProgramGuide';
import axiosInstance from '../../axiosInstance';
import { getUrl, URLS } from '../../urls';

/**
Fetches partner program guide names data from the given URL.
@returns {Promise<Customer[]>} - A promise that resolves to the partner program guide names data.
@throws {Error} - Throws an error if the request fails.
*/
export const fetchPartnerProgramGuideNames = async (): Promise<ProgramGuideSectionNames[]> => {
  try {
    const url = getUrl(URLS.PARTNER_PROGRAM_GUIDE_NAMES, { accountId: `001dy000000HRbhAAG` });

    const { data } = await axiosInstance.get<ProgramGuideSectionNamesResponse>(url);
    return data.data;
  } catch (err) {
    // Rethrow or handle the error as needed
    throw new Error('Failed to fetch partner program guide names');
  }
};
