import React, { useState } from 'react';

import dayjs from 'dayjs';

import CustomerDropdown from './CustomerDropdown';
import FilterOrderStatus from './OrderStatus';
import PODates from './PODates';
import SoldThroughDropdown from './SoldThroughDropdown';
import Translations from '../../../../assets/locale/en/content.json';
import { GridActionEnum } from '../../../../common/enums/GridActionEnum';
import { useGridDispatch, useGridState } from '../../../../common/GridContext/GridContextProvider';
import { StatusWithColor } from '../../../../common/interfaces/StatusColor';
import { TngApplyFilterBtn, TngDivider, TngGrid } from '../../../../components/common';
import { OrderHistoryFilter } from '../../modals/OrderHistoryFilter';
import { OrderHistoryFilterContext } from '../../modals/OrderHistoryFilterContext';
import {
  checkIfFiltersChanged,
  initializeFilterState,
  isAnyFilterSelected,
} from '../../utils/filterHelper';

interface FilterProps {
  orderStatuses: StatusWithColor[];
}

export const Filter: React.FC<FilterProps> = ({ orderStatuses }) => {
  const { filters }: { filters: OrderHistoryFilterContext } = useGridState();
  const dispatch = useGridDispatch();

  const [filterObj, setFiltersObj] = useState<OrderHistoryFilter>(initializeFilterState(filters));
  const defaultFilterState: OrderHistoryFilter = initializeFilterState(filters);
  const [poDateError, setPoDateError] = useState<string | null>(null);

  // Check if there are any active filters
  const hasFilters = checkIfFiltersChanged(filterObj, defaultFilterState);

  // Validation logic for PO dates
  const validatePoDates = () => {
    let valid = true;
    const poDateFrom = filterObj.poDateFrom;
    const poDateTo = filterObj.poDateTo;
    const today = dayjs().startOf('day');
    // Reset error
    setPoDateError(null);

    // Parse poDateFrom and poDateTo as dayjs objects
    const parsedPoDateFrom = dayjs(poDateFrom);
    const parsedPoDateTo = dayjs(poDateTo);

    // Check for validity and completeness of poDateFrom
    if (poDateFrom && !parsedPoDateFrom.isValid()) {
      setPoDateError(Translations.filterSection.invalidFromPoDateError);
      valid = false;
    }

    // Check for validity and completeness of poDateTo
    else if (poDateTo && !parsedPoDateTo.isValid()) {
      setPoDateError(Translations.filterSection.invalidToPoDateError);
      valid = false;
    }

    // if dates are valid dates then check for additional validation
    if (valid) {
      if (poDateFrom && poDateTo && poDateFrom.isAfter(poDateTo)) {
        setPoDateError(Translations.filterSection.fromPoDateGreaterthanToPoDateError);
        valid = false;
      } else if (poDateFrom && poDateFrom.isAfter(today) && poDateTo && poDateTo.isAfter(today)) {
        setPoDateError(Translations.filterSection.fromPoDateAndToPoDateFutureError);
        valid = false;
      } else if (poDateFrom && poDateFrom.isAfter(today)) {
        setPoDateError(Translations.filterSection.fromPoDateFutureError);
        valid = false;
      } else if (poDateTo && poDateTo.isAfter(today)) {
        setPoDateError(Translations.filterSection.toPoDateFutureError);
        valid = false;
      }
    }

    return valid;
  };

  const applyFilter = () => {
    if (validatePoDates()) {
      const poDateFrom = filterObj.poDateFrom?.format('YYYY-MM-DD') || null;
      const poDateTo = filterObj.poDateTo?.format('YYYY-MM-DD') || null;
      dispatch({
        type: GridActionEnum.SET_FILTERS,
        payload: { ...filterObj, poDateFrom, poDateTo },
      });
      dispatch({ type: GridActionEnum.SET_IS_FILTER_OPEN, payload: false });
      // check it no filter is selected
      if (isAnyFilterSelected(filterObj)) {
        dispatch({ type: GridActionEnum.SET_IS_FILTER_APPLIED, payload: true });
      } else {
        dispatch({ type: GridActionEnum.SET_IS_FILTER_APPLIED, payload: false });
      }
    }
  };
  return (
    <TngGrid container className="order_history_filter filter_container">
      <FilterOrderStatus
        orderStatuses={orderStatuses}
        filterObj={filterObj}
        setFiltersObj={setFiltersObj}
      />

      <TngDivider orientation="horizontal" variant="middle" flexItem />

      <CustomerDropdown filterObj={filterObj} setFiltersObj={setFiltersObj} />

      <TngDivider orientation="horizontal" variant="middle" flexItem />

      <SoldThroughDropdown filterObj={filterObj} setFiltersObj={setFiltersObj} />

      <TngDivider orientation="horizontal" variant="middle" flexItem />

      <PODates filterObj={filterObj} setFiltersObj={setFiltersObj} poDateError={poDateError} />

      <TngDivider orientation="horizontal" variant="middle" flexItem />

      <TngApplyFilterBtn hasFilters={hasFilters} applyFilter={applyFilter} />
    </TngGrid>
  );
};
