import { getUserTypeValueByLabel } from './getUserTypeByLable';
import { UserTypeOptions } from '../../../../common/interfaces/user/UserTypes';
import { AccountActiveContact, UserFormField } from '../interfaces/ManageUsers';

/**
Mapped the activeContact data fetched using api to actual data rendered on UI
@parameter {data} - user AccountActiveContact fetched using api
@returns UserFormField - field to be render in UI
*/

export const mappedActiveContactToFormData = (
  data: AccountActiveContact,
  userTypeOptions: UserTypeOptions[],
): UserFormField => {
  const {
    firstName,
    lastName,
    emailAddress,
    jobTitle,
    phoneNumber,
    userTypeId,
    address: { countryCode, stateCode, street, city, postalCode },
  } = data;

  const defaultData: UserFormField = {
    firstName: firstName,
    lastName: lastName,
    emailAddress: emailAddress,
    jobTitle: jobTitle,
    userType: getUserTypeValueByLabel(userTypeId, userTypeOptions),
    country: countryCode,
    state: stateCode,
    street: street,
    city: city,
    postalCode: postalCode,
    phoneNumber: phoneNumber,
  };

  return defaultData;
};
