import { useQuery } from '@tanstack/react-query';

import { fetchPartnerProgramGuideNames } from '../../../api/services/partnerProgramGuide/partnerProgramGuideService';
import { ProgramGuideSectionNames } from '../../../common/interfaces/partnerProgramGuide';

export const usePartnerProgramNames = () => {
  const { data, error, isLoading } = useQuery<ProgramGuideSectionNames[], Error>({
    queryKey: ['partnerProgramNames'],
    queryFn: fetchPartnerProgramGuideNames,
  });
  return { data, error, isLoading };
};
