/**
 * Generates a URL with query parameters and replaces `+` with `%20`.
 * @param baseURL - The base URL without query parameters.
 * @param queryParams - A URLSearchParams object containing the query parameters.
 * @returns A complete URL with encoded query parameters where spaces are encoded as `%20`.
 */
export const createEncodedUrl = (baseURL: string, queryParams: URLSearchParams): string => {
  const queryString = queryParams.toString().replace(/\+/g, '%20');
  return `${baseURL}?${queryString}`;
};
