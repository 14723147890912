import React from 'react';

import { TngButton } from './TngButton';
import { TngGrid } from './TngGrid';
import Translations from '../../assets/locale/en/content.json';

interface TngApplyFilterProps {
  hasFilters: boolean;
  applyFilter: () => void;
}

export const TngApplyFilterBtn: React.FC<TngApplyFilterProps> = ({ hasFilters, applyFilter }) => {
  return (
    <TngGrid className={`${!hasFilters ? 'drawer_apply_button_disabled' : ''} drawer_apply_button`}>
      <TngButton fullWidth disabled={!hasFilters} onClick={applyFilter}>
        {Translations.filterSection.applyFilter}
      </TngButton>
    </TngGrid>
  );
};
