import Translations from '../../../../assets/locale/en/content.json';
import { TransformDate } from '../../../../utils/dateTimeConvertor';
import { FieldConfig } from '../modals/FieldConfig';
import { UserDetailsModal } from '../modals/UserDetailsModal';

// create config which used to render the fields using map
export const CreateFieldConfigForUserDetails = (
  userDetailsInfo: UserDetailsModal | undefined,
): FieldConfig[] => {
  const fieldConfig: FieldConfig[] = [
    { name: Translations.ManageUsers.emailAddress, value: userDetailsInfo?.emailAddress },
    { name: Translations.ManageUsers.jobTitle, value: userDetailsInfo?.jobTitle },
    {
      name: Translations.ManageUsers.UserFormField.phoneNumber,
      value: userDetailsInfo?.phoneNumber,
    },
    { name: Translations.ManageUsers.userType, value: userDetailsInfo?.userType },
    {
      name: Translations.ManageUsers.dateAdded,
      value: TransformDate(userDetailsInfo?.createdDate),
    },
    {
      name: Translations.ManageUsers.address,
      value:
        userDetailsInfo &&
        userDetailsInfo.address &&
        (userDetailsInfo.address.street ||
          userDetailsInfo.address.city ||
          userDetailsInfo.address.state ||
          userDetailsInfo.address.country ||
          userDetailsInfo.address.postalCode)
          ? `${userDetailsInfo.address.street ? userDetailsInfo.address.street + '\n' : ''} ${userDetailsInfo.address.city ? userDetailsInfo.address.city + ',' : ''} ${userDetailsInfo.address.state ? userDetailsInfo.address.state + '\n' : ''} ${userDetailsInfo.address.country ? userDetailsInfo.address.country : ''} ${userDetailsInfo.address.postalCode ? userDetailsInfo.address.postalCode : ''}`
          : Translations.accountSettings.notAvailable,
    },
  ];

  return fieldConfig;
};
