import React from 'react';

import { TngGrid, TngListItemIcon, TngListItemText } from '../../../common';

interface iconTextWrapperProps {
  Icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  label: string;
}

const IconTextWrapper: React.FC<iconTextWrapperProps> = ({ Icon, label }) => {
  return (
    <TngGrid className="sidenav_link_icon_container">
      <TngListItemIcon className="sidenav_item_icon">
        <Icon />
      </TngListItemIcon>
      <TngListItemText className="sidenav_item_text" primary={label} />
    </TngGrid>
  );
};

export default IconTextWrapper;
