/**
 * Custom hook for downloading files.
 *
 * Uses the file ID to fetch file data and converts it into a Blob for download.
 * Displays an error toast if the download fails.
 *
 * @returns {Function} - A function that initiates the file download when called with a file ID.
 */

import { DownloadFile } from '../../api/services/content/contentService';
import Translations from '../../assets/locale/en/content.json';
import { useToast } from '../../components/Toasts/ToastContext';
import { convertBlobToFile } from '../../utils/convertBlobToFile';

export const useFileDownload = () => {
  const { showToast } = useToast();
  return async (fileId: string) => {
    try {
      // call service to get the blog data for file
      const resData = await DownloadFile(fileId);
      // Create a new Blob from the response data
      const fileBlob = new Blob([resData.data]);

      convertBlobToFile(fileBlob, resData.fileName);
      return { isSuccess: true };
    } catch (err) {
      showToast(Translations.PartnerProgram.failedToDownloadFile, {
        type: 'error',
      });
      throw new Error();
    }
  };
};
