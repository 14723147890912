import React from 'react';

const CollapsedIcon = () => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M25.3333 28.0498H6.66667C5.19391 28.0498 4 26.8559 4 25.3831V6.71647C4 5.24371 5.19391 4.0498 6.66667 4.0498H25.3333C26.8061 4.0498 28 5.24371 28 6.71647V25.3831C28 26.8559 26.8061 28.0498 25.3333 28.0498Z"
        stroke="#002854"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.66732 13.3828L7.33398 16.0495L9.66732 18.7161"
        stroke="#002854"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.666 28.0498V4.0498"
        stroke="#002854"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default CollapsedIcon;
