import React, { ReactNode } from 'react';

import { TngGrid } from '../../common';
import Navbar from '../DashboardLayout/Navbar';

interface HeaderLayoutProps {
  children: ReactNode;
}

const HeaderLayout: React.FC<HeaderLayoutProps> = ({ children }) => {
  return (
    <TngGrid className="layout_container">
      <Navbar />
      <TngGrid className="dashboard_container">{children}</TngGrid>
    </TngGrid>
  );
};

export default React.memo(HeaderLayout);
