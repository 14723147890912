import { getColumnHeaders } from './columnDetails';
import { getExistingOpportunityUrl } from '../../../../../../api/urlGenerator';
import Translations from '../../../../../../assets/locale/en/content.json';
import { Sorting } from '../../../../../../common/enums/Sorting';
import { FindGridConfig } from '../../../../../../common/interfaces/FindGridConfig';
import { ExistingOpportunity } from '../../../../modals/ExistingOpportunity';

// grid config contains the details about grid which will render for this screen
export const getGridConfig = (
  apiParams: any,
  existingOpportunityValue: ExistingOpportunity,
  setExistingOpportunityValue: React.Dispatch<React.SetStateAction<ExistingOpportunity>>,
): FindGridConfig => ({
  baseUrl: getExistingOpportunityUrl(apiParams),
  ColumnHeader: getColumnHeaders(existingOpportunityValue, setExistingOpportunityValue),
  search: {
    isApplicable: true,
    defaultSearch: { searchBy: 'name', searchValue: '' },
    isDropdownApplicable: false,
    placeholder: Translations.quoteCreation.searchOpportunityPlaceholder,
  },
  sorting: {
    isApplicable: true,
    defaultSorting: { colId: 'closeDate', sortOrder: Sorting.DESC },
  },
  moreOptions: {
    isApplicable: false,
    moreOptionNavigation: [],
  },
  rowHeight: 75,
  columnWidth: 182,
  isPaginationVisible: true,
});
