import React, { useEffect } from 'react';

import { useSelector } from 'react-redux';
import { Outlet, useNavigate } from 'react-router-dom';

import { setNewRefreshToken } from '../api/services/auth/authService';
import useTokenListener from '../common/hooks/useTokenListener';
import { TngGrid, TngLoader } from '../components/common';
import { RootState } from '../store';
import isTokenValid from '../utils/jwtTokenValidation';

const ProtectedRoute: React.FC = () => {
  const navigate = useNavigate();
  const { refreshToken, token } = useSelector((state: RootState) => state.auth);
  const isAuthenticated = token ? isTokenValid(token) : false;
  const isLoading = useSelector((state: RootState) => state.master.isLoading);
  useTokenListener();

  useEffect(() => {
    if (!isAuthenticated) {
      refreshToken ? setNewRefreshToken(refreshToken) : navigate('/');
    }
  }, [isAuthenticated, navigate, refreshToken]);

  if (isLoading) {
    return (
      <TngGrid className="custom-loader-overlay">
        <TngLoader />
      </TngGrid>
    );
  }

  if (isAuthenticated && !isLoading) {
    return <Outlet />;
  }

  // Return null if not authenticated to prevent rendering anything
  return null;
};

export default ProtectedRoute;
