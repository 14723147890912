import React, { createContext, useContext } from 'react';

import { toast, ToastContainer } from 'react-toastify';

import CustomToast from './CustomToast';
import Translations from '../../assets/locale/en/content.json';
import { ToastOptions, ToastType } from '../../common/interfaces/ToastOptions';

const ToastContext = createContext<ToastType>({
  showToast: () => {},
});

export const useToast = () => useContext(ToastContext);

const ToastProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const showToast = (message: string, options?: ToastOptions) => {
    try {
      toast(<CustomToast message={message} {...options} />, {
        ...options,
        // properties for all the toast
        containerId: options?.containerId ?? 'global', // use global as default Toast Container
        hideProgressBar: true,
        closeButton: false,
        className: 'custom-toast-container',
        bodyClassName: 'custom-toast-body',
        progressClassName: 'custom-toast-progress',
        autoClose: 2000,
        position: 'top-right',
      });
    } catch (error) {
      toast.error(Translations.common.someExceptionOccured);
    }
  };

  return (
    <ToastContext.Provider value={{ showToast }}>
      <ToastContainer containerId="global" />
      {children}
    </ToastContext.Provider>
  );
};

export { ToastContext, ToastProvider };
