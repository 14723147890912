import React from 'react';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import AppService from './app/services/AppService';
import ReactErrorBoundary from './components/ErrorBoundary/ErrorBoundary';
import ReactThemeProvider from './components/ThemeProvider/TngThemeProvider';
import { ToastProvider } from './components/Toasts/ToastContext';
import reportWebVitals from './reportWebVitals';
import './api/interceptor';
import './index.scss';
import { TungstenRoutes } from './router/router';
import store from './store';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});
root.render(
  <React.StrictMode>
    <ReactThemeProvider>
      <Provider store={store}>
        <ReactErrorBoundary>
          <QueryClientProvider client={queryClient}>
            <AppService>
              <BrowserRouter>
                <ToastProvider>
                  <TungstenRoutes />
                </ToastProvider>
              </BrowserRouter>
            </AppService>
          </QueryClientProvider>
        </ReactErrorBoundary>
      </Provider>
    </ReactThemeProvider>
  </React.StrictMode>,
);

reportWebVitals();
