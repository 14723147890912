type DebouncedFunction<T extends any[]> = (...args: T) => void;

export const debounce = <T extends any[]>(
  func: (...args: T) => void,
  delay: number,
): DebouncedFunction<T> => {
  let timer: ReturnType<typeof setTimeout>;
  return function (this: any, ...args: T) {
    clearTimeout(timer);
    timer = setTimeout(() => func.apply(this, args), delay);
  };
};
