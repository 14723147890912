import React, { useEffect, useState } from 'react';

import GridTabs from './components/GridTabs';
import PublicSectorProgramGrid from './PublicSectorProgramGrid';
import Translations from '../../assets/locale/en/content.json';
import { GridContextProvider } from '../../common/GridContext/GridContextProvider';
import { TngGrid, TngTypography } from '../../components/common';
import TngBackNavigation from '../../components/common/TngBackNavigation';
import { focusFirstInteractiveElement } from '../../utils/findInteractiveElement';

const PartnerProgramGuides: React.FC = () => {
  const [tabIndex, setTabIndex] = useState(1);

  useEffect(() => {
    const mainDiv = document.getElementById('header_container');
    focusFirstInteractiveElement(mainDiv);
  }, []);
  const onTabSelection = (index: number) => {
    setTabIndex(index);
  };
  return (
    <TngGrid container className="partner_program_guide_parent_container" id="header_container">
      <TngGrid container className="partner_program_guide_child_container">
        <TngGrid item xs={12}>
          <TngBackNavigation
            label={Translations.trainingCertifications.backToDashboard}
            path={Translations.navigation.dashboard}
          />
        </TngGrid>
        <TngGrid item xs={12} className="partner_program_guide_main_heading">
          <TngTypography variant="h3">
            {Translations.PartnerProgram.partnerProgramGuide}
          </TngTypography>
        </TngGrid>
        <TngGrid item xs={12} className="partner_program_guide_tab_container">
          <GridTabs onTabSelection={onTabSelection} />
          <GridContextProvider>
            <PublicSectorProgramGrid tabIndex={tabIndex} />
          </GridContextProvider>
        </TngGrid>
      </TngGrid>
    </TngGrid>
  );
};

export default PartnerProgramGuides;
