// src/features/auth/authSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import isTokenValid from '../../utils/jwtTokenValidation';

interface AuthState {
  email: string;
  username: string;
  token: string | null;
  idToken: string | null;
  refreshToken: string | null;
  isAuthenticated: boolean;
}

// Retrieve and parse the tokens object from localStorage
const storedTokens = JSON.parse(localStorage.getItem('tokens') || '{}');

// Access individual tokens
const token = storedTokens?.token;
const idToken = storedTokens?.idToken;
const refreshToken = storedTokens?.refreshToken;

// Currently using a temporary username for test purposes. Will remove later
const initialState: AuthState = {
  username: '',
  email: '',
  token: token, // Rehydrate token from localStorage
  idToken: idToken, // Rehydrate idToken from localStorage
  refreshToken: refreshToken, // Rehydrate idToken from localStorage
  isAuthenticated: token ? isTokenValid(token) : false,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    login(
      state,
      {
        payload: { token, idToken, refreshToken },
      }: PayloadAction<{ token: string; idToken: string; refreshToken: string }>,
    ) {
      state.token = token;
      state.idToken = idToken;
      state.isAuthenticated = true;
      state.refreshToken = refreshToken;
      // Store token and idToken in localStorage
      const tokens = {
        token: token,
        idToken: idToken,
        refreshToken: refreshToken,
      };
      // Store the entire object in localStorage as a JSON string
      localStorage.setItem('tokens', JSON.stringify(tokens));
    },
    logout(state) {
      state.username = '';
      state.token = null;
      state.idToken = null;
      state.refreshToken = null;
      state.isAuthenticated = false;
      // Clear tokens and user data from local storage
      localStorage.removeItem('tokens');
    },
    setUser(state, { payload: { username, email } }: PayloadAction<any>) {
      state.username = username;
      state.email = email;
    },
  },
});

export const { login, logout, setUser } = authSlice.actions;
export default authSlice.reducer;
