import { PayloadAction } from '@reduxjs/toolkit';

import { UserState } from './interfaces/userState';
import { UserProfile, UserProfileAPIResponse } from '../../common/interfaces/user/UserProfile';
import { MappedPermissions, PermissionItem } from '../../common/interfaces/userAdmin/Permissions';
import { mapPermissions } from '../../common/mappers/mapPermissions';
import { userProfileMapper } from '../../common/mappers/UserProfileMapper';

// Reducer to set user information
export const setUser = (state: UserState, action: PayloadAction<UserProfileAPIResponse>) => {
  const mappedUser: UserProfile = userProfileMapper(action.payload);
  state.user = mappedUser;
};

// Reducer to set user permissions
export const setUserPermissions = (state: UserState, action: PayloadAction<PermissionItem[]>) => {
  const mappedPermissions: MappedPermissions = mapPermissions(action.payload);
  state.permissions = mappedPermissions;
};
