import { OrderStatusEnum } from '../../../common/enums/OrderStatusEnum';
import { OrderStatusCount } from '../../../common/interfaces/order/OrderStatusCount';

export const mapOrderStatus = (orderStatus: OrderStatusCount[]) => {
  return Object.values({
    PendingFulfillment: orderStatus.find(
      (status) => status.orderStatus === OrderStatusEnum.PendingFulfillment,
    ),
    Fulfilled: orderStatus.find((status) => status.orderStatus === OrderStatusEnum.Fulfilled),
    CreditHold: orderStatus.find((status) => status.orderStatus === OrderStatusEnum.CreditHold),
    Billed: orderStatus.find((status) => status.orderStatus === OrderStatusEnum.Billed),
  });
};
