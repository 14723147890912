import React from 'react';

import { useSelector } from 'react-redux';

import FinancialDetails from './FinancialDetail';
import ProductList from './ProductList';
import Translations from '../../../assets/locale/en/content.json';
import { TngGrid } from '../../../components/common';
import { CustomTabs } from '../../../components/CustomTab/CustomTabs';
import { RootState } from '../../../store';
import { useOrderSummaryState } from '../context/ContextProvider';

interface TabConfig {
  label: string;
  id: number;
}

const GridTabs = () => {
  const { product } = useSelector((state: RootState) => state.user.permissions);
  const { isFinancialTabVisible } = useOrderSummaryState();

  const tabConfig: TabConfig[] = [
    product?.list ? { label: Translations.OrderSummary.productList, id: 1 } : null,
    isFinancialTabVisible ? { label: Translations.OrderSummary.financialDetails, id: 2 } : null,
  ].filter((tab): tab is TabConfig => tab !== null);

  return (
    <TngGrid container className="order_summary_grid_container">
      <CustomTabs tabs={tabConfig}>
        <ProductList />
        {isFinancialTabVisible && <FinancialDetails />}
      </CustomTabs>
    </TngGrid>
  );
};

export default GridTabs;
