import React, { useEffect, useState } from 'react';

import { Filter } from './Filter/Filter';
import { GridActionEnum } from '../../../common/enums/GridActionEnum';
import { useGridDispatch, useGridState } from '../../../common/GridContext/GridContextProvider';
import { useOrderStatus } from '../../../common/hooks/useOrderStatus';
import { StatusColors } from '../../../common/interfaces/StatusColor';
import { OrderHistoryGridUrl } from '../../../common/interfaces/urls/OrderHistoryGridUrl';
import { TngGrid } from '../../../components/common';
import TngAgGrid from '../../../components/TngGridSection/TngAgGrid/TngAgGrid';
import { CreateStatusColorObj } from '../../../utils/createStatusColorObj';
import { defaultAPIParams } from '../gridHelper/apiParams';
import { getGridConfig } from '../gridHelper/gridConfig';
import { mapOrdersToOrderGridModal } from '../gridHelper/mapperFunctions';

const OrderHistoryGrid = () => {
  const [statusColors, setStatusColors] = useState<StatusColors>({} as StatusColors);
  const { gridData, search, pageNumber, pageSize, sorting, filters } = useGridState();
  const { data: orderStatuses = [] } = useOrderStatus();
  const dispatch = useGridDispatch();

  // check if gridData is present than called the mapper to map the data according to grid columns
  useEffect(() => {
    if (gridData) {
      const mappedData = mapOrdersToOrderGridModal(gridData);
      dispatch({ type: GridActionEnum.SET_GRID_MAPPED_DATA, payload: mappedData });
      dispatch({ type: GridActionEnum.SET_LOADER, payload: false });
    }
  }, [dispatch, gridData]);

  useEffect(() => {
    // Function to populate status colors from API response
    if (orderStatuses && orderStatuses.length > 0) {
      const colors = CreateStatusColorObj(orderStatuses);
      setStatusColors(colors);
    }
  }, [orderStatuses]);

  const filterCount = (): number => {
    let count = 0;
    if (filters?.customerIds && filters.customerIds.length >= 0) {
      count += filters.customerIds.length;
    }
    if (filters?.OrdStatus && filters.OrdStatus.length >= 0) {
      count += filters.OrdStatus.length;
    }
    if (filters?.soldThrough && filters.soldThrough.length >= 0) {
      count += filters.soldThrough.length;
    }
    // Check if either poDateFrom or poDateTo is provided
    if (filters.poDateFrom || filters.poDateTo) {
      count += 1;
    }
    return count;
  };
  // create or get default api params
  const apiParams: OrderHistoryGridUrl = defaultAPIParams({
    pageNumber,
    pageSize,
    search,
    sorting,
    filters,
  });

  return (
    <TngGrid className="order_history_container">
      <TngAgGrid
        gridConfig={getGridConfig(apiParams, statusColors)}
        filterComponent={<Filter orderStatuses={orderStatuses} />}
        filterCount={filterCount()}
      />
    </TngGrid>
  );
};

export default OrderHistoryGrid;
